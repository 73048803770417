import React, { useRef, useState } from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Button, Box, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Groups3Icon from "@mui/icons-material/Groups3";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { CustomQuickFilter } from "./CustomQuickFilter";  // adjust path
import XLSX from "@sheet/core";
import { checkBrandAndLocation } from "../utils/staffHelpers";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions";
import { RevertList } from "../../Components/RevertList";


export const EditToolbar = React.memo(({
  tableTabsValue,
  setAddStaffModal,      // from StaffSettings.jsx
  // checkBrandAndLocation, // from StaffSettings.jsx if used
  // createStaffArray,      // from StaffSettings.jsx if used
  // compareArrays,         // from StaffSettings.jsx if used
  settings,              // from StaffSettings.jsx if used
  changes,
  setChanges,
  dispatch,
  getUID,
  ratesApiRef,
  handlePayTiersClick,
}) => {


  const handleClick = () => {
    setAddStaffModal(true);
  };

  const handleDownloadStaff = () => {
    let staffAoa = createStaffArray();

    //download excel output
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(staffAoa);
    XLSX.utils.book_append_sheet(wb, ws, "PayWellStaff");
    XLSX.writeFile(wb, "PayWellStaff.xlsx", { cellStyles: true });
  };

  const createStaffArray = () => {
    //get buckets
    let buckets = Object.entries(
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
    ).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

            // Access the brand from the settings
            let brand =
                settings[getUrlVariableValue("settingsId")].generalSettings.brand;
            let studios =
                settings[getUrlVariableValue("settingsId")].generalSettings.studios;
    
    //iterate through staff
    let staffValues = Object.values(settings.staff.staff).filter((item) =>
      checkBrandAndLocation(item.brand, item.locations, brand, studios)
    );

    let staffAoa = [
      [
        "*user id*",
        "name",
        "email",
        "alternateNames",
        "type",
        "homeLocation",
        "homeRegion",
        "idString",
        "secondIdString",
        "titles",
        "locations",
        "hourly",
        "secondaryHourly",
      ],
    ];
    for (let i = 0; i < staffValues.length; i++) {
      let staffArray = [];
      let staff = staffValues[i];
      staffArray.push(staff.id);
      staffArray.push(staff.name);
      staffArray.push(staff.email);
      staffArray.push(staff.alternateNames.toString());
      staffArray.push(staff.type);
      staffArray.push(staff.homeLocation);
      staffArray.push(staff.homeRegion);
      staffArray.push(staff.idString);
      staffArray.push(staff.secondIdString);
      staffArray.push(staff.titles.toString());
      staffArray.push(staff.locations.toString());
      staffArray.push(staff.hourly);
      staffArray.push(staff.secondaryHourly);

      for (let x = 0; x < buckets.length; x++) {
        //let standardBucketName = buckets[x][0];
        let bucketName =
          buckets[x][0].charAt(0).toLowerCase() +
          buckets[x][0].slice(1) +
          "Rate";
        let bucketDetails = buckets[x][1];
        if (bucketDetails.rate.includeZero === "Paid A Different Amount") {
          if (i === 0) {
            staffAoa[0].push(bucketName + ".zero");
          }
          staffArray.push(staff[bucketName].zero);
        } else if (bucketDetails.rate.includeZero === "Not Paid") {
          if (i === 0) {
            staffAoa[0].push(bucketName + ".zero");
          }
          staffArray.push("0");
        } else if (bucketDetails.rate.includeZero === "Paid The Base Rate") {
          //dont add anything
        }

        if (bucketDetails.rate.structure === "None (Zero)") {
          continue;
        }

        if (bucketDetails.rate.structure === "Base Rate + Per Head Bonus") {
          if (i === 0) {
            staffAoa[0].push(bucketName + ".base");
            staffAoa[0].push(bucketName + ".perHead");
            staffAoa[0].push(bucketName + ".afterAttendee");
          }
          staffArray.push(staff[bucketName].base);
          staffArray.push(staff[bucketName].perHead);
          staffArray.push(staff[bucketName].afterAttendee);
        } else if (bucketDetails.rate.structure === "Flat Rate") {
          if (i === 0) {
            staffAoa[0].push(bucketName + ".flat");
          }
          staffArray.push(staff[bucketName].flat);
        } else if (bucketDetails.rate.structure === "Custom Amount") {
          if (i === 0) {
            staffAoa[0].push(bucketName + ".custom");
          }
          staffArray.push(staff[bucketName].custom.toString());
        }
      }

      //add commission
      if (i === 0) {
        staffAoa[0].push("commissionRate.agreement");
        staffAoa[0].push("commissionRate.retail");
      }
      staffArray.push(staff.commissionRate.agreement);
      staffArray.push(staff.commissionRate.retail);

      staffAoa.push(staffArray);
    }
    return staffAoa;
  };

  const handleUploadStaff = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const uploadedAoa = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });
          resolve(uploadedAoa);
        };

        reader.onerror = (error) => reject(error);

        reader.readAsArrayBuffer(file);
      });
    };

    let uploadedAoa = await readFile(file);

    let staffAoa = createStaffArray();
    let differences = compareArrays(staffAoa, uploadedAoa);

    for (let d = 0; d < differences.length; d++) {
      //temporary ignore array type items
      if (
        differences[d].title.includes("locations") ||
        differences[d].title.includes("alternateNames") ||
        differences[d].title.includes("titles") ||
        differences[d].title.includes("custom")
      ) {
        continue;
      }
      let difference = differences[d];
      let staffMember = settings.staff.staff[difference.id];
      if (!staffMember) {
        continue;
      }
      let updatedRow = { ...staffMember };

      const titleParts = difference.title.split(".");

      if (titleParts.length === 1) {
        // Update top-level property
        updatedRow[difference.title] = difference["new-value"];
      } else if (titleParts.length === 2) {
        // Update nested property
        const [parentProp, childProp] = titleParts;
        updatedRow[parentProp] = {
          ...updatedRow[parentProp],
          [childProp]: difference["new-value"],
        };
      }

      // Additional updates for the row
      updatedRow.updated = true;
      updatedRow.status = "update";
      updatedRow.statusField = titleParts[titleParts.length - 1];

      let newRev = new RevertList(changes.getValues());
      newRev.addElement(
        updatedRow.id,
        difference["old-value"],
        difference["new-value"]
      );
      setChanges(newRev);

      dispatch({
        type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER",
        uid: getUID(),
        id: updatedRow.id,
        newStaff: updatedRow,
      });
    }
  };

  const compareArrays = (array1, array2) => {
    // Extract titles from the first row of array1
    const titles = array1[0];

    // Convert array1 to a map for easy lookup, skipping the first row
    const map1 = new Map(array1.slice(1).map((item) => [item[0], item]));

    // Initialize an array to hold the differences, skipping the first row of array2
    let differences = [];

    // Iterate over array2 to find and record differences, skipping the first row
    array2.slice(1).forEach((row2) => {
      const id = row2[0];
      const row1 = map1.get(id);

      if (row1) {
        row2.forEach((item, index) => {
          if (item !== row1[index]) {
            // Record the difference
            differences.push({
              id: id,
              title: titles[index],
              "old-value": row1[index],
              "new-value": item,
            });
          }
        });
      }
    });

    return differences;
  };

  const fileInputRef = useRef(null);
  const [advancedMenuAnchor, setAdvancedMenuAnchor] = useState(null);

  return (
    <GridToolbarContainer>
      <div class="grid-toolbar-container">
        <div className="grid-toolbar-container__buttons">
          <Button hidden={tableTabsValue > 0} color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add staff
          </Button>
          <Box>
            <Button
              hidden={tableTabsValue > 0}
              color="primary"
              endIcon={<ArrowDropDownIcon />}
              onClick={(event) => setAdvancedMenuAnchor(event.currentTarget)}
            >
              Advanced Options
            </Button>
            <Menu
              anchorEl={advancedMenuAnchor}
              open={Boolean(advancedMenuAnchor)}
              onClose={() => setAdvancedMenuAnchor(null)}
            >
              <MenuItem onClick={handlePayTiersClick}>
                <ListItemIcon>
                  <Groups3Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Manage Staff Templates</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDownloadStaff}>
                <ListItemIcon>
                  <FileDownloadRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Export Staff</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => fileInputRef.current.click()}>
                <ListItemIcon>
                  <FileUploadRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Import Staff</ListItemText>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUploadStaff}
                  accept=".xlsx, .xls"
                  style={{ display: "none" }}
                />
              </MenuItem>
              {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handlePayTiersClick}
                    sx={{ mb: 2 }}
                  >
                    Manage Pay Tiers
                  </Button>
                </Box> */}
            </Menu>
          </Box>
        </div>
        <CustomQuickFilter apiRef={ratesApiRef} />
      </div>
    </GridToolbarContainer>
  );
});


export const EditPayTiersToolbar = React.memo(({
  tableTabsValue,
  setAddPayTierModal,
  tiersApiRef,
  // anything else you need
}) => {

  const handleClick = () => {
    setAddPayTierModal(true)
  };

  return (
    <GridToolbarContainer>
      <div class="grid-toolbar-container">
        <div className="grid-toolbar-container__buttons">
          <Button hidden={tableTabsValue > 0} color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Staff Template
          </Button>
        </div>
        <CustomQuickFilter apiRef={tiersApiRef} />
      </div>
    </GridToolbarContainer>
  );
});