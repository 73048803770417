import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  IconButton,
  Avatar,
  Divider,
} from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CircularIntegration from "../LoadingButton";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { CloseRounded } from "@mui/icons-material";
import "./_emailPreviewTable.scss";
import { getDisplayLocations, getFirstName } from "../../pages/StaffEmailer/staffEmailerDropzone";

const getDisplayStaffName = (selectedEmail) => {
  debugger;
  const rawEmployeeName = selectedEmail?.headers?.[2][0] || "";
  // Processed names
  const employeeName = getFirstName(rawEmployeeName);
  return employeeName ? employeeName : null;
}

const getDisplayLocationName = (selectedEmail) => {
  const rawLocationName = selectedEmail?.headers?.[0][0] || "";
  // Processed names
  const locationName = getDisplayLocations(rawLocationName);
  return locationName ? locationName : null;
}


function TypedRowsLayout({ sections }) {


  return (
    <>
      {sections.map((section, sIndex) => {
        const { subheaderTitle, rows } = section;
        return (
          <Box key={sIndex} sx={{ mt: 3 }}>
            <h3 className="subheader-section-title">{subheaderTitle}</h3>

            <Box className="table-wrapper">
              <Box className="table-container">
                <table className="paystub-table">
                  <tbody>
                    {rows.map((rowObj, rIndex) => {
                      const { type, data } = rowObj;
                      return (
                        <tr
                          key={rIndex}
                          className={
                            type === "totals"
                              ? "totals-row"
                              : type === "subheader"
                                ? "subheader-row"
                                : ""
                          }
                        >
                          {data.map((cell, cIdx) => {
                            // If subheader's first cell => single <th> (colSpan)
                            if (type === "subheader" && cIdx === 0) {
                              return (
                                <th key={cIdx} colSpan={data.length}>
                                  {cell}
                                </th>
                              );
                            }
                            // If totals row's first cell => <th>
                            if (type === "totals" && cIdx === 0) {
                              return <th key={cIdx}>{cell}</th>;
                            }
                            return <td key={cIdx}>{cell}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
}



/* ----------------------------------------------------------------
   3) Main EmailPreviewTable
   - Filter out empty normal rows
   - Render
------------------------------------------------------------------*/
export default function EmailPreviewTable({
  emailCards,
  emails,
  handleSendEmail,
  handleSendAllEmails,
  sendAllEmailButtonDisabled,
  setSendAllEmailButtonDisabled,
}) {
  const { settings } = useSettingsContext();
  const [open, setOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const payPeriod = emails?.[0]?.payPeriod || "";


  // 2) Then do the allZeroEarnings check
  const allZeroEarnings = emailCards.every((card) => !!card?.zeroEarnings);





   
  

  function onHandleSendAllEmails(allEmails) {
    setSendAllEmailButtonDisabled(true);
    const filteredEmails = allEmails.filter((em) => !em.zeroEarnings);
    handleSendAllEmails(filteredEmails);
  }

  // 3) Open / close the preview
  const handleOpen = (email) => {
    setSelectedEmail(email);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEmail(null);
  };


  
  return (
    <>
      {/* A) Staff Paystubs Table */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: 2 }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 500 }}>
          Staff Paystubs
        </Typography>
        <Tooltip
          title={
            allZeroEarnings
              ? "All employees have 0 earnings. Unable to send any emails."
              : undefined
          }
        >
          <span>
            <Button
              variant="outlined"
              startIcon={<SendRoundedIcon />}
              disabled={sendAllEmailButtonDisabled || allZeroEarnings}
              onClick={() => onHandleSendAllEmails(emails)}
            >
              Send All
            </Button>
          </span>
        </Tooltip>
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden", mt: 2 }} elevation={0}>
        <TableContainer>
          <Table aria-label="email preview table">
            <thead>
              <TableRow>
                <TableCell>Staff Name</TableCell>
                <TableCell>
                  {getDisplayLocations(settings[getUrlVariableValue("settingsId")]?.generalSettings?.locationName) ||
                    "Location"}
                </TableCell>
                <TableCell>Staff Email</TableCell>
                <TableCell align="center">Paystub</TableCell>
                <TableCell align="center">Send</TableCell>
              </TableRow>
            </thead>
            <TableBody>
              {emailCards.map((emailCard, index) => (
                <TableRow key={index}>
                  <TableCell>{getFirstName(emailCard.headers?.[2]) || "N/A"}</TableCell>
                  <TableCell>{getDisplayLocations(emailCard.headers?.[0][0]) || "N/A"}</TableCell>
                  <TableCell>{emailCard.staffEmail}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        emailCard.zeroEarnings
                          ? "0 net earnings. Unable to send."
                          : undefined
                      }
                    >
                      <span>
                        <Button
                          variant="outlined"
                          size="small"
                          disabled={emailCard.zeroEarnings}
                          onClick={() => handleOpen(emailCard)}
                        >
                          Preview
                        </Button>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        emailCard.zeroEarnings
                          ? "$0 Net Earnings. Unable to send."
                          : undefined
                      }
                    >
                      <span>
                        <CircularIntegration
                          noMargin
                          disabled={emailCard.zeroEarnings}
                          sendEmail={() => handleSendEmail(emails[index])}
                          size="small"
                        >
                          Send
                        </CircularIntegration>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* B) “Preview” Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="email-preview-title"
        aria-describedby="email-preview-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#f1f3f4",
            borderBottom: "1px solid #dadce0",
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            Email Preview
          </Typography>
          <IconButton edge="end" aria-label="close" onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers sx={{ backgroundColor: "#fafafa" }}>
          {/* Possibly show subject, from, date */}
          {/* <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: "1.1rem" }}>
              {selectedEmail?.subject || "Paystub Summary"}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Avatar sx={{ width: 32, height: 32, mr: 1 }}>L</Avatar>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  no-reply-paystub@paywell.solutions
                </Typography>
                <Typography variant="body2">
                  to <strong>{selectedEmail?.staffEmail || "someone@email"}</strong>
                </Typography>
              </Box>
              <Typography variant="caption" sx={{ ml: "auto", color: "#999" }}>
                {new Date().toLocaleString()}
              </Typography>
            </Box>
          </Box> */}
          <Box sx={{ mb: 2 }}>
  <Typography sx={{ fontWeight: 500, fontSize: "1.1rem" }}>
    {selectedEmail?.payload?.subject || "Paystub Summary"}
  </Typography>
  <Box sx={{ display: "flex", alignItems: "flex-start", mt: 1 }}>
    <Avatar sx={{ width: 32, height: 32, mr: 1 }}>L</Avatar>
    <Box sx={{ flex: 1 }}>
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        no-reply-paystub@paywell.solutions
      </Typography>
      <Typography variant="body2">
        to: <strong>{selectedEmail?.staffEmail || "someone@email"}</strong>
      </Typography>
      {selectedEmail?.payload?.cc && (
        <Typography variant="body2">
          cc: <strong>{selectedEmail.payload.cc}</strong>
        </Typography>
      )}
    </Box>
    <Typography variant="caption" sx={{ ml: "auto", color: "#999", alignSelf: "center" }}>
      {new Date().toLocaleString()}
    </Typography>
  </Box>
</Box>


          <Divider sx={{ mb: 2 }} />

          {/* C) The Email container */}
          <Box className="email-container" sx={{ margin: "0 auto" }}>
            {/* Optional headerLogo */}
            <div
              className="header-logo"
              style={!selectedEmail?.headerLogo ? { padding: 0 } : {}}
            >
              {selectedEmail?.headerLogo && (
                <img
                  src={selectedEmail.headerLogo}
                  alt="PayWell Logo"
                  style={{ maxWidth: 120 }}
                />
              )}
            </div>

            {/* Title Section */}
            <div className="title-section">
              <p className="company-name">
                {getDisplayLocationName(selectedEmail) || "Location Name"}
              </p>
              <h1 className="main-heading">
                {getDisplayStaffName(selectedEmail)
                  ?
                  <>Get ready for payday,<br />
                    {getDisplayStaffName(selectedEmail)}.</>
                  :
                  <>Get ready for payday</>
                }

              </h1>
            </div>

            {/* Pay Summary */}
            <div className="pay-summary">
              <p className="pay-summary-header">Paystub Summary</p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p className="pay-summary__text-label pay-check-amount-label">
                        Gross Pay
                      </p>
                      <p className="pay-summary__text pay-check-amount">
                        {
                          parseFloat(selectedEmail?.payCheckAmount).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          }) || "$0.00"}
                      </p>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <p className="pay-summary__text-label pay-period-dates-label">
                        Pay Period
                      </p>
                      <p className="pay-period-dates">
                        {selectedEmail?.payPeriod || payPeriod}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* If there's custom text => show */}
              {selectedEmail?.payload?.customEmailTextContent && (
                <p
                  style={{
                    marginTop: "1rem",
                    color: "#515151",
                    fontSize: 15,
                    fontWeight: 400,
                    lineHeight: "1.6em",
                  }}
                >
                  {selectedEmail.customEmailTextContent}
                </p>
              )}
            </div>

            {/* typedRows => subheader sections */}
            {!selectedEmail?.payload?.sections?.length ? (
              <Box sx={{ p: 2 }}>
                <Typography>No pay details available.</Typography>
              </Box>
            ) : (
              <TypedRowsLayout sections={selectedEmail.payload.sections} />
            )}

            {/* Footer */}
            <div className="footer-block">
              <p>
                You are receiving this email because your company is using PayWell
                for a better payroll experience.
              </p>
            </div>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
