
/**********************************************
 * TEMPORARY UNTIL AUTOMATIONS IS MERGED
 **********************************************/
export const SESSION_EXCEPTIONS_ON = true;
export const TIME_EXCEPTIONS_ON = true;
export const COMMISSION_EXCEPTIONS_ON = true;


export var EXCEPTIONS_CONFIG = {
  SESSION: true,
  TIME: true,
  COMMISSION: true,
  ENABLE_AUTOMATIONS_AUTO_COMMENT: false,
};

export function setExceptionConfig(key, value) {
  if (EXCEPTIONS_CONFIG.hasOwnProperty(key)) {
    EXCEPTIONS_CONFIG[key] = value;
  }
}




/**********************************************
 * OutputTest.js
 **********************************************/

import React, { useState, useEffect } from "react";
import XLSX from "@sheet/core";
import { API } from "aws-amplify";
import { useSettingsContext } from "../../contexts/SettingsContext";
import {
  buildReportCompilerState,
  handleDownload,
} from "../../contexts/ReportCompiler/utility-functions.js";
import {
  addOrUpdateUrlVariable,
} from "../../utility-functions/utility-functions.js";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import {
  getWorkbookFromS3,
} from "../../contexts/StudioScrapeStatus/utility-functions.js";
import {
  fetchPreSignedUrls,
  processS3Urls,
  getStudiosAndWorkbooksFromSessionData,
  createPresignedUrl,
  fetchLatestSessionPathWithSnapshot,
  getReportCompilerStateFromLatestSessionSnapshot,
} from "../../utility-functions/AWS/S3-Helper-Functions.js";
import { getSettingsIdsForUid } from "../../services/studio.services";
import { ComparisonView } from "./ComparisonView.js";
import { uploadTestOutput } from "../../utility-functions/utility-functions.js";
import "./_outputTest.scss";

// Material UI & other libs
import LoadingButton from "@mui/lab/LoadingButton";
import { StartRounded, ExpandMore } from "@mui/icons-material";
import Papa from "papaparse";
import {
  Box,
  Paper,
  Button,
  Typography,
  TextField,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// ------------------ API HELPERS ------------------
/**
 * Fetch all users from your backend.
 */
export async function getAllUsers() {
  const myAPI = "paywellAPIResource";
  const path = "/listAllUsers";
  let users;
  await API.post(myAPI, path)
    .then((res) => {
      users = res;
      console.log(users);
    })
    .catch((error) => {
      console.error(error);
      users = error;
    });
  return users;
}


/**
 * Compare two Excel files in S3 (by paths).
 */
async function compareExcelFiles(s3FilePath1, s3FilePath2, includeExactMatches) {
  const myAPI = "paywellAPIResource";
  const path = "/compareExcelFiles";
  const event = {
    body: {
      s3FilePath1,
      s3FilePath2,
      includeExactMatches,
    },
  };
  let result;
  await API.post(myAPI, path, event)
    .then((res) => {
      result = res;
    })
    .catch((error) => {
      console.error(error);
    });
  return result;
}

function OutputTest() {
  // Loading state while we run comparisons
  const [loading, setLoading] = useState(false);

  // Exceptions Config
  const [exceptionsConfig, setLocalExceptionsConfig] = useState({
    ...EXCEPTIONS_CONFIG,
  });
  const toggleException = (key) => {
    const newValue = !exceptionsConfig[key];
    setExceptionConfig(key, newValue); 
    setLocalExceptionsConfig({ ...exceptionsConfig, [key]: newValue });
  };

  /**
   * 1) "allComparisonResults" holds final results for each user & each preset.
   *    e.g. { "bob@example.com": { userUid: "...", presets: [ {settingsId, ...}, ...] } }
   */
  const [allComparisonResults, setAllComparisonResults] = useState({});

  /**
   * 2) "userProgress" is an array storing each selected user's progress/logs.
   *    e.g. [{ email, uid, totalPresets, completedPresets, status, logs }, ...]
   */
  const [userProgress, setUserProgress] = useState([]);

  /**
   * Keep your existing single comparisonData if you want to show the "latest" comparison globally.
   */
  const [comparisonData, setComparisonData] = useState(null);

  // Access your contexts
  const { reportCompilerState } = useSettingsContext();
  const { scrapeSelectedStudios } = useStudioScrapeStatus();

  /**
   * 3) We store the list of all available users & which are selected via MUI Autocomplete.
   */
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // array of user objects

  // On mount, fetch all active users
  useEffect(() => {
    async function fetchUsers() {
      try {
        const usersData = await getAllUsers();
        const activeUsers = usersData.users.filter((u) => !u.disabled);
        activeUsers.sort((a, b) => a.email.localeCompare(b.email));
        setUsers(activeUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
    fetchUsers();
  }, []);

  /**
   * MAIN "run()" function - runs comparison for each selected user and each preset,
   * just like your original logic, but with logs & progress bars instead of console logs.
   */
  const run = async () => {
    setLoading(true);

    // Clear prior data
    setAllComparisonResults({});
    setComparisonData(null);
    setUserProgress([]);

    // Initialize userProgress for selected users
    const initialUserProgress = selectedUsers.map((u) => ({
      email: u.email,
      uid: u.uid,
      totalPresets: 0,
      completedPresets: 0,
      status: "pending", // "pending", "in-progress", "complete"
      logs: [],
    }));
    setUserProgress(initialUserProgress);

    try {
      if (!selectedUsers || selectedUsers.length === 0) {
        console.warn("No users selected for testing. Exiting run.");
        setLoading(false);
        return;
      }

      // FOR EACH USER
      for (const user of selectedUsers) {
        // Mark user as in-progress
        setUserProgress((prev) =>
          prev.map((up) =>
            up.uid === user.uid ? { ...up, status: "in-progress" } : up
          )
        );

        /**
         * 2) Fetch settings IDs for the user
         */
        let settingsIdArray = [];
        try {
          settingsIdArray = await getSettingsIdsForUid(user.uid);
          addLog(user.uid, `Settings IDs: ${settingsIdArray.join(", ")}`);
        } catch (error) {
          addLog(user.uid, `ERROR fetching settings IDs: ${error.message}`);
          continue; // skip to next user
        }

        if (settingsIdArray.length === 0) {
          addLog(user.uid, `No settings presets found. Skipping user.`);
          continue;
        }

        // update userProgress to show how many presets we have
        setUserProgress((prev) =>
          prev.map((up) =>
            up.uid === user.uid
              ? { ...up, totalPresets: settingsIdArray.length }
              : up
          )
        );

        // FOR EACH PRESET
        for (const settingsId of settingsIdArray) {
          addLog(user.uid, `Running settingsId #${settingsId}...`);

          /**
           * a) fetchLatestSessionPathWithSnapshot
           */
          let latestSessionPath = null;
          try {
            latestSessionPath = await fetchLatestSessionPathWithSnapshot(
              process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
              user.uid,
              undefined, // payPeriod (if needed)
              settingsId
            );
            if (latestSessionPath) {
              addLog(user.uid, `latestSessionPath: ${latestSessionPath}`);
            } else {
              addLog(user.uid, `No session path found for preset #${settingsId}, skipping.`);
              // increment user preset count and continue
              setUserProgress((prev) =>
                prev.map((up) =>
                  up.uid === user.uid
                    ? { ...up, completedPresets: up.completedPresets + 1 }
                    : up
                )
              );
              continue;
            }
          } catch (error) {
            addLog(user.uid, `ERROR: ${error.message}`);
            // increment user preset count and continue
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // b) fetchPreSignedUrls
          let preSignedUrls = [];
          try {
            addLog(user.uid, "Fetching presigned URLs from S3...");
            preSignedUrls = await fetchPreSignedUrls(
              process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
              latestSessionPath
            );
            if (!preSignedUrls || preSignedUrls.length === 0) {
              addLog(user.uid, "No pre-signed URLs found. Skipping preset.");
              // increment user preset count and continue
              setUserProgress((prev) =>
                prev.map((up) =>
                  up.uid === user.uid
                    ? { ...up, completedPresets: up.completedPresets + 1 }
                    : up
                )
              );
              continue;
            }
          } catch (error) {
            addLog(user.uid, `ERROR fetching presigned URLs: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // c) processS3Urls
          let sessionSnapshotData;
          try {
            addLog(user.uid, "Processing S3 URLs...");
            sessionSnapshotData = await processS3Urls(preSignedUrls);
          } catch (error) {
            addLog(user.uid, `ERROR processing S3 URLs: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // d) parse out studios, etc.
          let workbooks, studios;
          try {
            ({ workbooks, studios } = getStudiosAndWorkbooksFromSessionData(
              sessionSnapshotData
            ));
            addLog(user.uid, `Found ${studios.length} studios from session data.`);
          } catch (error) {
            addLog(user.uid, `ERROR reading studios: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // e) update URL with settingsId
          try {
            if (!settingsId || typeof settingsId !== "number") {
              throw new Error(`Invalid settingsId: ${settingsId}`);
            }
            addOrUpdateUrlVariable("settingsId", settingsId);
            addLog(user.uid, `Updated URL with settingsId: ${settingsId}`);
          } catch (error) {
            addLog(user.uid, `ERROR updating URL variable: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // f) buildReportCompilerState
          let localReportCompilerState;
          try {
            addLog(user.uid, "Building report compiler state...");
            localReportCompilerState = buildReportCompilerState(
              sessionSnapshotData.settings
            );
          } catch (error) {
            addLog(user.uid, `ERROR building compiler state: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // g) compile S3 data
          let latestSessionSnapshotData;
          try {
            addLog(user.uid, "Compiling S3 session data...");
            latestSessionSnapshotData =
              await getReportCompilerStateFromLatestSessionSnapshot(
                latestSessionPath,
                studios,
                settingsId,
                localReportCompilerState
              );
            if (!latestSessionSnapshotData) {
              addLog(user.uid, `Failed to compile report for preset #${settingsId}.`);
              setUserProgress((prev) =>
                prev.map((up) =>
                  up.uid === user.uid
                    ? { ...up, completedPresets: up.completedPresets + 1 }
                    : up
                )
              );
              continue;
            }
            localReportCompilerState =
              latestSessionSnapshotData.updatedReportCompilerState;
          } catch (error) {
            addLog(user.uid, `ERROR: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // h) createPresignedUrl for live output, get liveOutput workbook
          const liveOutputS3Path = `${latestSessionPath}session_snapshot/output.xlsx`;
          let liveOutputS3Url;
          try {
            addLog(user.uid, `Creating pre-signed URL for live output: ${liveOutputS3Path}`);
            liveOutputS3Url = await createPresignedUrl(
              process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
              liveOutputS3Path
            );
          } catch (error) {
            addLog(user.uid, `ERROR creating pre-signed URL: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          let liveOutputWorkbook;
          try {
            addLog(user.uid, "Fetching live output workbook from S3...");
            liveOutputWorkbook = await getWorkbookFromS3(liveOutputS3Url);
            addLog(user.uid, `Live output fetched: ${liveOutputWorkbook.fileName}`);
          } catch (error) {
            addLog(user.uid, `ERROR fetching live output workbook: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // Optionally write it locally (same as your original code)
          try {
            XLSX.writeFile(
              liveOutputWorkbook.workbook,
              liveOutputWorkbook.fileName
            );
            addLog(user.uid, `Live output workbook written locally: ${liveOutputWorkbook.fileName}`);
          } catch (error) {
            addLog(user.uid, `ERROR writing live output locally: ${error.message}`);
          }

          // i) handleDownload (generates test output, modifies localReportCompilerState)
          try {
            addLog(user.uid, "Generating test output (handleDownload)...");
            localReportCompilerState = await handleDownload(
              {
                testMode: true,
                latestSessionPath,
              },
              localReportCompilerState,
              sessionSnapshotData.settings,
              settingsId
            );
          } catch (error) {
            addLog(user.uid, `ERROR handleDownload: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // j) uploadTestOutput
          let testOutputS3Path;
          let testOutputS3Url; // We'll create a presigned URL from that path

          try {
            addLog(user.uid, "Uploading test output...");
            testOutputS3Path = await uploadTestOutput(
              latestSessionPath,
              localReportCompilerState.output.workbook,
              localReportCompilerState.output.name
            );
            localReportCompilerState.output.testOutputS3Path = testOutputS3Path;
            addLog(user.uid, `Test output uploaded: ${testOutputS3Path}`);

               // Now generate a presigned URL so we can actually download it
               testOutputS3Url = await createPresignedUrl(
                process.env.REACT_APP_PAYWELL_RUNS_BUCKET_NAME,
                testOutputS3Path
              );
              addLog(user.uid, `Test output presigned URL created.`);
          } catch (error) {
            addLog(user.uid, `ERROR uploading test output: ${error.message}`);
            setUserProgress((prev) =>
              prev.map((up) =>
                up.uid === user.uid
                  ? { ...up, completedPresets: up.completedPresets + 1 }
                  : up
              )
            );
            continue;
          }

          // k) Compare
          addLog(user.uid, "Comparing live output to test output...");
          let localComparisonData;
          try {
            localComparisonData = await compareExcelFiles(
              liveOutputS3Path,
              testOutputS3Path,
              false
            );
            if (localComparisonData) {
              setComparisonData(localComparisonData);
              addLog(user.uid, "Comparison data set successfully.");
            } else {
              addLog(user.uid, "No comparison data returned from compareExcelFiles.");
            }
          } catch (error) {
            addLog(user.uid, `ERROR comparing Excel files: ${error.message}`);
          }

          // l) Store final result for this preset in allComparisonResults
          setAllComparisonResults((prev) => {
            const next = { ...prev };
            if (!next[user.email]) {
              next[user.email] = { userUid: user.uid, presets: [] };
            }
            next[user.email].presets.push({
              settingsId,
              liveOutputUrl: liveOutputS3Url,
              // testOutputUrl is now also a presigned URL
              testOutputUrl: testOutputS3Url,
              comparisonData: localComparisonData || {},
            });
            return next;
          });

          // Mark the preset as completed for the user
          setUserProgress((prev) =>
            prev.map((up) =>
              up.uid === user.uid
                ? { ...up, completedPresets: up.completedPresets + 1 }
                : up
            )
          );
        } // end FOR EACH PRESET

        // Mark this user as "complete"
        setUserProgress((prev) =>
          prev.map((up) =>
            up.uid === user.uid ? { ...up, status: "complete" } : up
          )
        );
      } // end FOR EACH USER
    } catch (error) {
      console.error("Error during run:", error);
    } finally {
      setLoading(false);
      console.log("Run function completed.");
    }
  };

  /**
   * Helper to add log lines to the specified user's logs array.
   */
  const addLog = (uid, message) => {
    setUserProgress((prev) =>
      prev.map((up) => {
        if (up.uid === uid) {
          return {
            ...up,
            logs: [...up.logs, message],
          };
        }
        return up;
      })
    );
  };

  /**
   * CSV export utility for a single comparison result.
   */
  const exportCsvForComparison = (comparisonData, fileNamePrefix) => {
    if (!comparisonData || typeof comparisonData !== "object") return;
    const exportData = [];

    // shape: { "sheetName": [ { status, sheet, oldRow, ... }, ...], ...}
    Object.entries(comparisonData).forEach(([sectionName, differences]) => {
      if (!Array.isArray(differences)) return;
      differences.forEach((diff) => {
        exportData.push({
          Section: sectionName,
          Status: diff.status,
          Sheet: diff.sheet,
          "Old Row": diff.oldRow,
          "New Row": diff.newRow,
          "Old Value": diff.oldValue,
          "New Value": diff.newValue,
          "Incorrect Cells": diff.incorrectCells,
        });
      });
    });

    const csv = Papa.unparse(exportData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileNamePrefix}-comparison.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * Calculate overall progress across all selected users
   */
  const totalSelected = selectedUsers.length;
  const completedCount = userProgress.filter((u) => u.status === "complete").length;
  const globalProgress = totalSelected
    ? Math.round((completedCount / totalSelected) * 100)
    : 0;

  /**
   * RENDER
   */
  return (
    <Box className="output-container" sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Output Test
      </Typography>

      {/* Exception Controls */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Exception Controls
        </Typography>
        <Box display="flex" gap={3}>
          {/* <label>
            <input
              type="checkbox"
              checked={exceptionsConfig.ENABLE_AUTOMATIONS_AUTO_COMMENT}
              onChange={() => toggleException("ENABLE_AUTOMATIONS_AUTO_COMMENT")}
            />
            <span style={{ marginLeft: 6 }}>AUTOMATIONS_AUTO_COMMENT</span>
          </label> */}
          <label>
            <input
            disabled
              type="checkbox"
              checked={exceptionsConfig.SESSION}
              onChange={() => toggleException("SESSION")}
            />
            <span style={{ marginLeft: 6 }}>Session Exceptions On</span>
          </label>
          <label>
            <input
            disabled
              type="checkbox"
              checked={exceptionsConfig.TIME}
              onChange={() => toggleException("TIME")}
            />
            <span style={{ marginLeft: 6 }}>Time Exceptions On</span>
          </label>
          <label>
            <input
            disabled
              type="checkbox"
              checked={exceptionsConfig.COMMISSION}
              onChange={() => toggleException("COMMISSION")}
            />
            <span style={{ marginLeft: 6 }}>Commission Exceptions On</span>
          </label>
        </Box>
      </Paper>

      {/* User Selection */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Select User(s):
        </Typography>

        <Autocomplete
          multiple
          options={users}
          getOptionLabel={(option) => `${option.email} - ${option.uid}`}
          filterSelectedOptions
          value={selectedUsers}
          isOptionEqualToValue={(option, value) => option.uid === value.uid}
          onChange={(event, newValue) => setSelectedUsers(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Type or select users..."
              label="Users"
            />
          )}
          sx={{ maxWidth: 600, mb: 2 }}
        />

        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<StartRounded />}
          variant="contained"
          onClick={run}
        >
          Compare
        </LoadingButton>
      </Paper>

      {/* Overall progress */}
      {totalSelected > 0 && (
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="body1" gutterBottom>
            Overall Progress: {completedCount} / {totalSelected} users complete
          </Typography>
          <LinearProgress variant="determinate" value={globalProgress} />
        </Paper>
      )}

      {/* Per-user progress & logs */}
      {userProgress.map((u) => {
        const userPercent = u.totalPresets
          ? Math.round((u.completedPresets / u.totalPresets) * 100)
          : 0;

        return (
          <Paper sx={{ p: 2, mb: 2 }} key={u.uid}>
            <Typography variant="h6">
              {u.email} ({u.uid})
            </Typography>
            <Typography variant="body2">
              Status: {u.status.toUpperCase()}
            </Typography>

            {/* Preset progress bar */}
            {u.totalPresets > 0 && (
              <Box mt={1} mb={1}>
                <Typography variant="body2" gutterBottom>
                  Preset Progress: {u.completedPresets} / {u.totalPresets}
                </Typography>
                <LinearProgress variant="determinate" value={userPercent} />
              </Box>
            )}

            {/* Detailed logs in an Accordion */}
            {u.logs.length > 0 && (
              <Accordion sx={{ mt: 1 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>Detailed Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {u.logs.map((line, idx) => (
                    <Typography
                      key={idx}
                      variant="body2"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {line}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </Paper>
        );
      })}

      {/* Organized display of final comparison results */}
      {Object.entries(allComparisonResults).map(([userEmail, userObj]) => {
        const { userUid, presets } = userObj;
        return (
          <Paper
            key={userEmail}
            sx={{ padding: "1rem", marginBottom: "2rem", border: "1px solid #ddd" }}
          >
            <Typography variant="h5" gutterBottom>
              Comparison Results for: {userEmail} ({userUid})
            </Typography>

            {presets.map((preset, idx) => {
              const {
                settingsId,
                liveOutputUrl,
                testOutputUrl,
                comparisonData,
              } = preset;

              console.log({
                settingsId,
                liveOutputUrl,
                testOutputUrl,
                comparisonData,
              })
              const fileNamePrefix = `${userEmail.replace(
                /[@.]/g,
                "-"
              )}-settings-${settingsId}`;

              return (
                <Box
                  key={`${userEmail}-${settingsId}-${idx}`}
                  sx={{
                    border: "1px solid #ccc",
                    padding: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Settings Preset: {settingsId}
                  </Typography>

                  {/* Download buttons */}
                  <Box display="flex" gap={2} flexWrap="wrap" mb={2}>
                    {liveOutputUrl && (
                      <a
                        href={liveOutputUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="contained" color="primary">
                          Download Live Output XLSX
                        </Button>
                      </a>
                    )}
                    {testOutputUrl && (
                      <a
                        href={testOutputUrl}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button variant="contained" color="primary">
                          Download Test Output XLSX
                        </Button>
                      </a>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        exportCsvForComparison(comparisonData, fileNamePrefix)
                      }
                    >
                      Download Comparison CSV
                    </Button>
                  </Box>

                  {/* Render the existing comparison table */}
                  <ComparisonView data={comparisonData} />
                </Box>
              );
            })}
          </Paper>
        );
      })}

      {/* 
        If you'd like to show the single "latest" comparison table again, 
        uncomment below:

        <ComparisonView data={comparisonData} />
      */}
    </Box>
  );
}

export default OutputTest;
