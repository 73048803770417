import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import RateObjectComponent from './RateItem';
import AttributeListBox from '../../Components/AttributeListBox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflowY: 'auto'
};

const DiscountRateManager = ({ rates, rateName, setRates, bucketName }) => {
  const [open, setOpen] = useState(false);

  const [ratesState, setRatesState] = useState(rates);
  const [rateTypes, setRateTypes] = useState(Object.keys(rates).sort((a, b) => parseInt(a) - parseInt(b)));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRateChange = (rateType, newRateSettings) => {
    const updatedRates = { ...rates, [rateType]: newRateSettings };
    setRatesState(updatedRates);
    setRates(updatedRates, 'discountPercentages');
  };

  const handleDiscountDistinctRatesChange = (newDistinctRates) => {
    // Initialize a copy of the current rates
    let updatedRates = {...rates};
  
    // Add new rates or initialize defaults for new entries
    newDistinctRates.forEach(rateType => {
      if (!updatedRates[rateType]) {
        updatedRates[rateType] = {
          rateType: "Fixed", rate: 0, setupFeeIncluded: false, split: true, salespeople: ["Close"]
        };
      }
    });
  
    // Remove rates that are not in the newDistinctRates array
    Object.keys(updatedRates).forEach(rateType => {
      if (!newDistinctRates.includes(rateType)) {
        delete updatedRates[rateType];
      }
    });
  
    // Update the local state for rate types
    setRateTypes(newDistinctRates.sort((a, b) => parseInt(a) - parseInt(b)));
  
    // Update the rates with the new changes and trigger any necessary updates
    setRates(updatedRates, 'discountPercentages');
  };  

  return (
    <div>
      <Button onClick={handleOpen} startIcon={<EditIcon />}>
        Manage {rateName} Rates
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="modal-title" variant="h6" component="h2">
              {rateName} Rates
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
            <AttributeListBox
            key={bucketName + "-discountDistinctRates"}
            multiple={true}
            freeSolo={true}
            onChange={handleDiscountDistinctRatesChange}
            id="discountDistinctRates"
            default={rateTypes}
            label=""
            limitTags={20}
            options={Array.from({ length: 99 }, (_, i) => `${i + 1}%`)} // Predefined rate types
          /> 
          
          {rateTypes.map(rateType => (
            <Box key={rateType} sx={{ mt: 2, mb: 2 }}>
              <RateObjectComponent
                on={true}
                id="rates"
                key={rateType + "-rate"}
                bucketName={bucketName}
                rateName={rateType}
                rate={ratesState[rateType] || { rateType: "Fixed", rate: 0, setupFeeIncluded: false, split: true, salespeople: ["Close"] }}
                onRateChange={(newRateDetails) => handleRateChange(rateType, newRateDetails)}
              />
            </Box>
          ))}
        </Box>
      </Modal>
    </div>
  );
};

export default DiscountRateManager;
