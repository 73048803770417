import React, { useCallback, useEffect, useState } from "react";

// Global contexts
import { useSnackBar } from "../../../contexts/SnackBarContext/SnackBarContext";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { useAuth } from "../../../contexts/AuthContext";

// Utility imports
import { RevertList } from "../../Components/RevertList";
import { objFieldChange } from "../utils";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { useGridApiContext, useGridApiRef } from "@mui/x-data-grid";
import AttributeListBox from "../../Components/AttributeListBox.js";
import { MultipleSelectPlaceholder } from "../../Components/SelectorBox/SelectorBox.js";
import { convertInputBoxValue } from "../../Components/InputBox/InputBox.js";
import ConfirmationDialog from "../../../components/Dialogs/ConfirmationDialog.js";
import { createNewStaffObject } from "../utils.js";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { validateName, numberCheck, isWarning, isValidNumericInput, processNumericInput } from "../utils/staffValidators.js";
import { formatHeaderName, attributeArrayRead, locationsArrayRead, checkBrandAndLocation, attendeeFormat, getDistinctSalesRates, percentOrValueFormat } from "../utils/staffHelpers.js";
import { currencyFormatter } from "../utils/staffHelpers.js";
import { EarningsCell } from "../components/EarningsCell.js";

/**
 * useStaffSettings: 
 *   - row update logic (staff & tier)
 *   - build columns logic
 *   - any table state (tab selection, modals, etc.)
 */
export function useStaffSettings() {


    const showSnackBar = useSnackBar();
    const { settings, dispatch, changes, setChanges } = useSettingsContext();
    const { getUID } = useAuth();
    const settingsId = getUrlVariableValue("settingsId");

    const [tableTabsValue, setTableTabsValue] = useState(0);
    const [tierColumns, setTierColumns] = useState([]);
    const [editingRowId, setEditingRowId] = useState(0);
    const [editingCellField, setEditingCellField] = useState("");
    const [rowModesModel, setRowModesModel] = React.useState({});
    const ratesApiRef = useGridApiRef();
    const tiersApiRef = useGridApiRef();
    // 1. Create centralized API ref helper function
    const getApiRef = (row) => row?.isTier ? tiersApiRef : ratesApiRef;
    const isTierRow = (row) => !!row?.isTier;
    const getCurrentApiRef = () => {
        const editingRow = ratesApiRef.current.getRow(editingRowId) ||
            tiersApiRef.current.getRow(editingRowId);
        return getApiRef(editingRow);
    };
    const validateRowExists = (id) => {
        return !!ratesApiRef.current.getRow(id) || !!tiersApiRef.current.getRow(id);
    };

    const [isEditing, setIsEditing] = useState(false);

    function renderLocationsEdit(props) {
        debugger;




        const { value, field, id, row } = props;
        let values = props.row.locations;
        let limit = 0;

        let options = ["All Studios"];

        if (settings && settings.staff.studiosFromAllPresets) {
            options = ["All Studios", ...settings.staff.studiosFromAllPresets];
        }


        const currentApiRef = getApiRef(row);

        const handleChange = (newValue) => {
            currentApiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        const handleRef = (element) => {
            if (element) {
                const input = element.querySelector(`input[value="${value}"]`);

                input?.focus();
            }
        };

        return (
            <MultipleSelectPlaceholder
                limitTags={limit}
                options={options}
                placeholder="0 Locations Selected"
                id={props.id}
                ref={handleRef}
                onChange={handleChange}
                // width={250}
                label="Studio"
                default={values}
                name="Studio"
            ></MultipleSelectPlaceholder>
        );
    }

    function renderAttributeListBoxEdit(props) {
        const { value, field, row } = props; // Destructure row from props
        let label = "Name(s)";
        let values = row.alternateNames; // Use row instead of props.row
        let limit = 2;
        let free = true;
        let options = [];
        let placeholder = "";

        if (props.field === "titles") {
            label = "Title(s)";
            values = row.titles; // Use row instead of props.row
            options = Object.values(props.colDef.valueOptions);
        }

        const apiRef = getApiRef(row);

        const handleChange = (newValue) => {
            apiRef.current.setEditCellValue({ id: props.id, field, value: newValue });
        };



        const handleRef = (element) => {
            if (element) {
                const input = element.querySelector('input');
                if (input) {
                    input.focus();
                }
            }
        };

        return (
            <AttributeListBox
                autoSelect
                placeholder={placeholder}
                limitTags={limit}
                ref={handleRef}
                onChange={handleChange}
                id={props.id}
                multiple
                freeSolo={free}
                default={values}
                size={"100%"}
                style={{ backgroundColor: "green" }}
                label={label}
                options={options}
                clearOnBlur={false}
            ></AttributeListBox>
        )
    }

    const handleAddStaffDialogSave = (formValues) => {
        const settingsId = getUrlVariableValue("settingsId");
        const { classSettings, generalSettings } = settings[settingsId];
        let { newStaffObj, id } = createNewStaffObject(
            settings,
            getUID,
            classSettings.classBuckets,
            generalSettings
        );

        let defaultTierId = Object.keys(settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers || {}).find(tier => settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers[tier].name === "Default" || settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers[tier].name === "");
        if (defaultTierId && (newStaffObj.tier === defaultTierId || newStaffObj.tier === "")) {
            newStaffObj = addStaffTier(newStaffObj);
        }

        Object.entries(formValues).forEach(([key, value]) => {
            newStaffObj[key] = value;
        });
        newStaffObj.isNew = false;
        newStaffObj.updated = true;

        dispatch({
            type: "ADD_STAFF_SETTINGS_STAFF_MEMBER",
            newStaffId: id,
            newStaff: newStaffObj,
        });

        let newRev = new RevertList(changes.getValues());
        newRev.addElement(
            id,
            null,
            newStaffObj
        );
        setChanges(newRev);
        setAddStaffModal(false);
    };

    const handleAddPayTierSave = (name) => {
        const settingsId = getUrlVariableValue("settingsId");
        const { classSettings, generalSettings } = settings[settingsId];
        const { newStaffObj, id } = createNewStaffObject(
            settings,
            getUID,
            classSettings.classBuckets,
            generalSettings
        );

        newStaffObj.isNew = false;
        newStaffObj.id = id;
        newStaffObj.updated = true;
        newStaffObj.name = name;
        newStaffObj.isTier = true;

        dispatch({
            type: "ADD_PAY_TIER_SETTINGS_STAFF_MEMBER",
            settingsId: settingsId,
            uid: getUID(),
            newStaffId: id,
            newStaff: newStaffObj,
        });

        let newRev = new RevertList(changes.getValues());
        newRev.addElement(
            id,
            null,
            newStaffObj
        );
        setChanges(newRev);
        setAddPayTierModal(false);
    }

    const handleAddStaffDialogClose = () => {
        setAddStaffModal(false);
    };

    const handleAddPayTierDialogClose = () => {
        setAddPayTierModal(false);
    }

    const handleTableTabsChange = React.useCallback((event, newValue) => {
        if (isEditing) return;
        setTableTabsValue(newValue);
    }, [isEditing]);

    const handleCellEditStart = (params, event) => {
        setEditingRowId(params.id);
        setIsEditing(true);
        const { id, field } = params;

        // Add safety checks and logging
        console.log('Params:', params);
        console.log('Is Tier:', params.row?.isTier);

        const currentApiRef = getApiRef(params.row);
        console.log('Current ApiRef:', currentApiRef);

        // Add more comprehensive null checks
        if (!currentApiRef?.current) {
            console.error('ApiRef or current is null');
            return;
        }

        try {
            const cellMode = currentApiRef.current.getCellMode?.(id, field) || 'view';
            if (field !== "actions" && cellMode === "view") {
                currentApiRef.current.startCellEditMode({
                    id: id,
                    field: field,
                });
            }
        } catch (error) {
            console.error('Error in handleCellEditStart:', error);
        }
    };
    const handleCellEditStop = (params, event) => {
        debugger;
        const { id, field } = params;
        const currentApiRef = getApiRef(params.row);
        currentApiRef.current?.stopCellEditMode({ id, field });  // Added optional chaining
        setIsEditing(false);
    };

    const handleDeleteClick = (id, tier) => async () => {
        if (tier) {
            let tierName = settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers[id].name;
            if (tierName === "Default") {
                showSna
                showSnackBar("Default tier cannot be deleted", "error");
                setIsEditing(false);
                return;
            }
            let prevVal = settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers[id];
            let newVal = { ...prevVal, status: "delete" };
            let newRev = new RevertList(changes.getValues());
            newRev.addElement(id, prevVal, newVal);
            setChanges(newRev);
            dispatch({
                type: "DELETE_PAY_TIER_SETTINGS_STAFF_MEMBER",
                uid: getUID(),
                tier: id,
                settingsId: getUrlVariableValue("settingsId"),
            });
            setIsEditing(false);
            return;
        }
        let prevVal = settings.staff.staff[id];
        let newVal = { ...prevVal, status: "delete" };
        let newRev = new RevertList(changes.getValues());
        newRev.addElement(id, prevVal, newVal);
        setChanges(newRev);
        dispatch({
            type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER_STATUS",
            uid: getUID(),
            id: id,
            status: "delete",
        });
        setIsEditing(false);
    };
    const actionRateColumn = React.useMemo(() => [{
        field: "actions",
        type: "actions",
        headerName: "Actions",
        cellClassName: "actions",
        getActions: ({ row }) => [
            <GridActionsCellItem
                icon={
                    <ConfirmationDialog
                        confirmTitle={`Delete ${row.name}`}
                        confirmDescription={`Are you sure you want to delete ${row.name}`}
                        confirmButtonColor="error"
                        confirmButtonText="Delete"
                        icon={<DeleteIcon />}
                        onConfirm={handleDeleteClick(row.id, row.isTier)}
                    />
                }
                label="Delete"
                color="inherit"
            />
        ]
    }], [handleDeleteClick]); // Ensure handleDeleteClick is memoized
    const [isEarningsDialogOpen, setIsEarningsDialogOpen] = useState(false);
    const [earningField, setEarningField] = useState(null);
    const [currentEarnings, setCurrentEarnings] = useState([]);
    const [earningBucketName, setEarningBucketName] = useState(null);
    const [currentEarningBucket, setCurrentEarningBucket] = useState(null);
    const openEarningsDialog = (field, rowId, earnings) => {
        setEarningField(field);
        setEditingRowId(rowId);
        setCurrentEarnings(earnings);
        setIsEarningsDialogOpen(true);

        let parts = field.split("Earning")[0].split(/(?=[A-Z])/);
        let timeBucketName = parts.map((part, index) => {
            if (index < parts.length) {
                return part.charAt(0).toUpperCase() + part.slice(1);
            }
            return part.toLowerCase();
        }).join("");
        setEarningBucketName(timeBucketName);
        setCurrentEarningBucket(settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[timeBucketName]);
    };
    const saveEarningsData = () => {
        if (!validateRowExists(editingRowId)) {
            console.error('Row does not exist');
            return;
        }

        const currentApiRef = getCurrentApiRef();
        const updatedRow = {
            ...currentApiRef.current.getRow(editingRowId),
            [earningField]: currentEarnings,
        };

        currentApiRef.current.updateRows([updatedRow]);
        const processFn = isTierRow(updatedRow) ? processRowUpdateTier : processRowUpdate;
        processFn(updatedRow);
        setIsEarningsDialogOpen(false);
    };
    const handleCloseCustomDialog = (confirmed) => {
        setIsEarningsDialogOpen(false);
        if (confirmed) {
            // Update editableStaff with new earnings data only if user confirmed
            saveEarningsData();
        }
    };
    const [tiersAnchor, setTiersAnchor] = useState(null);
    const handlePayTiersClick = (event) => {

        // let builtColumns = buildColumns("Info", null, true)
        // setRatesColumns(builtColumns.concat(actionRateColumn));

        setTableTabsValue(0);
        setTiersAnchor(event.currentTarget);
    };
    const handlePayTiersClose = () => {

        // let builtColumns = buildColumns("Info", null, false)
        // setRatesColumns(builtColumns.concat(actionRateColumn));

        setTableTabsValue(0);
        setTiersAnchor(null);
    };
    const ratesColumns = React.useMemo(() => {
        const payTier = Boolean(tiersAnchor); // Move here

        if (!settings || !settings[settingsId]) return [];

        let type, bucket = {};
        const classBuckets = settings[settingsId].classSettings.classBuckets;

        // Determine column type based on active tab
        if (tableTabsValue === 0) {
            type = "Info";
        } else if (tableTabsValue === 1) {
            type = "Hourly";
        } else if (tableTabsValue === 2) {
            type = "Earning";
        } else {
            const bucketKeys = Object.keys(classBuckets).sort();
            const bucketIndex = tableTabsValue - 3;

            if (bucketIndex >= 0 && bucketIndex < bucketKeys.length) {
                const bucketName = bucketKeys[bucketIndex];
                type = `${bucketName.charAt(0).toLowerCase()}${bucketName.slice(1)}Rate`;
                bucket = classBuckets[bucketName];
            } else {
                type = "commissionRate";
            }
        }

        const columns = buildColumns(type, bucket, payTier);
        return columns.concat(actionRateColumn);
    }, [tableTabsValue, tiersAnchor, settings, settingsId]); // Add tiersAnchor
    const [addStaffModal, setAddStaffModal] = useState(false);
    const [addPayTierModal, setAddPayTierModal] = useState(false);
    /*************************************************************
     *                 1) Row Update Logic
     *************************************************************/
    const addStaffTier = useCallback((staffRow) => {
        // Get the settings ID and tiers
        const settingsId = getUrlVariableValue("settingsId");
        const tiers = settings[settingsId].generalSettings.staffTiers || {};

        // Find the tier that matches the staff member's tier name
        //let matchingTier = Object.values(tiers).find(tier => tier.name === staffRow.tier);
        let matchingTier = null;
        let matchingTierId = Object.keys(tiers).find(tier => tier === staffRow.tier);

        matchingTier = tiers[matchingTierId];

        if (!matchingTierId) {
            //check if there is a default tier
            matchingTier = Object.values(tiers).find(tier => tier.name === "Default" || tier.name === "");
            matchingTierId = matchingTier.id;

            //return staffRow; // Return unchanged if no matching tier found
        }

        if (!matchingTier) {
            return staffRow;
        }

        staffRow.tier = matchingTierId;

        // Create a new object with the original staff data
        const updatedStaff = { ...staffRow };

        // Copy rate-related properties from the matching tier
        const rateProperties = [];

        // Recursive function to collect all properties, including nested ones
        const collectProperties = (obj, prefix = '') => {
            Object.entries(obj).forEach(([key, value]) => {
                // Skip administrative properties
                if (['name', 'id', 'status', 'statusField', 'tier', 'updated', 'isNew', 'isTier', "sharedRates", "paywellUID", "sharedInfo", "brand", 'alternateNames'].includes(key)) {
                    return;
                }

                if (value && typeof value === 'object' && !Array.isArray(value)) {
                    // If it's a nested object, recurse with the current path
                    collectProperties(value, prefix ? `${prefix}.${key}` : key);
                } else {
                    // Add the full property path
                    rateProperties.push(prefix ? `${prefix}.${key}` : key);
                }
            });
        };

        // Collect all properties from the matching tier
        collectProperties(matchingTier);

        let defaultStaffValues = createNewStaffObject(settings, getUID, settings[settingsId].classSettings.classBuckets, settings[settingsId].generalSettings).newStaffObj;

        const getNestedValue = (obj, path) => {
            return path.split('.').reduce((current, key) => current?.[key], obj);
        };

        // Helper function to set nested property value
        const setNestedValue = (obj, path, value) => {
            const keys = path.split('.');
            const lastKey = keys.pop();
            const target = keys.reduce((current, key) => {
                current[key] = current[key] || {};
                return current[key];
            }, obj);
            target[lastKey] = value;
        };

        const compareValues = (val1, val2) => {
            // If either value is null/undefined, do direct comparison
            if (val1 == null || val2 == null) {
                return val1 === val2;
            }

            // For arrays
            if (Array.isArray(val1) && Array.isArray(val2)) {
                if (val1.length !== val2.length) return false;
                return val1.every((item, index) => compareValues(item, val2[index]));
            }

            // For objects (but not arrays)
            if (typeof val1 === 'object' && typeof val2 === 'object') {
                const keys1 = Object.keys(val1);
                const keys2 = Object.keys(val2);
                if (keys1.length !== keys2.length) return false;
                return keys1.every(key => compareValues(val1[key], val2[key]));
            }

            // For strings, numbers, booleans
            return val1 === val2;
        };
        // Copy only rate properties that exist in the matching tier
        rateProperties.forEach(prop => {
            //if (matchingTier.hasOwnProperty(prop)) {
            let currentStaffValue = getNestedValue(updatedStaff, prop);
            const tierValue = getNestedValue(matchingTier, prop);
            const defaultValue = getNestedValue(defaultStaffValues, prop);

            if (!compareValues(tierValue, defaultValue) && !compareValues(currentStaffValue, tierValue)) {
                setNestedValue(updatedStaff, prop, tierValue);
            }
            //}
            // if (matchingTier.hasOwnProperty(prop) && matchingTier[prop] !== defaultStaffValues[prop]) {
            //   updatedStaff[prop] = matchingTier[prop];
            // }
        });

        return updatedStaff;
    }, [settings]);

    const processRowUpdate = useCallback(async (newRow) => {
        // e.g. old row
        const prevRow = settings?.staff?.staff[newRow.id];
        const updatedField = objFieldChange(prevRow, newRow);

        // Validate name
        if (!newRow.name || newRow.name.trim() === "") {
            showSnackBar("Staff name cannot be empty", "error");
            throw new Error("Staff name cannot be empty");
        }

        // if "tier" changed, do tier logic
        if (updatedField === "tier") {
            newRow = addStaffTier(newRow);
        }

        // finalize updated row
        const updatedRow = {
            ...newRow,
            isNew: false,
            updated: true,
            status: newRow.status || "update",
            statusField: updatedField,
        };

        // RevertList logic
        const newRev = new RevertList(changes.getValues());
        newRev.addElement(updatedRow.id, prevRow, updatedRow);
        setChanges(newRev);

        // dispatch
        dispatch({
            type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER",
            uid: getUID(),
            id: updatedRow.id,
            newStaff: updatedRow,
        });

        // check for warnings
        const warningMsg = isWarning(updatedRow);
        debugger;
        if (warningMsg) {
            showSnackBar(warningMsg, "warning");
        }

        return updatedRow;
    }, [settings, showSnackBar, dispatch, getUID, changes, setChanges, addStaffTier]);

    const processRowUpdateTier = useCallback(async (newRow) => {
        const settingsId = getUrlVariableValue("settingsId");
        const prevRow = settings?.[settingsId]?.generalSettings?.staffTiers?.[newRow.id];
        const updatedField = objFieldChange(prevRow, newRow);

        if (!newRow.name || newRow.name.trim() === "") {
            showSnackBar("Tier name cannot be empty", "error");
            throw new Error("Staff name cannot be empty");
        }

        const updatedRow = {
            ...newRow,
            isNew: false,
            updated: true,
            status: newRow.status || "update",
            statusField: updatedField,
        };

        // RevertList
        const newRev = new RevertList(changes.getValues());
        newRev.addElement(updatedRow.id, prevRow, updatedRow);
        setChanges(newRev);

        // dispatch
        dispatch({
            type: "UPDATE_PAY_TIER_SETTINGS_STAFF_MEMBER",
            uid: getUID(),
            id: updatedRow.id,
            settingsId,
            newStaffId: updatedRow.id,
            newStaff: updatedRow,
        });

        // Warnings
        const warningMsg = isWarning(updatedRow);
        if (warningMsg) {
            showSnackBar(warningMsg, "warning");
        }

        return updatedRow;
    }, [settings, showSnackBar, dispatch, getUID, changes, setChanges]);

    /**
     * Process any error thrown during row update.
     */
    function processRowUpdateError(error) {
        debugger;
        if (error) {
            showSnackBar(error.message, "error");
        } else {
            // showSnackBar(null);
        }
    }
    /*************************************************************
     *            3) Memoized Rows
     *************************************************************/
    // Example of building "rows" for the main staff grid
    // Ensure settings and settings.staff are defined before memoizing rows
    const memoizedRatesRows = React.useMemo(() => {

        if (!settings || !settings.staff) return [];
        // Access the brand from the settings
        let brand =
            settings[getUrlVariableValue("settingsId")].generalSettings.brand;
        let studios =
            settings[getUrlVariableValue("settingsId")].generalSettings.studios;

        return Object.values(settings.staff.staff).filter((s) => {

            if (!checkBrandAndLocation(s.brand, s.locations, brand, studios)) {
                return false;
            }

            const staffTypes = settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes;

            let payType;

            if (tableTabsValue === 0) {
                payType = 'hourlyPay';
            } else if (tableTabsValue === 1) {
                payType = "hourlyPay";
            } else if (tableTabsValue === 2) {
                //payType = "Earning";
                payType = "hourlyPay";
            } else if (
                tableTabsValue ===
                Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets).length + 3
            ) {
                payType = "commissionPay";
            } else {
                payType = "sessionPay";
            }

            for (let i = 0; i < staffTypes.length; i++) {
                if (staffTypes[i].name === s.type) {
                    return staffTypes[i][payType];
                }
            }
            return true;
        });
    }, [settings, tableTabsValue]);
    const memoizedPayTiersRows = React.useMemo(() => {
        if (!settings || !settings.staff) return [];
        if (!settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers || Object.keys(settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers).length === 0) {
            // Create default tier
            const { newStaffObj, id } = createNewStaffObject(
                settings,
                getUID,
                settings[getUrlVariableValue("settingsId")].classSettings.classBuckets,
                settings[getUrlVariableValue("settingsId")].generalSettings
            );

            let defaultTier = newStaffObj;
            defaultTier.name = "Default";
            defaultTier.isNew = false;
            defaultTier.id = "Default";
            defaultTier.updated = true;
            defaultTier.isTier = true;

            // Update the settings with the default tier
            dispatch({
                type: "ADD_PAY_TIER_SETTINGS_STAFF_MEMBER",
                uid: getUID(),
                id: defaultTier.id,
                settingsId: getUrlVariableValue("settingsId"),
                newStaffId: defaultTier.id,
                newStaff: defaultTier,
            });

            let newRev = new RevertList(changes.getValues());
            newRev.addElement(
                defaultTier.id,
                null,
                defaultTier
            );
            setChanges(newRev);

            showSnackBar("Staff Tiers Created. Please Press Save.", "success");

            return [defaultTier];
        }
        return Object.values(settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers)
            .filter((s) => {
                let payType;

                if (tableTabsValue === 0) {
                    payType = 'hourlyPay';
                } else if (tableTabsValue === 1) {
                    payType = "hourlyPay";
                } else if (tableTabsValue === 2) {
                    payType = "hourlyPay";
                } else if (
                    tableTabsValue ===
                    Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets).length + 3
                ) {
                    payType = "commissionPay";
                } else {
                    payType = "sessionPay";
                }

                return true;
            })
            .sort((a, b) => {
                // If a is Default, it comes first
                if (a.name === 'Default') return -1;
                // If b is Default, it comes first
                if (b.name === 'Default') return 1;
                // Otherwise, sort alphabetically
                return a.name.localeCompare(b.name);
            });
    }, [settings, tableTabsValue]);
   
    function renderNumericEditCell(params) {
        const { id, field, row, value } = params; // Add value destructuring
        const apiRef = getApiRef(row);

        const handleChange = (event) => {
            const newValue = processNumericInput(event.target.value);
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        return (
            <input
                type="text"
                value={value || ''}
                onChange={handleChange}
                style={{ width: '100%', border: 'none', outline: 'none' }}
            />
        );
    }
    /*****************
     * COLUMN BUILDING
     ********************/
    function buildColumns(type, bucket, tierTable) {
        console.log("buildColumns()")
        if (settings) {

            let columnArray = [];
            if (type === "Info") {

                return buildInfoColumns(tierTable);
            } else if (type === "Hourly") {

                let buckets = settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets;
                let timeSettings = settings[getUrlVariableValue("settingsId")].timeSettings;

                return buildHourlyColumnsNew(buckets, timeSettings, tierTable);
            } else if (type === "Earning") {
                let buckets = settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets;

                return buildEarningColumns(buckets, tierTable);
            } else if (type === "hourlyRate") {
                return buildHourlyColumns(tierTable);
            } else if (type === "commissionRate") {
                return buildSalesCommissionColumns(tierTable);
            } else {
                addNameColumn(columnArray, tierTable);
                addZeroColumn(columnArray, bucket, type);
                addRateColumns(columnArray, bucket, type);
            }

            return columnArray;

        } else {
            return [
                { field: 'col1', headerName: 'Column 1', width: 150 },
                { field: 'col2', headerName: 'Column 2', width: 150 },
                { field: 'col3', headerName: 'Column 3', width: 150 },
            ];
        }
    }
    function buildInfoColumns(tierTable) {
        let staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
        let orderedFields = staffFields.filter(field => field.order >= 0).sort((a, b) => a.order - b.order);
        let defaultInfoColumns1 = [];

        orderedFields.forEach(attribute => {
            let name = attribute.name;
            if (attribute.field === 'name' && tierTable) {
                name = "Tier";
            }
            if (attribute.field === 'tier' && tierTable) {
                return;
            }
            if (attribute.field === 'alternateNames' && tierTable) {
                return;
            }
            if (attribute.field === 'type' && tierTable) {
                return;
            }
            const columnConfig = {
                field: attribute.field,
                width: 200,
                editable: true,
                renderHeader: () => <strong>{name}</strong>,
                description: attribute.description,
                preProcessEditCellProps: (params) => {
                    const hasError = attribute.required && (!params.props.value);
                    if (hasError) {
                        processRowUpdateError({ message: "Required Field", severity: "error" });
                    } else {
                        processRowUpdateError(null);
                    }
                    return { ...params.props, error: hasError };
                },
                valueSetter: (params) => {
                    const value = params.value || attribute.default;
                    return { ...params.row, [attribute.field]: value };
                }
            };

            // Additional configurations for specific fields
            switch (attribute.field) {
                case 'name':
                    columnConfig.renderCell = (params) => (
                        <Box>
                            {params.value === 'Default' ? (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5
                                }}>
                                    <VerifiedOutlinedIcon fontSize="small" sx={{ color: 'text.secondary' }} />
                                    {params.value}
                                </Box>
                            ) : (
                                params.value
                            )}
                        </Box>
                    );
                    columnConfig.preProcessEditCellProps = (params) => {
                        const errorMessage = validateName(params.props.value);
                        const hasError = errorMessage !== null;
                        if (hasError) {
                            processRowUpdateError({ message: errorMessage, severity: "error" });
                        } else {
                            processRowUpdateError(null);
                        }
                        return { ...params.props, error: hasError };
                    };
                    break;
                case 'type':
                    columnConfig.type = "singleSelect";
                    columnConfig.valueOptions = settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes.map(type => type.name);
                    break;
                case 'alternateNames':
                case 'titles':
                    columnConfig.renderEditCell = renderAttributeListBoxEdit;
                    columnConfig.valueOptions = attribute.options;
                    columnConfig.valueFormatter = ({ value }) => attributeArrayRead(value);
                    break;
                case 'email':
                case 'idString':
                case 'secondIdString':
                    break;
                case 'locations':
                    columnConfig.width = 450;
                    // columnConfig.renderEditCell = renderLocationsEdit;
                    columnConfig.renderEditCell = (params) => renderLocationsEdit(params);
                    columnConfig.valueFormatter = ({ value }) => locationsArrayRead(value);
                    break;
                case 'homeLocation':
                    columnConfig.type = "singleSelect";
                    columnConfig.valueOptions = settings[getUrlVariableValue("settingsId")].generalSettings.studios;
                    break;
                case 'tier':
                    columnConfig.type = "singleSelect";
                    const tierObjects = settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers || {};
                    // Create array of objects with id and name
                    const tierOptions = Object.entries(tierObjects).map(([id, tier]) => ({
                        value: id,  // The ID (object key) as the actual value
                        label: tier.name  // The name as the display value
                    }));
                    // Sort by display name
                    tierOptions.sort((a, b) => a.label.localeCompare(b.label));

                    columnConfig.valueOptions = tierOptions;
                    // Format the display value (show name instead of ID)
                    columnConfig.valueFormatter = (params) => {
                        if (!params.value) return '';
                        const tier = tierObjects[params.value];
                        return tier ? tier.name : '';
                    };
                    // When saving, use the ID value
                    columnConfig.valueGetter = (params) => {
                        const tierName = params.row.tier;
                        const tierEntry = Object.entries(tierObjects).find(([_, tier]) => tier.name === tierName);
                        return tierEntry ? tierEntry[0] : tierName;
                    };
                    break;
                default:
                    columnConfig.type = attribute.type;
                    columnConfig.valueOptions = attribute.options;
            }

            defaultInfoColumns1.push(columnConfig);
        });

        return defaultInfoColumns1;
    }
    function buildHourlyColumnsNew(buckets, timeSettings, tierTable) {
        const hourlyColumns = [];
        let name = tierTable ? "Tier" : "Staff Name";

        hourlyColumns.push({
            field: "name",
            renderHeader: () => <strong>{name}</strong>,
            width: 150,
            editable: false,
        });

        // Separate and sort the buckets by isStandardTimeType
        const sortedBuckets = Object.entries(buckets).sort(([keyA, valueA], [keyB, valueB]) => {
            if (valueA.isStandardTimeType && !valueB.isStandardTimeType) {
                return -1; // A comes first if it is standard and B is not
            }
            if (!valueA.isStandardTimeType && valueB.isStandardTimeType) {
                return 1; // B comes first if it is standard and A is not
            }
            return 0; // Keep original order if both are the same type
        });

        // Map over sorted buckets to create columns for those marked 'Hourly'
        sortedBuckets.forEach(([key, value]) => {
            let field = key.toLowerCase() + "Hourly";
            if (key === "Regular") {
                field = "hourly"; // Use a general 'hourly' field name for regular hours
            }
            if (value.type === "Hourly") {
                hourlyColumns.push({
                    field: field,
                    headerName: key,
                    width: 130,
                    editable: true,
                    // preProcessEditCellProps: (params) => {
                    //     return { ...params.props, error: numberCheck(params.props.value) };
                    // },
                    // valueFormatter: ({ value }) => currencyFormatter.format(value || 0),


                    renderEditCell: renderNumericEditCell, // Add this line
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error: !isValidNumericInput(params.props.value)
                    }),
                    valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
                });
            }
        });

        if (timeSettings.general.useComplexTimeSettings ?? false) {
            // Add standard payroll components like overtime
            if (timeSettings.general.overtimeRate === 'Overtime Rate') {
                hourlyColumns.push({
                    field: 'overtimeHourly',
                    headerName: 'Overtime',
                    width: 130,
                    editable: true,
                    // preProcessEditCellProps: (params) => {
                    //     return { ...params.props, error: numberCheck(params.props.value) };
                    // },
                    // valueFormatter: ({ value }) => currencyFormatter.format(value || 0)

                    renderEditCell: renderNumericEditCell, // Add this line
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error: !isValidNumericInput(params.props.value)
                    }),
                    valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
                });
            }
            if (timeSettings.general.doubleOvertimeRate === 'Double Overtime Rate') {
                hourlyColumns.push({
                    field: 'doubleOvertimeHourly',
                    headerName: 'Double Overtime',
                    width: 130,
                    editable: true,
                    // preProcessEditCellProps: (params) => {
                    //     return { ...params.props, error: numberCheck(params.props.value) };
                    // },
                    // valueFormatter: ({ value }) => currencyFormatter.format(value || 0)


                    renderEditCell: renderNumericEditCell, // Add this line
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error: !isValidNumericInput(params.props.value)
                    }),
                    valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
                });
            }
            if (timeSettings.general.holidayRate === 'Holiday Rate') {
                hourlyColumns.push({
                    field: 'holidayHourly',
                    headerName: 'Holiday Pay',
                    width: 130,
                    editable: true,
                    // preProcessEditCellProps: (params) => {
                    //     return { ...params.props, error: numberCheck(params.props.value) };
                    // },
                    // valueFormatter: ({ value }) => currencyFormatter.format(value || 0)

                    renderEditCell: renderNumericEditCell, // Add this line
                    preProcessEditCellProps: (params) => ({
                        ...params.props,
                        error: !isValidNumericInput(params.props.value)
                    }),
                    valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
                });
            }
        }

        return hourlyColumns;
    }
    function buildEarningColumns(buckets, tierTable = false) {
        const earningColumns = [];
        let name = tierTable ? "Tier" : "Staff Name";

        earningColumns.push({
            field: "name",
            renderHeader: () => <strong>{name}</strong>,
            width: "200",
            editable: false
        });

        Object.entries(buckets)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .forEach(([key, value]) => {
                let frequency = value.frequency;
                let headerName = formatHeaderName(key);
                if (value.type === "Earning") {
                    let field = key.charAt(0).toLowerCase() + key.slice(1) + "Earning";
                    earningColumns.push({
                        field: field,
                        headerName: headerName,
                        width: "200",
                        editable: true,

                        renderCell: (params) => {

                            const earningsArray = params?.row[field] || [];
                            const earningsItemsCount = earningsArray.length;
                            let totalEarnings = earningsArray.reduce((acc, curr) => {
                                // If amount exists, use it
                                if (curr.amount) {
                                    return acc + curr.amount;
                                }
                                // If both rate and hours exist, multiply them
                                if (curr.rate && curr.hours) {
                                    return acc + (curr.hours * curr.rate);
                                }
                                // If neither condition is met, add 0
                                return acc;
                            }, 0);
                            if (earningsArray.length === 0) {
                                totalEarnings = null;
                            }
                            let freqDisplay = "Check";

                            if (frequency === "Yearly") {
                                freqDisplay = "YR";
                            } else if (frequency === "Monthly") {
                                freqDisplay = "MO";
                            }
                            const totalString = totalEarnings === null ? "-" : `$${totalEarnings} / ${freqDisplay}`;
                            return (
                                <div className="MuiDataGrid-cell" style={{ width: "100%", border: "none" }}>
                                    <div className="MuiDataGrid-cellContent" style={{ width: "100%" }}>
                                        {totalString}
                                    </div>
                                </div>
                            );
                        },
                        renderEditCell: (params) => (
                            <EarningsCell
                                value={params.row[field] || []}
                                field={field}
                                openEarningsDialog={() => openEarningsDialog(field, params.id, params.row[field] || [])}
                                frequency={frequency}
                                rowId={params.id}
                            />
                        )
                    });
                }
            });

        return earningColumns;
    }
    function buildHourlyColumns(tierTable) {
        let name = tierTable ? "Tier" : "Staff Name";

        const hourlyRateColumns = [
            {
                field: "name",
                renderHeader: () => <strong>{name}</strong>,
                width: 150,
                editable: false,
            },
            {
                field: "hourly",



                width: 205,
                editable: true,
                // valueFormatter: ({ value }) => currencyFormatter.format(value),
                renderHeader: () => <strong>{"Primary Hourly Rate"}</strong>,


                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),
                valueFormatter: ({ value }) => currencyFormatter.format(value || 0),



            },
            {
                field: "secondaryHourly",
                // preProcessEditCellProps: (params) => {
                //     let hasError = numberCheck(params.props.value);
                //     return { ...params.props, error: hasError };
                // },
                width: 205,
                editable: true,
                // valueFormatter: ({ value }) => currencyFormatter.format(value),
                renderHeader: () => <strong>{"Secondary Hourly Rate"}</strong>,


                renderEditCell: renderNumericEditCell, // Add this
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),
                valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
            },
            {
                field: "overtime",
                // preProcessEditCellProps: (params) => {
                //     let hasError = numberCheck(params.props.value);
                //     return { ...params.props, error: hasError };
                // },
                width: 205,
                editable: true,
                // valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
                renderHeader: () => <strong>{"Overtime Rate"}</strong>,


                renderEditCell: renderNumericEditCell, // Add this
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),
                valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
            },
            {
                field: "doubleOvertime",
                // preProcessEditCellProps: (params) => {
                //     let hasError = numberCheck(params.props.value);
                //     return { ...params.props, error: hasError };
                // },
                width: 205,
                editable: true,
                // valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
                renderHeader: () => <strong>{"Double Overtime Rate"}</strong>,


                renderEditCell: renderNumericEditCell, // Add this
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),
                valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
            },
            {
                field: "holiday",
                // preProcessEditCellProps: (params) => {
                //     let hasError = numberCheck(params.props.value);
                //     return { ...params.props, error: hasError };
                // },
                width: 205,
                editable: true,
                // valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
                renderHeader: () => <strong>{"Holiday Rate"}</strong>,


                renderEditCell: renderNumericEditCell, // Add this
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),
                valueFormatter: ({ value }) => currencyFormatter.format(value || 0),
            },
        ];
        return hourlyRateColumns;
    }
    function buildSalesCommissionColumns(tierTable = false) {
        let name = tierTable ? "Tier" : "Staff Name";

        const salesColumns = [
            {
                field: "name",
                renderHeader: () => <strong>{name}</strong>,
                width: 175,
                editable: false,
            },
            {
                field: "agreement",
                editable: true,
                width: 175,

                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),
                // valueFormatter: ({ value }) => currencyFormatter.format(value || 0),


                valueFormatter: ({ value }) => percentOrValueFormat(value),
                renderHeader: () => <strong>{"Agreement Rate"}</strong>,
                valueGetter: (params) => {
                    return params.row.commissionRate.agreement;
                },
                valueSetter: (params) => {
                    params.row.commissionRate.agreement = params.value;
                    return params.row;
                },
            },
            {
                field: "retail",
                editable: true,
                width: 175,
                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),

                valueFormatter: ({ value }) => percentOrValueFormat(value),
                renderHeader: () => <strong>{"Retail Rate"}</strong>,
                valueGetter: (params) => {
                    return params.row.commissionRate.retail;
                },
                valueSetter: (params) => {
                    params.row.commissionRate.retail = params.value;
                    return params.row;
                },
            },
        ];

        let salesBuckets = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets;
        let allDistinctRates = getDistinctSalesRates(salesBuckets);
        //add custom rates here
        for (let i = 0; i < allDistinctRates.length; i++) {
            salesColumns.push({
                field: allDistinctRates[i],
                editable: true,
                width: 175,

                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),

                valueFormatter: ({ value }) => percentOrValueFormat(value),

                renderHeader: () => <strong>{allDistinctRates[i]}</strong>,
                valueGetter: (params) => {
                    return params.row.commissionRate[allDistinctRates[i]] || "";
                },
                valueSetter: (params) => {
                    params.row.commissionRate[allDistinctRates[i]] = params.value || "";
                    return params.row;
                },
            });
        }

        return salesColumns;
    }
    function addNameColumn(columnArray, tierTable) {
        let name = tierTable ? "Tier" : "Instructor Name";
        columnArray.push({
            field: "name",
            renderHeader: () => <strong>{name}</strong>,
            width: 195,
            editable: false,
        });
    }
    function addZeroColumn(columnArray, bucket, type) {
        let rateObj = bucket.rate;

        if (
            (rateObj.includeZero === true ||
                rateObj.includeZero === "Paid A Different Amount") &&
            rateObj.structure !== "None (Zero)"
        ) {
            let zeroCol = {
                field: "zero",
                editable: true,
                width: 175,
                valueFormatter: (params) => {
                    let value = parseFloat(params.value);
                    if (value < 1 && value > 0) {
                        return value * 100 + "%";
                    } else {
                        return currencyFormatter.format(value);
                    }
                },
                renderHeader: () => <strong>{"Zero Attendees"}</strong>,
                valueGetter: (params) => {
                    return params.row[type].zero;
                },
                valueSetter: (params) => {
                    params.row[type].zero = params.value;
                    return params.row;
                },


                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),

            };
            columnArray.push(zeroCol);
        }
    }
    function addRateColumns(columnArray, bucket, type) {
        let rateObj = bucket.rate;
        if (rateObj.structure === "Flat Rate") {
            let headerLabel =
                "Flat Rate (1-" + convertInputBoxValue(bucket.capacity) + " attendees)";
            let flatCol = {
                field: "flat",
                editable: true,
                width: 245,
                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),


                valueFormatter: (params) => {
                    let value = parseFloat(params.value);
                    if (value < 1 && value > 0) {
                        return value * 100 + "%";
                    } else {
                        return currencyFormatter.format(value);
                    }
                },
                renderHeader: () => <strong>{headerLabel}</strong>,
                valueGetter: (params) => {
                    return params.row[type].flat;
                },
                valueSetter: (params) => {
                    params.row[type].flat = params.value;
                    return params.row;
                },
                preProcessEditCellProps: (params) => {
                    return { ...params.props, error: numberCheck(params.props.value) };
                },
            };
            columnArray.push(flatCol);
        } else if (rateObj.structure === "Base Rate + Per Head Bonus") {
            let headerBaseLabel = "Base Rate";
            let w = 175;
            if (type === "privateRate") {
                headerBaseLabel = "Semi-Private Base Rate (2 Attendees)";
                w = 275;
            }
            let baseCol = {
                field: "base",
                width: w,
                editable: true,
                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),

                renderHeader: () => <strong>{headerBaseLabel}</strong>,
                valueFormatter: (params) => {
                    let value = parseFloat(params.value);
                    if (value < 1 && value > 0) {
                        return value * 100 + "%";
                    } else {
                        return currencyFormatter.format(value);
                    }
                },
                valueGetter: (params) => {
                    return params.row[type].base;
                },
                valueSetter: (params) => {
                    params.row[type].base = params.value;
                    return params.row;
                },

            };

            let headerBonusLabel = "Per Head Bonus";
            let wHead = 175;
            if (type === "privateRate") {
                headerBonusLabel = "Semi-Private Per Head Bonus (2+ Attendees)";
                wHead = 305;
            }
            let bonusCol = {
                field: "perHead",
                width: wHead,
                editable: true,

                renderHeader: () => <strong>{headerBonusLabel}</strong>,
                valueFormatter: (params) => {
                    let value = parseFloat(params.value);
                    if (value < 1 && value > 0) {
                        return value * 100 + "%";
                    } else {
                        return currencyFormatter.format(value);
                    }
                },
                valueGetter: (params) => {
                    return params.row[type].perHead;
                },
                valueSetter: (params) => {
                    params.row[type].perHead = params.value;
                    return params.row;
                },


                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),

            };

            let attendeeLabel = "";
            let wAfter = 160;
            if (type === "privateRate") {
                attendeeLabel = "Semi-Private";
                wAfter = 190;
            }
            let attendeeStartCol = {
                field: "afterAttendee",
                width: wAfter,
                editable: true,

                renderHeader: () => <strong>{"Starting AFTER"}</strong>,
                valueFormatter: ({ value }) => attendeeFormat(value, attendeeLabel),
                valueGetter: (params) => {
                    if (params.row[type].afterAttendee === null) {
                        return rateObj.defaultAfterAttendee;
                    }
                    return params.row[type].afterAttendee;
                },
                valueSetter: (params) => {
                    params.row[type].afterAttendee = params.value;
                    return params.row;
                },


                renderEditCell: renderNumericEditCell, // Add this line
                preProcessEditCellProps: (params) => ({
                    ...params.props,
                    error: !isValidNumericInput(params.props.value)
                }),

            };
            columnArray.push(baseCol);
            columnArray.push(bonusCol);
            columnArray.push(attendeeStartCol);
        } else if (rateObj.structure === "Custom Amount") {
            let capacity = parseInt(convertInputBoxValue(bucket.capacity));
            if (type !== "privateRate") {
                for (let i = 1; i <= capacity; i++) {
                    columnArray.push({
                        field: "attendee" + i,
                        width: 175,
                        editable: true,

                        renderHeader: () => <strong>{i + " Attendees"}</strong>,
                        valueFormatter: (params) => {
                            let value = parseFloat(params.value);
                            if (value < 1 && value > 0) {
                                return value * 100 + "%";
                            } else {
                                return currencyFormatter.format(value);
                            }
                        },
                        valueGetter: (params) => {
                            let rowArray = params.row[type].custom;
                            if (rowArray.length >= i) {
                                return params.row[type].custom[i];
                            }
                            return "0";
                        },
                        valueSetter: (params) => {
                            params.row[type].custom[i] = params.value;
                            params.row[type].custom[0] = params.row[type].zero;
                            return params.row;
                        },


                        renderEditCell: renderNumericEditCell, // Add this line
                        preProcessEditCellProps: (params) => ({
                            ...params.props,
                            error: !isValidNumericInput(params.props.value)
                        }),
                        // valueFormatter: ({ value }) => currencyFormatter.format(value || 0),

                    });
                }
            } else {
                for (let i = 2; i <= capacity; i++) {
                    columnArray.push({
                        field: "attendee" + i,
                        width: 215,
                        editable: true,

                        renderHeader: () => (
                            <strong>{i + " Semi-Private Attendees"}</strong>
                        ),
                        valueFormatter: (params) => {
                            let value = parseFloat(params.value);
                            if (value < 1 && value > 0) {
                                return value * 100 + "%";
                            } else {
                                return currencyFormatter.format(value);
                            }
                        },
                        valueGetter: (params) => {
                            let rowArray = params.row[type].custom;
                            if (rowArray.length >= i) {
                                return params.row[type].custom[i];
                            }
                            return "0";
                        },
                        valueSetter: (params) => {
                            params.row[type].custom[i] = params.value;
                            params.row[type].custom[0] = params.row[type].zero;
                            params.row[type].custom[1] = params.row[type].private;
                            return params.row;
                        },


                        renderEditCell: renderNumericEditCell, // Add this line
                        preProcessEditCellProps: (params) => ({
                            ...params.props,
                            error: !isValidNumericInput(params.props.value)
                        }),

                    });
                }
            }
        }
    }
    const isLoading = !settings.hasOwnProperty("1") && !settings.hasOwnProperty("staff");
    if (isLoading) {
        return {
            isLoading,
            // Return dummy or empty values for the rest
            tableTabsValue,
            setTableTabsValue,
            ratesColumns: [],
            memoizedRatesRows: [],
            processRowUpdate: () => { },      // no-op or throw
            processRowUpdateTier: () => { },  // no-op or throw
            addStaffModal,
            setAddStaffModal,
            // ...
        };
    }

    /*************************************************************
     * Export everything from the hook
     *************************************************************/
    return {
        isLoading,
        settings,

        // tab logic
        tableTabsValue,
        setTableTabsValue,

        // columns
        ratesColumns,
        // setRatesColumns,

        tierColumns,

        // row data
        memoizedRatesRows,
        memoizedPayTiersRows,

        // row update methods
        processRowUpdate,
        processRowUpdateTier,

        // tier-logic
        addStaffTier,

        // modals
        addStaffModal,
        setAddStaffModal,
        addPayTierModal,
        setAddPayTierModal,


        //new
        tableTabsValue,
        ratesApiRef,
        tiersApiRef,
        rowModesModel,
        setRowModesModel,
        handleCellEditStart,
        handleCellEditStop,
        editingRowId,
        setEditingRowId,
        editingCellField,
        setEditingCellField,
        handlePayTiersClick,


        handleAddStaffDialogSave,
        handleAddPayTierSave,
        handleAddStaffDialogClose,
        handleAddPayTierDialogClose,
        isEarningsDialogOpen,


        isEarningsDialogOpen, setIsEarningsDialogOpen,
        earningField, setEarningField,
        currentEarnings, setCurrentEarnings,
        earningBucketName, setEarningBucketName,
        currentEarningBucket, setCurrentEarningBucket,
        tiersAnchor,
        handleTableTabsChange,
        handlePayTiersClose,
        handleCloseCustomDialog

    };
}