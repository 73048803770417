import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { v4 as uuidv4 } from "uuid";
import { brands } from "../StudioSettings/CommonVariables";
// Helper function to create a new staff object
export function createNewStaffObject(
  settings,
  getUID,
  classBuckets,
  generalSettings
) {
  let id = uuidv4();
  let buckets = Object.keys(classBuckets);

  let timeBucketsObj = settings?.timeSettings?.timeBuckets || {'Regular': {}, 'Secondary': {}};
  let timeBuckets = Object.keys(timeBucketsObj);

  const newStaffObj = {
    commissionRate: {
      retail: "",
      agreement: "",
    },
    alternateNames: [],
    locations: generalSettings.studios,
    secondaryHourly: 0,
    overtimeHourly: 0,
    doubleOvertimeHourly: 0,
    holidayHourly: 0,
    secondIdString: "",
    type: generalSettings.staffTypes[0].name,
    paywellUID: getUID(),
    email: "",
    homeLocation: "",
    hourly: 0,
    name: "",
    isNew: true,
    status: "new",
    id: id,
    homeRegion: "",
    idString: "",
    titles: [],
    brand: findBrand(settings),
    sharedRates: true,
    sharedInfo: true,

    //staff updates
    tier: "",
  };

  timeBuckets.forEach((bucket) => {
    let type = timeBucketsObj[bucket].type;
    if(bucket === "Regular"){
      newStaffObj.hourly = 0;
    }else if(type === "Hourly"){
      const rateKey = bucket.charAt(0).toLowerCase() + bucket.slice(1) + "Hourly";
      newStaffObj[rateKey] = 0;
    }else if(type === "Earning"){
      const rateKey = bucket.charAt(0).toLowerCase() + bucket.slice(1) + "Earning";
      newStaffObj[rateKey] = [];
    }
  });
  
  let staffFields = generalSettings?.staffFields;
  if(staffFields){
    staffFields.forEach((attribute) => {
      if(attribute.field !== "locations" && attribute.field !== "type"){
        newStaffObj[attribute.field] = attribute.default;
      }
    });
  }

  buckets.forEach((bucket) => {
    const rateKey = bucket.charAt(0).toLowerCase() + bucket.slice(1) + "Rate";
    newStaffObj[rateKey] = {
      none: false,
      zero: "0",
      base: "0",
      perHead: "0",
      afterAttendee: "0",
      flat: "0",
      custom: [],
    };
  });

  return { newStaffObj, id };
}
export function objFieldChange(old, newObj) {
  const oldKeys = Object.keys(old);
  const newObjKeys = Object.keys(newObj);

  if (oldKeys.length !== newObjKeys.length) {
    return "Objects have different keys";
  }

  // var changes = [];
  for (let key of oldKeys) {
    if (old[key] !== newObj[key]) {
      return key;
      // return `Objects have different values for key "${key}": ${old[key]} and ${newObj[key]}`;
      // return
    }
  }

  // if (changes.length > 0) {
  //   return changes;
  // } else {
  //   return false;
  // }

  return false;
  // return "Objects have the same keys and values";
}
export const findBrand = (settings) => {
  // Access the brand from the settings
  //let brand = settings[getUrlVariableValue("settingsId")].generalSettings.brand;
  let brand = settings[getUrlVariableValue("settingsId")].generalSettings.brand || null;
  //|| settings[getUrlVariableValue("settingsId")].generalSettings.franchise;
  
  let studios =
    settings[getUrlVariableValue("settingsId")].generalSettings.studios;

  // If brand is not defined, find it
  if (!brand) {
    const brandList = brands;

    for (let element1 of brandList) {
      for (let element2 of studios) {
        if (element2.includes(element1)) {
          brand = element1;
          break;
        }
      }

      if (brand) {
        break;
      }
    }
  }

  return brand;
};
