import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import AddPayTierComponent from "../components/AddPayTierComponent";


const AddPayTierDialog = ({ open, onClose, onSave, tiers }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle component={"h3"}>Add New PayTier</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill out all pay tier info.
        </DialogContentText>
        <AddPayTierComponent onSave={onSave} onClose={onClose} tiers={tiers} />
      </DialogContent>
    </Dialog>
  );
};

export default AddPayTierDialog;
