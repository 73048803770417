// ExcelComparison.js
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Paper, Typography, Button, CircularProgress } from '@mui/material';
import XLSX from '@sheet/core'; // same as used in your OutputTest.js
import { ComparisonView } from './ComparisonView';

// Import functions from excel-comparison-utils, including the new full-sheet diff generator.
import {
  generateFullSheetDiff
} from './excel-comparison-utils';

const ExcelComparison = () => {
  // Local state for each file, a loading flag, and for the comparison results.
  const [leftFile, setLeftFile] = useState(null);
  const [rightFile, setRightFile] = useState(null);
  const [leftFileName, setLeftFileName] = useState('');
  const [rightFileName, setRightFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [comparisonData, setComparisonData] = useState(null);
  const [error, setError] = useState('');

  // Use react-dropzone for the left upload area.
  const onDropLeft = useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setLeftFile(acceptedFiles[0]);
      setLeftFileName(acceptedFiles[0].name);
    }
  }, []);

  const { getRootProps: getRootPropsLeft, getInputProps: getInputPropsLeft, isDragActive: isDragActiveLeft } =
    useDropzone({
      onDrop: onDropLeft,
      accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

  // …and for the right upload area.
  const onDropRight = useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setRightFile(acceptedFiles[0]);
      setRightFileName(acceptedFiles[0].name);
    }
  }, []);

  const { getRootProps: getRootPropsRight, getInputProps: getInputPropsRight, isDragActive: isDragActiveRight } =
    useDropzone({
      onDrop: onDropRight,
      accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

  // Read a file as an ArrayBuffer and then convert it into a workbook.
  const readFileAsWorkbook = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Problem reading the file"));
      };
      reader.onload = () => {
        try {
          const arrayBuffer = reader.result;
          const workbook = XLSX.read(arrayBuffer, {
            type: 'array',
            cellFormula: true,
            xlfn: true,
            PRN: true,
            bookVBA: true,
            cellStyles: true
          });
          resolve(workbook);
        } catch (e) {
          reject(e);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };


  // Add this sorting utility at the top of ExcelComparison.js
// const sortWorkbookSheets = (workbook) => {
//   // Make a shallow copy of the workbook (we only replace Sheets and SheetNames)
//   const sortedWorkbook = {
//     ...workbook,
//     Sheets: {},
//     SheetNames: [...workbook.SheetNames]
//   };

//   workbook.SheetNames.forEach(sheetName => {
//     const sheet = workbook.Sheets[sheetName];

//     // Use the pro version's sheet_to_json with options to get every cell,
//     // filling missing values with empty strings.
//     const data = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });

//     // If the sheet is empty or has no rows, keep the original
//     if (!data || data.length === 0) {
//       sortedWorkbook.Sheets[sheetName] = sheet;
//       return;
//     }

//     // Assume the first row is the header
//     const header = data[0];
//     const rows = data.slice(1);

//     // Sort the rows using a delimiter-based comparison.
//     // We pad the rows to the same length (using header.length as a guide)
//     // so that rows with missing trailing cells are compared consistently.
//     const sortedRows = rows.sort((a, b) => {
//       const maxLength = Math.max(a.length, b.length, header.length);
//       const aValues = [];
//       const bValues = [];
//       for (let i = 0; i < maxLength; i++) {
//         aValues.push(a[i] !== undefined ? a[i] : '');
//         bValues.push(b[i] !== undefined ? b[i] : '');
//       }
//       // Join with a delimiter unlikely to appear in data (here using pipe '|')
//       const aString = aValues.join('|');
//       const bString = bValues.join('|');
//       return aString.localeCompare(bString);
//     });

//     // Rebuild the data with the header and sorted rows
//     const sortedData = [header, ...sortedRows];
//     // Create a new sheet from the sorted data
//     const sortedSheet = XLSX.utils.aoa_to_sheet(sortedData);
//     sortedWorkbook.Sheets[sheetName] = sortedSheet;
//   });

//   return sortedWorkbook;
// };


// // Update the handleCompare function to use sorted workbooks
// const handleCompare = async () => {
//   if (!leftFile || !rightFile) return;
//   setError('');
//   setLoading(true);
//   setComparisonData(null);
  
//   try {
//     // Read and sort both workbooks
//     const [leftWorkbook, rightWorkbook] = await Promise.all([
//       readFileAsWorkbook(leftFile).then(sortWorkbookSheets),
//       readFileAsWorkbook(rightFile).then(sortWorkbookSheets)
//     ]);

//     // Build diff result using sorted sheets
//     const diffResult = {};
//     leftWorkbook.SheetNames.forEach(sheetName => {
//       if (rightWorkbook.SheetNames.includes(sheetName)) {
//         diffResult[sheetName] = generateFullSheetDiff(
//           leftWorkbook.Sheets[sheetName],
//           rightWorkbook.Sheets[sheetName]
//         );
//       } else {
//         diffResult[sheetName] = "MISSING SHEET IN NEW FILE";
//       }
//     });

//     rightWorkbook.SheetNames.forEach(sheetName => {
//       if (!leftWorkbook.SheetNames.includes(sheetName)) {
//         diffResult[sheetName] = "NEW SHEET";
//       }
//     });

//     setComparisonData(diffResult);
//   } catch (e) {
//     console.error(e);
//     setError("Error comparing files. Please check that both files are valid Excel workbooks.");
//   } finally {
//     setLoading(false);
//   }
// };


// Helper function to remove rows that are completely empty
const removeEmptyRows = (data) => {
  if (!Array.isArray(data) || data.length === 0) return data;
  // Always keep the header row (first row)
  const header = data[0];
  // Filter out rows where every cell is empty (after converting to string and trimming)
  const filteredRows = data.slice(1).filter(row => 
    !row.every(cell => String(cell).trim() === '')
  );
  return [header, ...filteredRows];
};

// Sorting utility for workbooks
const sortWorkbookSheets = (workbook) => {
  // Make a shallow copy of the workbook (we only replace Sheets and SheetNames)
  const sortedWorkbook = {
    ...workbook,
    Sheets: {},
    SheetNames: [...workbook.SheetNames]
  };

  workbook.SheetNames.forEach(sheetName => {
    const sheet = workbook.Sheets[sheetName];

    // Use the pro version's sheet_to_json with options to get every cell,
    // filling missing values with empty strings.
    let data = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });
    // Remove completely empty rows to reduce clutter
    data = removeEmptyRows(data);

    // If the sheet is empty or has no rows, keep the original
    if (!data || data.length === 0) {
      sortedWorkbook.Sheets[sheetName] = sheet;
      return;
    }

    // Assume the first row is the header
    const header = data[0];
    const rows = data.slice(1);

    // Sort the rows using a delimiter-based comparison.
    // We pad the rows to the same length (using header.length as a guide)
    // so that rows with missing trailing cells are compared consistently.
    const sortedRows = rows.sort((a, b) => {
      const maxLength = Math.max(a.length, b.length, header.length);
      const aValues = [];
      const bValues = [];
      for (let i = 0; i < maxLength; i++) {
        aValues.push(a[i] !== undefined ? a[i] : '');
        bValues.push(b[i] !== undefined ? b[i] : '');
      }
      // Join with a delimiter unlikely to appear in data (here using pipe '|')
      const aString = aValues.join('|');
      const bString = bValues.join('|');
      return aString.localeCompare(bString);
    });

    // Rebuild the data with the header and sorted rows
    const sortedData = [header, ...sortedRows];
    // Create a new sheet from the sorted data
    const sortedSheet = XLSX.utils.aoa_to_sheet(sortedData);
    sortedWorkbook.Sheets[sheetName] = sortedSheet;
  });

  return sortedWorkbook;
};

// Updated handleCompare function to use sorted (and empty-row filtered) workbooks
const handleCompare = async () => {
  if (!leftFile || !rightFile) return;
  setError('');
  setLoading(true);
  setComparisonData(null);
  
  try {
    // Read and sort both workbooks
    const [leftWorkbook, rightWorkbook] = await Promise.all([
      readFileAsWorkbook(leftFile).then(sortWorkbookSheets),
      readFileAsWorkbook(rightFile).then(sortWorkbookSheets)
    ]);

    // Build diff result using sorted sheets
    const diffResult = {};
    leftWorkbook.SheetNames.forEach(sheetName => {
      if (rightWorkbook.SheetNames.includes(sheetName)) {
        diffResult[sheetName] = generateFullSheetDiff(
          leftWorkbook.Sheets[sheetName],
          rightWorkbook.Sheets[sheetName]
        );
      } else {
        diffResult[sheetName] = "MISSING SHEET IN NEW FILE";
      }
    });

    rightWorkbook.SheetNames.forEach(sheetName => {
      if (!leftWorkbook.SheetNames.includes(sheetName)) {
        diffResult[sheetName] = "NEW SHEET";
      }
    });

    setComparisonData(diffResult);
  } catch (e) {
    console.error(e);
    setError("Error comparing files. Please check that both files are valid Excel workbooks.");
  } finally {
    setLoading(false);
  }
};


  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Excel Comparison
      </Typography>
      <Typography variant="body1" gutterBottom>
        Upload (or drag and drop) two Excel files to compare.
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
        {/* Left Drop Zone */}
        <Paper
          {...getRootPropsLeft()}
          sx={{
            flex: 1,
            p: 2,
            border: '2px dashed #aaa',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: isDragActiveLeft ? '#f0f0f0' : 'inherit'
          }}
        >
          <input {...getInputPropsLeft()} />
          {leftFileName ? (
            <Typography variant="subtitle1">File: {leftFileName}</Typography>
          ) : (
            <Typography variant="subtitle1">
              {isDragActiveLeft ? "Drop the file here" : "Drag & drop left Excel file, or click to select"}
            </Typography>
          )}
        </Paper>

        {/* Right Drop Zone */}
        <Paper
          {...getRootPropsRight()}
          sx={{
            flex: 1,
            p: 2,
            border: '2px dashed #aaa',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: isDragActiveRight ? '#f0f0f0' : 'inherit'
          }}
        >
          <input {...getInputPropsRight()} />
          {rightFileName ? (
            <Typography variant="subtitle1">File: {rightFileName}</Typography>
          ) : (
            <Typography variant="subtitle1">
              {isDragActiveRight ? "Drop the file here" : "Drag & drop right Excel file, or click to select"}
            </Typography>
          )}
        </Paper>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCompare}
          disabled={!leftFile || !rightFile || loading}
        >
          Compare Files
        </Button>
      </Box>

      {loading && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <CircularProgress size={24} />
          <Typography variant="body1">Comparing...</Typography>
        </Box>
      )}

      {error && (
        <Typography variant="body1" color="error" gutterBottom>
          {error}
        </Typography>
      )}

      {/* Display the comparison result using ComparisonView */}
      {comparisonData && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Comparison Results
          </Typography>
          <ComparisonView data={comparisonData} />
        </Box>
      )}
    </Box>
  );
};

export default ExcelComparison;
