import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { Autocomplete } from '@mui/material';
import { useSettingsContext } from "../../contexts/SettingsContext";
import { getSettingsGroup as getSettingsGroup, settingSearchResults } from '../StudioSettings/CommonVariables';
import { stringToKeywords } from '../../utility-functions/utility-functions';
import { useHistory } from "react-router-dom";

function SearchPopup({ open, setOpen }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryKeywords, setSearchQueryKeywords] = useState([]);
  const [selectedResult, setSelectedResult] = useState(undefined);
  const { settings } = useSettingsContext();
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();
  const inputRef = useRef(null);

  useEffect(() => {
    const newSearchResults = settingSearchResults(settings);
    setSearchResults(newSearchResults);
  }, [settings]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 20)
  }, [open, inputRef]);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function goToSetting(settingData) {
    const newQueryParams = new URLSearchParams(location.search);
    if (settingData.path?.tab) {
      newQueryParams.set("tabId", settingData.path.tab);
    }
    let newLocation = {
      pathname: `/studio-setting/${settingData.path.page}`,
      search: newQueryParams.toString(),
    };
    if (settingData.path?.id) {
      newLocation.hash = `#${settingData.path?.id}`
    }
    history.push(newLocation);
    handleClose();
  }

  const goToSelectedSetting = _ => { goToSetting(selectedResult) };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <SearchIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ paddingBottom: '8px' }}><Typography component="h3" sx={{ fontWeight: '500' }}>Search Settings</Typography></DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '16px' }}>
            Search for specific settings in the search bar below. Enter keywords to start.
          </Typography>
          <Autocomplete
            fullWidth
            options={searchResults}
            groupBy={(option) => getSettingsGroup(settings, option.path.page)}
            filterOptions={(options) => {
              return options.filter((option) => {
                if (!option) return false;
                // 1. Get the list of keywords for the option
                let optionKeywords = [];
                optionKeywords = optionKeywords.concat(stringToKeywords(option.label));
                if (option?.alt) {
                  for (let altLabel of option.alt) {
                    try {
                      const altKeywords = stringToKeywords(altLabel);
                      optionKeywords = optionKeywords.concat(altKeywords);
                    } catch (err) {
                      console.warn(`Setting contains invalid keyword in \`alt\` attribute. Setting: `, option, "Error message: ", err);
                    }
                  }
                }
                const groupLabel = getSettingsGroup(settings, option.path.page);
                try {
                  optionKeywords = optionKeywords.concat(stringToKeywords(groupLabel))
                } catch (err) {
                  console.warn(`Setting contains invalid group label "${groupLabel}". Setting: `, option, "Error message: ", err);
                }

                /* 2. If any of the keywords in the query aren't included as prefixes of the option keywords
                      return false */
                for (let queryKeyword of searchQueryKeywords) {
                  let keywordFound = false;
                  for (let optionKeyword of optionKeywords) {
                    if (optionKeyword.startsWith(queryKeyword)) {
                      keywordFound = true;
                      break;
                    }
                  }
                  if (!keywordFound) return false;
                }

                return true;
              })
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.label}>{option.label}</li>
            )}
            noOptionsText={"No settings found from this search."}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Search settings"}
                type="text"
                fullWidth
                variant="outlined"
                inputRef={inputRef}
                onKeyDown={(e) => {
                  if (e?.key === 'Enter' && !!selectedResult) {
                    goToSelectedSetting();
                  }
                }}
              />
            )}
            inputValue={searchQuery}
            onInputChange={(_, newQuery, reason) => {
              // This occurs when the user clicks off of the input box
              if (reason === "reset" && newQuery === "") return;
              if (newQuery === undefined || newQuery === null) return;

              setSearchQuery(newQuery);
              setSearchQueryKeywords(stringToKeywords(newQuery));
            }}
            value={selectedResult}
            onChange={(event, selectedValue) => {
              setSelectedResult(selectedValue)
              if (event?.code === "Enter") {
                /*
                 * Here, you have to pass the selected value directly, because calling
                 * `goToSelectedSetting` will result in the previous state's path being used 
                 * for the navigation, because it hasn't updated yet.
                 * */
                goToSetting(selectedValue);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ opacity: .6, color: 'black' }}>
            Cancel
          </Button>
          <Button disabled={!selectedResult} onClick={goToSelectedSetting} color="primary">
            Go to Setting
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SearchPopup;
