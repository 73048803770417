import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";

// export default function AttributeListBox(props) {

//   const [currentlySelected, setvalue] = useState(props.default);
//   let readOnly = props.readOnly === undefined ? false : props.readOnly;
//   let placeholder =
//     props.placeholder === undefined ? "" : props.placeholder;
//   let limitTags = props.limitTags === undefined ? 4 : props.limitTags;
//   let grouping = props.grouping === undefined ? null : props.grouping;
//   let disabled = props.disabled === undefined ? false : props.disabled;
//   let noOptions = props.noOptions === undefined ? "No Options" : props.noOptions;
//   let options = props.options === undefined || props.options === null || Object.keys(props.options).length === 0 ? [] : props.options;
//   let clearOnBlur = props.clearOnBlur === undefined ? true : props.clearOnBlur;

//   const handleChange = (event, value) => {
//     debugger;
//     setvalue(value);

//     if (props.onChange) {
//       props.onChange(value, props.id);
//     }
//     if (props.setIsStudioSelected) {
//       if (currentlySelected.length > 0) {
//         props.setIsStudioSelected(true);
//       } else {
//         props.setIsStudioSelected(false);
//       }
//     }
//   };

//   return (
//     <Autocomplete
//       noOptionsText={noOptions}
//       autoSelect={props.autoSelect}
//       disabled={disabled}
//       sx={{ width: props.size }}
//       className={props.className}
//       disableClearable
//       limitTags={limitTags}
//       readOnly={readOnly}
//       autoComplete
//       multiple
//       id={props.id}
//       options={options.map((option) => option)}
//       defaultValue={currentlySelected}
//       onChange={handleChange}
//       freeSolo={props.freeSolo}
//       groupBy={grouping}
//       clearOnBlur={clearOnBlur}
//       //getOptionLabel={removeSubstrings}
//       renderTags={(value, getTagProps) =>
//         value.map((option, index) => (
//           <Chip variant="outlined" label={option} {...getTagProps({ index })} />
//         ))
//       }
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label={props.label}
//           placeholder={placeholder}
//           onKeyDown={(event) => {
//             if (event.key === "Backspace") {
//               event.stopPropagation();
//             } else if (event.key === "Enter" && !props.freeSolo) {
//               // Prevent the Enter key from propagating to the DataGrid
//               event.preventDefault();
//               event.stopPropagation();
//             }
//           }}
//         />
//       )}
//     />
//   );
// }
// AttributeListBox.js


import PropTypes from "prop-types";

export default function AttributeListBox(props) {
  // Initialize state with props.default or an empty array for multiple selections
  const [currentlySelected, setValue] = useState(props.default || (props.multiple ? [] : ""));

  // Destructure props with default values
  const {
    readOnly = false,
    placeholder = "",
    limitTags = 4,
    grouping = null,
    disabled = false,
    noOptions = "No Options",
    options = [],
    clearOnBlur = true,
    freeSolo = false,
    multiple = true, // Default to multiple if not specified
    size = "100%", // Default size
    className = "",
    label = "",
    onChange,
    setIsStudioSelected,
    id,
    autoSelect = false,
  } = props;

  // Handle changes in the Autocomplete component
  const handleChange = (event, value) => {
    setValue(value);

    if (onChange) {
      onChange(value, id);
    }

    if (setIsStudioSelected) {
      setIsStudioSelected(multiple ? value.length > 0 : Boolean(value));
    }
  };

  // Handle key down events to manage Enter key behavior
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Prevent the Enter key from triggering DataGrid's cell edit mode exit
      event.preventDefault();
      event.stopPropagation();

      // If freeSolo is enabled and the input has a value, add it to the selection
      if (freeSolo) {
        const inputValue = event.target.value.trim();

        // For multiple selections
        if (multiple) {
          if (inputValue && !currentlySelected.includes(inputValue)) {
            const newValue = [...currentlySelected, inputValue];
            setValue(newValue);
            if (onChange) {
              onChange(newValue, id);
            }
          }
        }
        // For single selection
        else {
          if (inputValue) {
            setValue(inputValue);
            if (onChange) {
              onChange(inputValue, id);
            }
          }
        }
      }
    } else if (event.key === "Backspace") {
      // Prevent Backspace from propagating to avoid unintended deletions
      event.stopPropagation();
    }
  };

  // Optional: Synchronize external default changes with internal state
  // useEffect(() => {
  //   if (props.default !== undefined) {
  //     setValue(props.default);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.default]);

  return (
    <Autocomplete
      noOptionsText={noOptions}
      autoSelect={autoSelect}
      disabled={disabled}
      sx={{ width: size }}
      className={className}
      disableClearable
      limitTags={limitTags}
      readOnly={readOnly}
      autoComplete
      multiple={multiple}
      id={id}
      options={options.map((option) => option)}
      value={currentlySelected} // Controlled component
      onChange={handleChange}
      freeSolo={freeSolo}
      groupBy={grouping}
      clearOnBlur={clearOnBlur}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          onKeyDown={handleKeyDown} // Attach the keydown handler
        />
      )}
    />
  );
}

// Define prop types for better type checking and documentation
AttributeListBox.propTypes = {
  default: PropTypes.any,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  limitTags: PropTypes.number,
  grouping: PropTypes.func,
  disabled: PropTypes.bool,
  noOptions: PropTypes.string,
  options: PropTypes.array,
  clearOnBlur: PropTypes.bool,
  freeSolo: PropTypes.bool,
  multiple: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  setIsStudioSelected: PropTypes.func,
  id: PropTypes.string,
  autoSelect: PropTypes.bool,
};


export function SingleAttributeListBox(props) {
  const [currentlySelected, setvalue] = useState(props.default);
  let readOnly = props.readOnly === undefined ? false : props.readOnly;
  let placeholder =
    props.placeholder === undefined ? "" : props.placeholder;

  const handleChange = (event, value) => {
    setvalue(value);

    if (props.onChange) {
      props.onChange(value, props.id);
    }
    if (props.setIsStudioSelected) {
      if (currentlySelected.length > 0) {
        props.setIsStudioSelected(true);
      } else {
        props.setIsStudioSelected(false);
      }
    }
  };

  return (
    <Autocomplete
      autoSelect
      className={props.className}
      disableClearable
      readOnly={readOnly}
      autoComplete
      multiple={false}
      id={props.id}
      options={props.options.map((option) => option)}
      defaultValue={currentlySelected}
      onChange={handleChange}
      freeSolo={props.freeSolo}
      style={{ width: 200 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          size="large"
          className={`${props.className}-text-field`}
          label={props.label}
          placeholder={placeholder}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              event.stopPropagation();
            } else if (event.key === "Enter" && !props.freeSolo) {
              event.stopPropagation();
            }
          }}
        />
      )}
    />
  );
}

export function SingleValueInput(props) {
  const [currentlySelected, setvalue] = useState(props.default);

  const handleChange = (event, value) => {
    setvalue(value);

    if (props.onChange) {
      props.onChange(value, props.id);
    }
    if (props.setIsStudioSelected) {
      if (currentlySelected.length > 0) {
        props.setIsStudioSelected(true);
      } else {
        props.setIsStudioSelected(false);
      }
    }
  };

  return (
    <TextField
      className={props.className}
      id={props.id}
      select
      label="Select"
      value={currentlySelected}
      // onChange={handleChange}
      helperText="Please select an option"
      variant="outlined"
      freeSolo
    >
      {props.options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
