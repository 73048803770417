/////////////////////////////////////////////
// AddStaffComponent.js
/////////////////////////////////////////////
import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Autocomplete,
  Button,
  DialogActions,
  Stack,
  FormHelperText,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Chip,
} from "@mui/material";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useStudioScrapeStatus } from "../../contexts/StudioScrapeStatus/StudioScrapeStatusContext";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { makeFirstLetterLowercase } from "./utils"; // Adjust path as necessary
import "./staffDialog.scss";
import { getUrlVariableValue } from "../../utility-functions/utility-functions";
import { useAuth } from "../../contexts/AuthContext";
import { updateStaffMasterSettings } from "../../services/staff.services";
import { useStaffContext } from "../../contexts/StaffContext/StaffContext";
import { LoadingButton } from "@mui/lab";
import NumericTextField from "../CustomTextFields/NumericTextField";
import EarningsDialog from "../../pages/StaffSettings/dialogs/EarningsDialog";

import { AddRounded, EditRounded } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

/* Reusable InputComponent used by DynamicRates, unchanged. */
const InputComponent = React.memo(({ label, type, value, onChange, InputProps }) => {
  return type === 'number' ? (
    <NumericTextField label={label} value={value} onChange={onChange} InputProps={InputProps} />
  ) : (
    <TextField
      label={label}
      type={type}
      fullWidth
      margin="normal"
      value={value || ""}
      onChange={onChange}
    />
  );
});

/* For building out dynamic rate input fields, unchanged. */
const GeneratedInputComponents = React.memo(
  ({ bucketName, bucketSetup, editableStaff, handleChange }) => {
    const ratePropertyName = makeFirstLetterLowercase(bucketName) + "Rate";

    return (
      <>
        {bucketSetup.rate.includeZero === "Paid A Different Amount" && (
          <InputComponent
            key={`${bucketName}-zero-attendee`}
            label="Zero Attendee"
            type="number"
            value={editableStaff[ratePropertyName]?.zero || ""}
            onChange={(e) =>
              handleChange(`${ratePropertyName}.zero`, e.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputMode: "decimal",
              pattern: "[0-9]*",
            }}
          />
        )}

        {bucketSetup.rate.structure === "Flat Rate" && (
          <InputComponent
            key={`${bucketName}-flat-rate`}
            label={`${bucketSetup.rate.structure} (1-12 Attendees)`}
            type="number"
            value={editableStaff[ratePropertyName]?.flat || ""}
            onChange={(e) =>
              handleChange(`${ratePropertyName}.flat`, e.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputMode: "decimal",
              pattern: "[0-9]*",
            }}
          />
        )}

        {bucketSetup.rate.structure === "Base Rate + Per Head Bonus" && (
          <>
            <InputComponent
              key={`${bucketName}-base-rate`}
              label="Base Rate"
              type="number"
              value={editableStaff[ratePropertyName]?.base || ""}
              onChange={(e) =>
                handleChange(`${ratePropertyName}.base`, e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputMode: "decimal",
                pattern: "[0-9]*",
              }}
            />
            <InputComponent
              key={`${bucketName}-per-head-rate`}
              label="Per Head Rate"
              type="number"
              value={editableStaff[ratePropertyName]?.perHead || ""}
              onChange={(e) =>
                handleChange(`${ratePropertyName}.perHead`, e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputMode: "decimal",
                pattern: "[0-9]*",
              }}
            />
            <InputComponent
              key={`${bucketName}-starting-after`}
              label="Starting After"
              type="number"
              value={editableStaff[ratePropertyName]?.afterAttendee || ""}
              onChange={(e) =>
                handleChange(
                  `${ratePropertyName}.afterAttendee`,
                  e.target.value
                )
              }
            />
          </>
        )}

        {bucketSetup.rate.structure === "Custom Amount" &&
          Array.from(
            { length: parseInt(bucketSetup.capacity.split("~")[1], 10) },
            (_, i) => (
              <InputComponent
                key={`${bucketName}-custom-amount-${i}`}
                label={`Custom Amount for ${i + 1} Attendees`}
                type="number"
                value={
                  editableStaff[ratePropertyName]?.customAmounts?.[i] || ""
                }
                onChange={(e) =>
                  handleChange(
                    `${ratePropertyName}.customAmounts[${i}]`,
                    e.target.value
                  )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputMode: "decimal",
                  pattern: "[0-9]*",
                }}
              />
            )
          )}
      </>
    );
  }
);

/* For each "bucketName Rates" section. */
const DynamicRateInput = React.memo(
  ({ bucketName, bucketSetup, editableStaff, handleChange }) => {
    return (
      <>
        <Typography variant="h5">
          {bucketName} Rates
        </Typography>
        <GeneratedInputComponents
          bucketName={bucketName}
          bucketSetup={bucketSetup}
          editableStaff={editableStaff}
          handleChange={handleChange}
        />
      </>
    );
  }
);

/* Wrapper to map over class buckets and render DynamicRateInput. */
const DynamicRates = ({ settings, editableStaff, handleChange }) => {
  const classBuckets =
    settings[getUrlVariableValue("settingsId")].classSettings.classBuckets;

  return (
    <>
      {Object.entries(classBuckets).map(([bucketName, bucketSetup]) => (
        <DynamicRateInput
          key={bucketName}
          bucketName={bucketName}
          bucketSetup={bucketSetup}
          editableStaff={editableStaff}
          handleChange={handleChange}
        />
      ))}
    </>
  );
};

// Helper function to sanitize the staff name for Excel.
// export const sanitizeForExcel = (str) => {
//   if (typeof str !== 'string') return str;
//   const invalidChars = [
//     '=', "'", '%', '`', '+', '-', '@', '[', ']', '(', ')', '{', '}', '<', '>', '?',
//     '#', '$', '^', '&', '*', '"', '~', '|', '/', '\\', ':', ';', '!', ','
//   ];
//   let sanitized = str;
//   invalidChars.forEach((char) => {
//     sanitized = sanitized.replace(new RegExp(`\\${char}`, 'g'), '');
//   });
//   return sanitized;
// };

export function sanitizeForExcel(str) {
  if (typeof str !== "string") return str;
  
  const invalidChars = [
    "=", "'", "%", "`", "+", "-", "@", "[", "]", "(", ")", 
    "{", "}", "<", ">", "?", "#", "$", "^", "&", "*", 
    "\"", "~", "|", "/", "\\", ":", ";", "!", ","
  ];
  
  let sanitized = str;

  // 1) Replace each invalid character with a single space
  invalidChars.forEach((char) => {
    const re = new RegExp(`\\${char}`, "g");
    sanitized = sanitized.replace(re, " ");
  });

  // 2) Collapse multiple spaces into one
  sanitized = sanitized.replace(/\s+/g, " ");

  // 3) Trim leading/trailing spaces
  sanitized = sanitized.trim();

  // 4) If more than 31 chars, truncate
  if (sanitized.length > 31) {
    sanitized = sanitized.slice(0, 31).trim();
  }

  return sanitized;
}



/* 
  ================ 
  The main AddStaffComponent
  ================
*/
export default function AddStaffComponent({ staff, onSave, onClose }) {
  // ====== Hooks / Context ======
  const { getUID } = useAuth();
  const { settings, dispatch, reportCompilerDispatch } = useSettingsContext();
  const {
    editableStaff,
    updateEditableStaff,
    resetEditableStaff,
    staffContextDispatch,
    loading,
  } = useStaffContext();
  const showSnackBar = useSnackBar();
  const { currentlySelectedStudios } = useStudioScrapeStatus();

  // ====== Local State ======
  const fieldRefs = useRef({});
  const [errors, setErrors] = useState({});

  // EarningsDialog controls:
  const [earningsDialogOpen, setEarningsDialogOpen] = useState(false);
  const [tempEarnings, setTempEarnings] = useState([]);
  const [earningFieldKey, setEarningFieldKey] = useState(null);
  const [earningBucketName, setEarningBucketName] = useState("");
  const [earningsBucket, setEarningsBucket] = useState(null);

  // For "Alternate Names" init logic
  const [alternateNamesInitialized, setAlternateNamesInitialized] = useState(false);
  const [initialAlternateName, setInitialAlternateName] = useState("");

  // ====== Helpers ======
  // Open the EarningsDialog
  const openEarningsDialog = (bucketName, bucket, fieldKey, existingData) => {
    if (!bucket) {
      console.warn("No earnings bucket found for:", bucketName);
      return;
    }
    setEarningBucketName(bucketName);
    setEarningsBucket(bucket);
    setEarningFieldKey(fieldKey);
    setTempEarnings(existingData || []);
    setEarningsDialogOpen(true);
  };

  // Close the EarningsDialog
  const handleCloseEarningsDialog = (confirmed) => {
    setEarningsDialogOpen(false);
    if (confirmed && earningFieldKey) {
      updateEditableStaff({
        ...editableStaff,
        [earningFieldKey]: tempEarnings,
      });
    }
  };

  // Generic field change
  const handleChange = (path, value) => {
    if (path.includes(".")) {
      const keys = path.split(".");
      let updatedStaff = { ...editableStaff };
      let current = updatedStaff;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current[key] = current[key] || {};
          current = current[key];
        }
      });
      updateEditableStaff(updatedStaff);
    } else {
      updateEditableStaff({ ...editableStaff, [path]: value });
    }
  };

  // Save staff to server
  const handleSaveStaff = async (uid, staffObj) => {
    const newStaffObjects = { [staffObj.id]: staffObj };
    const updateSuccessful = await updateStaffMasterSettings(uid, newStaffObjects);
    if (updateSuccessful) {
      showSnackBar(`Successfully added ${staffObj.name}`, "success");
      reportCompilerDispatch({ type: "BUILD_REPORT_COMPILER_STATE_STAFF" });
    } else {
      showSnackBar(`Failed to add ${staffObj.name}`, "error");
    }
  };

  // Validation
  const validateRequiredFields = () => {
    const staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
    const newErrors = {};
    staffFields
      .filter((fld) => fld.order !== -1)
      .forEach((fld) => {
        if (fld.required) {
          const val = editableStaff[fld.field];
          const isEmptyValue =
            val === undefined ||
            val === null ||
            (typeof val === "string" && val.trim() === "") ||
            (Array.isArray(val) && val.length === 0);
          if (isEmptyValue) {
            newErrors[fld.field] = `${fld.name} is required`;
          }
        }
      });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // On Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateRequiredFields()) return;

      // 1) If the user typed something in `editableStaff.name`, do final sanitization
  if (editableStaff.name) {
    const original = editableStaff.name;

    // Ensure the original name is in alternateNames if not present
    const altNames = Array.isArray(editableStaff.alternateNames)
      ? [...editableStaff.alternateNames]
      : [];
    if (!altNames.includes(original)) {
      altNames.push(original);
    }

    // Run our updated sanitize function
    const sanitized = sanitizeForExcel(original);

    // Overwrite
    editableStaff.name = sanitized;
    editableStaff.alternateNames = altNames;
  }
  
    staffContextDispatch({ type: "SET_LOADING", payload: true });
    dispatch({
      type: "ADD_STAFF_SETTINGS_STAFF_MEMBER",
      newStaffId: editableStaff.id,
      newStaff: editableStaff,
    });
    await handleSaveStaff(getUID(), editableStaff);
    staffContextDispatch({ type: "ADD_STAFF", payload: editableStaff });
    resetEditableStaff();
    staffContextDispatch({ type: "SET_LOADING", payload: false });
    onSave(editableStaff);
  };

  // Scroll to first error if any
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
      const firstErrorField = staffFields.find(
        (fld) => fld.order !== -1 && errors[fld.field]
      );
      if (firstErrorField && fieldRefs.current[firstErrorField.field]) {
        fieldRefs.current[firstErrorField.field].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        fieldRefs.current[firstErrorField.field].focus();
      }
    }
  }, [errors, settings]);

  // Initialize alternateNames once
  useEffect(() => {
    if (staff && staff.name && editableStaff && !alternateNamesInitialized) {
      const sanitizedName = sanitizeForExcel(staff.name);
      const newAlternateNames = [...(editableStaff.alternateNames || []), staff.name];

      updateEditableStaff({
        ...editableStaff,
        name: sanitizedName,
        alternateNames: newAlternateNames,
      });

      setInitialAlternateName(staff.name);
      setAlternateNamesInitialized(true);
    }
  }, [staff, editableStaff, updateEditableStaff, alternateNamesInitialized]);

  // staffFields from settings
  const staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
  const sortedFields = staffFields
    .filter((fld) => fld.order !== -1)
    .sort((a, b) => a.order - b.order);

  // Option helper
  const getOptionsForField = (field) => {
    if (field.options && Array.isArray(field.options) && field.options.length > 0) {
      return field.options;
    }
    switch (field.field) {
      case "type":
        return (
          settings[getUrlVariableValue("settingsId")].generalSettings?.staffTypes?.map(
            (s) => s.name
          ) || []
        );
      case "locations":
      case "homeLocation":
        return currentlySelectedStudios || [];
      case "homeRegion":
        return settings[getUrlVariableValue("settingsId")].generalSettings?.regions || [];
      case "tier":
        return settings[getUrlVariableValue("settingsId")].generalSettings?.payTiers || [];
      default:
        return [];
    }
  };

  // ========== RENDER ==========
  return (
    <Stack spacing={3} sx={{ margin: "16px 0" }}>
      {/* 1) Staff Fields */}
      {sortedFields.map((field) => {
        const fieldValue = editableStaff[field.field];
        const isError = !!errors[field.field];
        const helperText = isError ? errors[field.field] : field.description;

        switch (field.type) {
          case "string":
          case "text":
            return (
              <TextField
                key={field.field}
                label={field.name}
                value={fieldValue || ""}
                onChange={(e) => handleChange(field.field, e.target.value)}
                fullWidth
                margin="dense"
                error={isError}
                helperText={helperText}
                inputRef={(el) => (fieldRefs.current[field.field] = el)}
                disabled={field.field === "name"}
              />
            );

          case "singleSelect":
            const selectOptions = getOptionsForField(field);
            return (
              <FormControl
                key={field.field}
                fullWidth
                error={isError}
                margin="dense"
              >
                <InputLabel>{field.name}</InputLabel>
                <Select
                  label={field.name}
                  value={fieldValue || ""}
                  onChange={(e) => handleChange(field.field, e.target.value)}
                  input={<OutlinedInput label={field.name} />}
                  inputRef={(el) => (fieldRefs.current[field.field] = el)}
                >
                  {selectOptions.length > 0 ? (
                    selectOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No options available</MenuItem>
                  )}
                </Select>
                {isError && (
                  <FormHelperText error>
                    {errors[field.field]}
                  </FormHelperText>
                )}
                {!isError && field.description && (
                  <FormHelperText>{field.description}</FormHelperText>
                )}
              </FormControl>
            );

          /* 
            Revert the original "list" (Autocomplete) logic: 
            - multiple
            - freeSolo
            - renderTags that doesn't allow deletion of the initial alternateName, etc. 
          */
          case "list":
            const autocompleteOptions = getOptionsForField(field);
            return (
              <Autocomplete
                key={field.field}
                multiple
                freeSolo
                options={autocompleteOptions}
                value={fieldValue || []}
                onChange={(event, newValue) => {
                  handleChange(field.field, newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const tagProps = getTagProps({ index });
                    // If this is the initial alternate name, remove the onDelete handler
                    if (option === initialAlternateName) {
                      delete tagProps.onDelete;
                    }
                    return <Chip {...tagProps} label={option} />;
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={field.name}
                    placeholder={field.description}
                    error={isError}
                    helperText={helperText}
                    inputRef={(el) => (fieldRefs.current[field.field] = el)}
                  />
                )}
              />
            );

          default:
            return (
              <TextField
                key={field.field}
                label={field.name}
                value={fieldValue || ""}
                onChange={(e) => handleChange(field.field, e.target.value)}
                fullWidth
                margin="dense"
                error={isError}
                helperText={helperText}
                inputRef={(el) => (fieldRefs.current[field.field] = el)}
              />
            );
        }
      })}

      {/* 2) Earning Buckets in a "Paper"/Box with new styling */}
      {Object.entries(
        settings[getUrlVariableValue("settingsId")]?.timeSettings?.timeBuckets || {}
      )
        .filter(([_, bucket]) => bucket.type === "Earning")
        .map(([bucketName, bucket]) => {
          const fieldKey = `${bucketName.toLowerCase()}Earning`;
          const currentValue = editableStaff[fieldKey] || [];

          return (
            <Box
              key={bucketName}
              sx={{
                marginTop: 3,
                padding: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.grey[50],
              }}
            >
              {currentValue.length > 0 ? (
                <Stack spacing={2}>
                  {/* Title + Edit button row */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      pb: 1,
                    }}
                  >
                    <Typography variant="h5">
                      {bucketName} Earnings
                    </Typography>
                    <Button
                      variant="outlined"
                      startIcon={<EditRounded />}
                      onClick={() =>
                        openEarningsDialog(
                          bucketName,
                          bucket,
                          fieldKey,
                          currentValue
                        )
                      }
                    >
                      Edit
                    </Button>
                  </Box>

                  {/* Summary Table */}
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentValue.map((item, idx) => {
                        // If user stored 'hours' & 'rate', fallback to hours*rate
                        const displayedValue =
                          item.amount != null
                            ? item.amount
                            : item.hours != null && item.rate != null
                            ? item.hours * item.rate
                            : 0;

                        return (
                          <TableRow key={idx}>
                            <TableCell>{item.location}</TableCell>
                            <TableCell align="right">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(displayedValue)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Stack>
              ) : (
                /* "Add" scenario */
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      pb: 1,
                    }}
                  >
                    <Typography variant="h5">{bucketName} Earnings</Typography>
                    <Button
                      variant="outlined"
                      startIcon={<AddRounded />}
                      onClick={() =>
                        openEarningsDialog(bucketName, bucket, fieldKey, [])
                      }
                    >
                      Add
                    </Button>
                  </Box>
                  <Typography sx={{ mt: 2 }}>
                    No {bucketName.toLowerCase()} earnings have been added yet.
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}

      {/* 3) Hourly Rates logic */}
      <Typography variant="h5" gutterBottom>
        Hourly Rates
      </Typography>
      {Object.entries(
        settings[getUrlVariableValue("settingsId")]?.timeSettings?.timeBuckets || {}
      )
        .filter(([, bucket]) => bucket.type === "Hourly")
        .map(([bucketName, bucket]) => {
          let fieldName = "hourly";
          if (bucketName !== "Regular") {
            fieldName = bucketName.toLowerCase() + bucket.type;
          }
          const labelText = `${bucketName} Hourly Rate`;
          return (
            <NumericTextField
              key={bucketName}
              label={labelText}
              value={editableStaff[fieldName] || ""}
              onChange={(e) => handleChange(fieldName, e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputMode: "decimal",
                pattern: "[0-9]*",
              }}
            />
          );
        })}

      {/* 4) DynamicRates (class rates) */}
      <DynamicRates
        settings={settings}
        editableStaff={editableStaff}
        handleChange={handleChange}
      />

      {/* 5) Commission Rates */}
      <Typography variant="h5" gutterBottom>
        Commission Rates (Override)
      </Typography>
      <TextField
        label="Retail Commission Rate"
        value={editableStaff.commissionRate?.retail || ""}
        onChange={(e) => handleChange("commissionRate.retail", e.target.value)}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Agreement Commission Rate"
        value={editableStaff.commissionRate?.agreement || ""}
        onChange={(e) => handleChange("commissionRate.agreement", e.target.value)}
        fullWidth
        margin="dense"
      />

      {/* 6) The EarningsDialog usage */}
      <EarningsDialog
        isOpen={earningsDialogOpen}
        onClose={handleCloseEarningsDialog}
        earnings={tempEarnings}
        setEarnings={setTempEarnings}
        earningBucketName={earningBucketName}
        earningsBucket={earningsBucket}
        locations={currentlySelectedStudios}
      />

      {/* 7) Confirm / Cancel */}
      <DialogActions className="add-staff-component__actions">
        <Button onClick={() => onClose(staff)}>Cancel</Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
}
