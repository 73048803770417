import React from 'react';
import TextField from '@mui/material/TextField';

const NumericTextField = React.memo(({ label, value, onChange, InputProps, ...props }) => {
  const handleInputChange = (e) => {
    const { value: newValue } = e.target;
    // Allow numbers and decimal points, but only update if there's a value
    if (newValue === '' || /^\d*\.?\d*$/.test(newValue)) {
      onChange(e);
    }
  };

  return (
    <TextField
      label={label}
      type="text"
      fullWidth
      margin="normal"
      // value={value || ""}
      value={value === 0 ? "0" : value || ""}
      onChange={handleInputChange}
      inputProps={{ 
        inputMode: 'decimal',
        ...props.inputProps 
      }}
      InputProps={{
        ...InputProps,
        sx: {
          // Remove spinner arrows
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
          },
          "& input[type=number]": {
            "-moz-appearance": "textfield"
          },
          ...InputProps?.sx
        }
      }}
      {...props}
    />
  );
});

export default NumericTextField;
