import React, { } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, IconButton, Divider } from '@mui/material';
import Box from "@mui/material/Box";
import VideocamIcon from '@mui/icons-material/Videocam';
import { useSettingsContext } from "../../contexts/SettingsContext";
import { useAuth } from "../../contexts/AuthContext";
import "./staffSettings.scss";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";
import { Skeleton, Link } from "@mui/material";
import AddStaffDialogSettings from "./dialogs/AddStaffDialogSettings.js";
import EarningsDialog from "./dialogs/EarningsDialog.js";
import AddPayTierDialog from "./dialogs/AddPayTierDialog.js";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import { tabProps } from "./components/TabPanel.js"
import { StripedDataGrid, StyledBox } from "./components/StyledComponents.js";
import { EditToolbar, EditPayTiersToolbar } from "./components/toolbars";
import { useStaffSettings } from "./hooks/useStaffSettings.js";
export default function StaffSettings() {
  const { dispatch, changes, setChanges } = useSettingsContext();

  const {
    isLoading,
    //new
    settings,

    // tab logic
    tableTabsValue,

    // columns
    ratesColumns,


    // row data
    memoizedRatesRows,
    memoizedPayTiersRows,

    // row update methods
    processRowUpdate,
    processRowUpdateTier,


    // modals
    addStaffModal,
    setAddStaffModal,
    addPayTierModal,
    setAddPayTierModal,
    ratesApiRef,
    rowModesModel,
    handleCellEditStart,
    handleCellEditStop,
    editingRowId,
    tiersApiRef,
    setEditingRowId,
    setEditingCellField,
    handlePayTiersClick,
    handleAddStaffDialogSave,
    handleAddPayTierSave,
    handleAddStaffDialogClose,
    handleAddPayTierDialogClose,
    isEarningsDialogOpen,
    currentEarnings,
    setCurrentEarnings,
    earningBucketName,
    currentEarningBucket,
    tiersAnchor,
    handleTableTabsChange,
    handlePayTiersClose,
    handleCloseCustomDialog
  } = useStaffSettings();

  const { getUID } = useAuth();


  if (isLoading || !settings.hasOwnProperty("1") && !settings.hasOwnProperty("staff")) {
    return (
      <Box className="settings-page">
        <Skeleton>
          <Typography variant="h2" className="settings-page__title">
            Studios
          </Typography>
        </Skeleton>
        <Divider className="settings-page__divider" />
        <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
      </Box>
    );
  }

  return (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        Staff
      </Typography>
      <Typography variant="p" className="settings-page__title-description">
        <Link href="/resources/Adding-and-Updating-Staff"><VideocamIcon />Tutorial</Link>
        {` - Add new staff, remove exisitng staff, and updates their information, such as email, ID's, hourly rates, session rates and much more`}
      </Typography>

      <Box className="settings-page__tab-content">
        <Box className="table">
          <Tabs
            className="table__tabs"
            value={tableTabsValue}
            onChange={(event, newValue) => handleTableTabsChange(event, newValue, false)}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              key={"Info-0"}
              onClick={(event) => handleTableTabsChange(event, 0, false)}
              className={`settings-page__tab settings-page__tab${tableTabsValue == 0 ? "--active" : ""
                }`}
              label="Info"
              {...tabProps(0)}
            />

            <Tab
              key={"Hourly-1"}
              onClick={(event) => handleTableTabsChange(event, 1, false)}
              className={`settings-page__tab settings-page__tab${tableTabsValue == 1 ? "--active" : ""
                }`}
              label="Hourly"
              {...tabProps(1)}
            />

            <Tab
              key={"Earnings-2"}
              onClick={(event) => handleTableTabsChange(event, 2, false)}
              className={`settings-page__tab settings-page__tab${tableTabsValue == 2 ? "--active" : ""
                }`}
              label="Earnings"
              {...tabProps(2)}
            />

            {Object.entries(
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets
            )
              .sort()
              .map((tab, index) => (
                <Tab
                  key={`${tab[0]}-${index + 3}`}
                  onClick={(event) => handleTableTabsChange(event, index + 3, false)}
                  className={`settings-page__tab settings-page__tab${tableTabsValue === index + 3 ? "--active" : ""
                    }`}
                  label={tab[0].replace(/(?<!^)([A-Z])/g, "-$1")}
                  {...tabProps(index + 3)}
                />
              ))}

            <Tab
              key={`Sales-Commission-${Object.keys(
                settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets
              ).length + 3}`}
              onClick={() =>
                handleTableTabsChange(
                  event,
                  Object.keys(
                    settings[getUrlVariableValue("settingsId")].classSettings
                      .classBuckets
                  ).length + 3,
                  false
                )
              }
              className={`settings-page__tab settings-page__tab${tableTabsValue ==
                Object.keys(
                  settings[getUrlVariableValue("settingsId")].classSettings
                    .classBuckets
                ).length + 3
                ? "--active"
                : ""
                }`}
              label="Sales-Commission"
              {...tabProps(
                Object.keys(
                  settings[getUrlVariableValue("settingsId")].classSettings
                    .classBuckets
                ).length + 3
              )}
            />
          </Tabs>

          <Box
            className="table__data-grid-container table__data-grid-container--staff-rates"
            sx={{
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
            }}
          >
            <StyledBox>
              <StripedDataGrid
                className="table__data-grid-pro table__data-grid-pro--staff-rates"
                rows={memoizedRatesRows}
                columns={ratesColumns}
                apiRef={ratesApiRef}
                rowModesModel={rowModesModel}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(err) => console.error(err)}
                onCellClick={handleCellEditStart}
                onCellEditStop={handleCellEditStop}
                getRowClassName={(params) =>
                  params.row.status
                    ? `MuiDataGrid-row--${params.row.status}`
                    : ""
                }
                getCellClassName={(params) => {
                  if (
                    params.row["statusField"] &&
                    params.row["statusField"] == params.field
                  ) {
                    return `MuiDataGrid-cell--${params.row.status}`;
                  }
                }}
                components={{
                  Toolbar: EditToolbar,
                }}
                componentsProps={{
                  toolbar: {
                    editingRowId,
                    setEditingRowId,
                    setEditingCellField,
                    rowModesModel,
                    apiRef: ratesApiRef,
                    tableTabsValue,
                    setAddStaffModal,
                    settings,
                    changes,
                    setChanges,
                    dispatch,
                    getUID,
                    ratesApiRef,
                    handlePayTiersClick
                  },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                initialState={{
                  pinnedColumns: { right: ["actions"], left: ["name"] },
                }}
                sortModel={[
                  {
                    field: 'name',
                    sort: 'asc',  // Sort ascending
                  },
                ]}
                hideFooter
                sx={{ border: 1 }}
              />
            </StyledBox>
          </Box>
        </Box>
      </Box>

      {addStaffModal && (
        <AddStaffDialogSettings
          open={addStaffModal}
          onClose={handleAddStaffDialogClose}
          onSave={handleAddStaffDialogSave}
        />
      )}
      {addPayTierModal && (
        <AddPayTierDialog
          open={addPayTierModal}
          onClose={handleAddPayTierDialogClose}
          onSave={handleAddPayTierSave}
          tiers={['Default', ...Object.values(settings[getUrlVariableValue("settingsId")].generalSettings.staffTiers || {}).map(tier => tier.name)]}
        />
      )}
      {isEarningsDialogOpen && (
        <EarningsDialog
          isOpen={isEarningsDialogOpen}
          editingRowId={editingRowId}
          earningBucketName={earningBucketName}
          earningsBucket={currentEarningBucket}
          onClose={handleCloseCustomDialog}

          locations={settings[getUrlVariableValue("settingsId")].generalSettings.studios}
          earnings={currentEarnings}
          setEarnings={setCurrentEarnings}
        />
      )}
      {tiersAnchor && (
        <Dialog
          open={tiersAnchor}
          anchorEl={tiersAnchor}
          onClose={handlePayTiersClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          maxWidth={false} // Remove the default maxWidth
          fullWidth={true} // Optional: Make the dialog take full width based on PaperProps
          PaperProps={{
            sx: { width: '90%', maxHeight: '90vh', p: 2 }
          }}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}>
            <Typography variant="h4">Staff Templates</Typography>
            <IconButton onClick={handlePayTiersClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="table">
            <Tabs
              className="table__tabs"
              value={tableTabsValue}
              onChange={(event, newValue) => handleTableTabsChange(event, newValue, true)}
              aria-label="basic tabs example"
              //centered
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                key={"Info-0-tier"}
                onClick={(event) => handleTableTabsChange(event, 0, true)}
                className={`settings-page__tab settings-page__tab${tableTabsValue == 0 ? "--active" : ""
                  }`}
                label="Info"
                {...tabProps(0)}
              />

              <Tab
                key={"Hourly-1-tier"}
                onClick={(event) => handleTableTabsChange(event, 1, true)}
                className={`settings-page__tab settings-page__tab${tableTabsValue == 1 ? "--active" : ""
                  }`}
                label="Hourly"
                {...tabProps(1)}
              />

              <Tab
                key={"Earnings-2-tier"}
                onClick={(event) => handleTableTabsChange(event, 2, true)}
                className={`settings-page__tab settings-page__tab${tableTabsValue == 2 ? "--active" : ""
                  }`}
                label="Earnings"
                {...tabProps(2)}
              />

              {Object.entries(
                settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets
              )
                .sort()
                .map((tab, index) => (
                  <Tab
                    key={`${tab[0]}-${index + 3}-tier`}
                    onClick={(event) => handleTableTabsChange(event, index + 3, true)}
                    className={`settings-page__tab settings-page__tab${tableTabsValue === index + 3 ? "--active" : ""
                      }`}
                    label={tab[0].replace(/(?<!^)([A-Z])/g, "-$1")}
                    {...tabProps(index + 3)}
                  />
                ))}

              <Tab
                key={`${Object.keys(
                  settings[getUrlVariableValue("settingsId")].classSettings
                    .classBuckets
                ).length + 3}-tier`}
                onClick={(event) =>
                  handleTableTabsChange(
                    event,
                    Object.keys(
                      settings[getUrlVariableValue("settingsId")].classSettings
                        .classBuckets
                    ).length + 3,
                    true
                  )
                }
                className={`settings-page__tab settings-page__tab${tableTabsValue ==
                  Object.keys(
                    settings[getUrlVariableValue("settingsId")].classSettings
                      .classBuckets
                  ).length + 3
                  ? "--active"
                  : ""
                  }`}
                // className="table__tab"
                label="Sales-Commission"
                {...tabProps(
                  Object.keys(
                    settings[getUrlVariableValue("settingsId")].classSettings
                      .classBuckets
                  ).length + 3
                )}
              />
            </Tabs>

            <Box
              className="table__data-grid-container table__data-grid-container--staff-rates"
              sx={{
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <StyledBox>
                <StripedDataGrid
                  className="table__data-grid-pro table__data-grid-pro--staff-rates"
                  rows={memoizedPayTiersRows}
                  columns={ratesColumns}
                  apiRef={tiersApiRef}
                  rowModesModel={rowModesModel}
                  processRowUpdate={processRowUpdateTier}
                  onProcessRowUpdateError={(err) => console.error(err)}
                  onCellClick={handleCellEditStart}
                  onCellEditStop={handleCellEditStop}
                  getRowClassName={(params) =>
                    params.row.status
                      ? `MuiDataGrid-row--${params.row.status}`
                      : ""
                  }
                  getCellClassName={(params) => {
                    if (
                      params.row["statusField"] &&
                      params.row["statusField"] == params.field
                    ) {
                      return `MuiDataGrid-cell--${params.row.status}`;
                    }
                  }}
                  components={{
                    Toolbar: EditPayTiersToolbar,
                  }}
                  componentsProps={{
                    toolbar: {
                      editingRowId,
                      setEditingRowId,
                      setEditingCellField,

                      apiRef: tiersApiRef,

                      tableTabsValue,
                      setAddPayTierModal,
                      tiersApiRef,
                    },
                  }}
                  experimentalFeatures={{ newEditingApi: true }}
                  initialState={{
                    pinnedColumns: { right: ["actions"], left: ["name"] },
                  }}
                  sortModel={[
                    {
                      field: 'name',
                      sort: 'asc',
                    },
                  ]}
                  hideFooter
                  sx={{ border: 1 }}
                />
              </StyledBox>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  )
}
