import React, { useState } from "react";
import { Typography, Stack, Box, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Accordion, AccordionSummary, AccordionDetails, Autocomplete, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { Skeleton } from "@mui/material";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import PayPeriodsConfig from "./PayPeriodsConfig.js";
import SettingsFormContainer from "../../../components/SettingsForm/SettingsFormContainer";
import SettingsFormInput from "../../../components/SettingsForm/SettingsFormInput";
import useAutoScrollToHash from "../useAutoScrollToHash";
import { settingIds, settingTooltips } from "../CommonVariables";

export default function AdditionalSettings() {
  const { getUID, currentUser } = useAuth();
  console.log(currentUser);
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const [open, setOpen] = useState(false);
  const [payPeriodExpanded, setPayPeriodExpanded] = useState(false);
  const [commissionExpanded, setCommissionExpanded] = useState(false);
  const [emailExpanded, setEmailExpanded] = useState(false);

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  useAutoScrollToHash((tabId) => {
    if (tabId === 'payPeriodSettings') {
      handleDialogOpen();
      setPayPeriodExpanded(true);
    } else if (tabId === 'commissionSettings') {
      setCommissionExpanded(true);
    } else if (tabId === 'emailSettings') {
      setEmailExpanded(true);
    } else {
      console.warn(`Invalid tab id "${tabId}"`);
    }
  });

    function updateSettingDispatch(value, id) {
      // settings[getUrlVariableValue("settingsId")].generalSettings[id] = value;
      let newRev = new RevertList(changes.getValues());
      newRev.addElement(
        id,
        settings[getUrlVariableValue("settingsId")].generalSettings[id],
        value
      );
      setChanges(newRev);

      dispatch({
        type: "UPDATE_SETTINGS_PROPERTY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        path: `${getUrlVariableValue("settingsId")}.generalSettings.${id}`,
        value: value,
      });
    }

    function handleChange(value, id) {
      if(id.includes("-")){
        let [newId, index] = id.split("-");
        let arrayValue = settings[getUrlVariableValue("settingsId")].generalSettings[newId];
        arrayValue[index] = value;
        let newRev = new RevertList(changes.getValues());
        newRev.addElement(
          newId,
          settings[getUrlVariableValue("settingsId")].generalSettings[newId],
          arrayValue
        );
        setChanges(newRev);
  
        dispatch({
          type: "UPDATE_SETTINGS_PROPERTY",
          settingsId: getUrlVariableValue("settingsId"),
          uid: getUID(),
          path: `${getUrlVariableValue("settingsId")}.generalSettings.${newId}`,
          value: arrayValue,
        });
        return;
      }
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].generalSettings[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SETTINGS_PROPERTY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      path: `${getUrlVariableValue("settingsId")}.generalSettings.${id}`,
      value: value,
    });
  }

  if (!settings.hasOwnProperty("1") || !settings.hasOwnProperty("staff")) {
    return (
      <Box className="settings-page">
        <Skeleton animation="wave">
          <Typography variant="h2" className="settings-page__title">Loading Settings...</Typography>
        </Skeleton>
      </Box>
    );
  }

  const payFrequency = settings[getUrlVariableValue("settingsId")].generalSettings.payFrequency;
  
  return (
    <Box className="settings-page">
      <Typography variant="h2" gutterBottom>Admin Settings</Typography>
      <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`Configure general PayWell settings, such as your pay period, ${
          settings[getUrlVariableValue("settingsId")]?.generalSettings?.commissionName?.toLowerCase() || 'commission'
        } pay sequences, and email pay stub preferences.`}
      </Typography>
      <Divider className="settings-page__divider"/>
      <Stack spacing={2}>
        <Accordion expanded={payPeriodExpanded} onChange={() => {setPayPeriodExpanded(curr => !curr)}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Pay Period Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Button variant="outlined" onClick={handleDialogOpen}>Edit Pay Period Settings</Button>
            <Dialog open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-title" maxWidth="md">
      <DialogTitle id="form-dialog-title">Pay Period Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 2 }}>
          <SettingsFormContainer>
            <SettingsFormInput 
              key="processor"
              label="Processor:"
              inputElement={
                <SelectorBox
                  id="processor"
                  onChange={handleChange}
                  default={settings[getUrlVariableValue("settingsId")].generalSettings.processor}
                  size="auto"
                  fullWidth
                  options={["Gusto", "Paychex", "ADP"]}
                  label="Processor"
                />
              }
            />

            <SettingsFormInput
              key="fiscalWorkweekStartDay"
              label="Fiscal Week Start Day:"
              inputElement={
                <SelectorBox
                  id="fiscalWorkweekStartDay"
                  onChange={handleChange}
                  default={settings[getUrlVariableValue("settingsId")].generalSettings.fiscalWorkweekStartDay}
                  size="auto"
                  fullWidth
                  options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                  label="Fiscal Week Start Day"
                />
              }
            />

            <SettingsFormInput
              key="payFrequency"
              label="Frequency:"
              inputElement={
                <SelectorBox
                  id="payFrequency"
                  onChange={handleChange}
                  default={payFrequency}
                  size="auto"
                  fullWidth
                  options={["Weekly", "Bi-Weekly", "Semi-Monthly", "Monthly"]}
                  label="Pay Frequency"
                />
              }
            />

            {payFrequency === "Weekly" && (
              <SettingsFormInput
                key="weeklyPayDay"
                label="Pay Day:"
                inputElement={
                  <SelectorBox
                    id="weeklyPayDay"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.weeklyPayDay}
                    size="auto"
                    fullWidth
                    options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                    label="Weekly Payday"
                  />
                }
              />
            )}

            {payFrequency === "Bi-Weekly" && (
              <SettingsFormInput
                key="biWeeklyPayDay"
                label="Pay Day:"
                inputElement={
                  <SelectorBox
                    id="biWeeklyPayDay"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings.biWeeklyPayDay}
                    size="auto"
                    fullWidth
                    options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                    label="Bi-Weekly Payday"
                  />
                }
              />
            )}

            {payFrequency === "Monthly" && (
              <>
                <SettingsFormInput
                  key="monthlyPayDays-0"
                  label="Pay Date:"
                  inputElement={
                    <SelectorBox
                      id="monthlyPayDays-0"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.monthlyPayDays[0]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="Monthly Payday"
                    />
                  }
                />

                <SettingsFormInput
                  key="payPeriodDays1-0"
                  label="Pay Period Start Day:"
                  inputElement={
                    <SelectorBox
                      id="payPeriodDays1-0"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[0]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="Start of Pay Period 1"
                    />
                  }
                />

                <SettingsFormInput
                  key="payPeriodDays1-1"
                  label="Pay Period End Day:"
                  inputElement={
                    <SelectorBox
                      id="payPeriodDays1-1"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[1]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="End of Pay Period 1"
                    />
                  }
                />
              </>
            )}

            {payFrequency === "Semi-Monthly" && (
              <>
                <SettingsFormInput
                  key="monthlyPayDays-0"
                  label="First Semi-Monthly Payday:"
                  inputElement={
                    <SelectorBox
                      id="monthlyPayDays-0"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.monthlyPayDays[0]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="First Semi-Monthly Payday"
                    />
                  }
                />

                <SettingsFormInput
                  key="monthlyPayDays-1"
                  label="Second Semi-Monthly Payday:"
                  inputElement={
                    <SelectorBox
                      id="monthlyPayDays-1"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.monthlyPayDays[1]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="Second Semi-Monthly Payday"
                    />
                  }
                />

                <SettingsFormInput
                  key="payPeriodDays1-0"
                  label="First Pay Period Start Day:"
                  inputElement={
                    <SelectorBox
                      id="payPeriodDays1-0"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[0]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="Start of Pay Period 1"
                    />
                  }
                />

                <SettingsFormInput
                  key="payPeriodDays1-1"
                  label="First Pay Period End Day:"
                  inputElement={
                    <SelectorBox
                      id="payPeriodDays1-1"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays1[1]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="End of Pay Period 1"
                    />
                  }
                />

                <SettingsFormInput
                  key="payPeriodDays2-0"
                  label="Second Pay Period Start Day:"
                  inputElement={
                    <SelectorBox
                      id="payPeriodDays2-0"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays2[0]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="Start of Pay Period 2"
                    />
                  }
                />

                <SettingsFormInput
                  key="payPeriodDays2-1"
                  label="Second Pay Period End Day:"
                  inputElement={
                    <SelectorBox
                      id="payPeriodDays2-1"
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings.payPeriodDays2[1]}
                      size="auto"
                      fullWidth
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "Last Day of the Month"]}
                      label="End of Pay Period 2"
                    />
                  }
                />
              </>
            )}

            <SettingsFormInput
              key="payPeriodExamples"
              label="Pay Period Examples"
              inputElement={
                <PayPeriodsConfig 
                  id="payPeriodExamples"
                  payPeriods={settings[getUrlVariableValue("settingsId")].generalSettings?.payPeriodExamples ?? []}
                  onChange={handleChange}
                />
              }
            />

          </SettingsFormContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">Cancel</Button>
        <Button onClick={handleDialogClose} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={commissionExpanded} onChange={() => {setCommissionExpanded(curr => !curr)}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Commission Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SettingsFormContainer>

              <SettingsFormInput 
                key="agreementCommissionPaySequence"
                label="Agreement Pay Sequence:"
                inputElement={
                  <SelectorBox
                    id="agreementCommissionPaySequence"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings?.agreementCommissionPaySequence}
                    options={["Standard", "Advanced", "Custom"]}
                    label="Agreement Pay Sequence"
                  />
                }
                id={settingIds.generalSettings.agreementCommissionPaySequence}
                tooltip={settingTooltips.generalSettings.agreementCommissionPaySequence}
              />

              <SettingsFormInput 
                key="retailCommissionPaySequenceType"
                label="Retail Pay Sequence:"
                inputElement={
                  <SelectorBox
                    id="retailCommissionPaySequenceType"
                    onChange={handleChange}
                    default={settings[getUrlVariableValue("settingsId")].generalSettings?.retailCommissionPaySequenceType}
                    options={["Standard", "Advanced", "Custom"]}
                    label="Retail Pay Sequence"
                  />
                }
                id={settingIds.generalSettings.retailCommissionPaySequenceType}
                tooltip={settingTooltips.generalSettings.retailCommissionPaySequenceType}
              />

            </SettingsFormContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={emailExpanded} onChange={() => {setEmailExpanded(curr => !curr)}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Email Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SettingsFormContainer>

              <SettingsFormInput 
                key="useEmailer"
                label="Emailer Enabled: "
                inputElement={
                  <BooleanToggleElement
                    onChange={handleChange}
                    id="useEmailer"
                    default={settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer}
                  />
                }
                id={settingIds.generalSettings.useEmailer}
                tooltip={settingTooltips.generalSettings.useEmailer}
              />

              {settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer && (
                <SettingsFormInput 
                  key="customEmailSubject"
                  label="Subject Line of Email: "
                  inputElement={
                    <InputBoxElement
                      id="customEmailSubject"
                      types={["text"]}
                      onChange={handleChange}
                      default={settings[getUrlVariableValue("settingsId")].generalSettings?.customEmailSubject}
                      label="Custom Email Subject"
                      inputProps={{
                        maxLength: 250,  // Limit to 50 characters
                      }}
                    />
                  }
                  id={settingIds.generalSettings.customEmailSubject}
                  tooltip={settingTooltips.generalSettings.customEmailSubject}
                />
              )}

              {settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer && (

                <SettingsFormInput 
                  key="customEmailTextContent"
                  label="Custom Email Text Content: "
                  inputElement={
                    <TextField
                      id="customEmailTextContent"
                      label="Custom Email Text Content"
                      variant="outlined"
                      type="text"
                      onInput={(event) => handleChange(event?.target?.value, "customEmailTextContent")}
                      defaultValue={
                        settings[getUrlVariableValue("settingsId")].generalSettings?.customEmailTextContent || ""
                      }
                      fullWidth
                    />
                  }
                  id={settingIds.generalSettings.customEmailTextContent}
                  tooltip={settingTooltips.generalSettings.customEmailTextContent}
                />
              )}

              {settings[getUrlVariableValue("settingsId")].generalSettings?.useEmailer && (
                <SettingsFormInput 
                  key="emailCcRecipients"
                  label="CC'ed Email Recipients"
                  inputElement={
                    <Autocomplete
                      multiple
                      freeSolo
                      limitTags={5}
                      id="emailCcRecipients"
                      options={[]}
                      defaultValue={settings[getUrlVariableValue("settingsId")].generalSettings.emailCcRecipients === undefined || settings[getUrlVariableValue("settingsId")].generalSettings.emailCcRecipients === null ? [currentUser.email] : settings[getUrlVariableValue("settingsId")].generalSettings.emailCcRecipients}
                      onChange={(_, value) => {
                        handleChange(value, "emailCcRecipients")
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="CC'ed Email Recipients" size="small" />
                      )}
                      sx={{ width: 400 }}
                    />
                  }
                  id={settingIds.generalSettings.emailCcRecipients}
                  tooltip={settingTooltips.generalSettings.emailCcRecipients}
                />
              )}
            </SettingsFormContainer>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Box>
  );
}

