/**
 * Checks if the staff name is valid.
 * @param {string} name
 * @returns {string|null} An error message if invalid, otherwise null.
 */
export function validateName(name) {
    if (!name || name.trim() === "") {
      return "Staff name cannot be empty";
    }
    // If you later want to re-enable the length or invalid-char checks, you can do so here.
    // e.g. check length
    // if (name.length > 31) return "Name cannot be longer than 31 characters";
  
    return null;
  }
  
  /**
   * Checks if a string is a valid numeric input (including negative or decimal).
   * @param {string|number} value 
   * @returns {boolean} true if invalid, false if valid
   */
  export function numberCheck(value) {
    const regExp = /^-?[0-9]*\.?[0-9]+$/;
    return !regExp.test(value);  // If it does NOT pass the regex, it's invalid
  }


  // staffValidators.js
export function isValidNumericInput(value) {
  // Allows numbers, negative sign (only at start), and single decimal point
  return /^-?\d*\.?\d*$/.test(value);
}

export function processNumericInput(value) {
  // Remove any invalid characters and ensure proper number format
  let cleaned = value
    .replace(/[^0-9.-]/g, '') // Remove non-numeric characters
    .replace(/(\..*)\./g, '$1') // Allow only one decimal point
    .replace(/(?!^)-/g, ''); // Allow negative only at start

  // Handle empty value case
  if (cleaned === '-' || cleaned === '.') return cleaned;
  if (cleaned === '') return '';
  
  return cleaned;
}
  
  
  /**
   * Checks for warnings with staff data (e.g., missing name, duplicate name).
   * @param {Object} staffRow - The row object for a staff member.
   * @param {Array} allStaff - Array of all staff objects to check duplicates, etc.
   * @returns {string|null} Returns a warning string if there's an issue, otherwise null.
   */
  export function isWarning(staffRow, allStaff = []) {
    // Example: name is empty (for newly created row)
    if (staffRow.name.length === 0 && staffRow.isNew) {
      return "Staff must have name";
    } 
    // Example: name doesn't have a space
    else if (!staffRow.name.includes(" ") && staffRow.isNew) {
      return "Staff name is usually in the format 'First Last'";
    }
  
    // Example: check duplicate names in allStaff
    for (let i = 0; i < allStaff.length; i++) {
      if (staffRow.name === allStaff[i].name && staffRow.id !== allStaff[i].id) {
        return `Already have staff with name: ${staffRow.name}`;
      }
    }
  
    return null;
  }
  


/**
  const isWarning = (staffRow) => {
    if (staffRow.name.length === 0 && staffRow.isNew) {
      return "Staff must have name";
    } else if (!staffRow.name.includes(" ") && staffRow.isNew) {
      return "Staff name is usually be in format 'First' 'Last'";
    }

    let staffArray = Object.values(settings.staff.staff);
    for (let i = 0; i < staffArray.length; i++) {
      if (
        staffRow.name === staffArray[i].name &&
        staffRow.id !== staffArray[i].id
      ) {
        return "Already have staff with name: " + staffRow.name;
      }
    }
    return null;
  };



    const validateName = (name) => {
    // Check if the name is empty or only contains white spaces
    if (!name || name.trim() === "") {
      return "Staff name cannot be empty";
    }
  
    // Check if the name exceeds 31 characters
    // if (name.length > 31) {
    //   return "Name cannot be longer than 31 characters";
    // }
  
    // Define a regular expression for invalid characters.
    // This set includes various characters that can cause issues in Excel:
    // = ' % ` + - @ [ ] ( ) { } < > ? # $ ^ & * " ~ | / \ : ; ! ,
    const invalidChars = /[='%`+\-@\[\]\(\){}<>\?#\$^&\*"~|\/\\:;!,]/;
  // removing for now
    // if (invalidChars.test(name)) {
    //   // If any invalid character is found, return an error message
    //   return "Name contains invalid characters. Please remove or add name to alternate names.";
    // }
  
    // Return null if no errors are found
    return null;
  };


  
 */

