import { PayWellStaff } from "../ReportCompiler/classes/PayWellStaff.js";

import { ClassBucket } from "../QuestionObjects/ClassBuckets.js";
import { BooleanToggle } from "../QuestionObjects/BooleanToggle.js";
import { CatchAllClassLogic } from "../QuestionObjects/CatchAllClassLogic.js";
import {
  ProcessorConfig,
  Column,
  StaffAttribute,
  ExcelAttribute,
  RowAttribute,
} from "../QuestionObjects/ProcessorConfig.js";
import { CatchAllCommissionLogic } from "../QuestionObjects/CatchAllCommissionLogic.js";
import { InputBox } from "../QuestionObjects/InputBox.js";
import { HoursVsClassFlag } from "../QuestionObjects/HourVsClassFlag.js";
import { CommissionBucket } from "../QuestionObjects/CommissionBuckets.js";
import { ClassAttributeList } from "../QuestionObjects/ClassAttributeList.js";
import { CatchAllTimeLogic } from "../QuestionObjects/CatchAllTimeLogic.js";
import { AgreementPriceSettings } from "../QuestionObjects/AgreementPriceSettings.js";
import { SalesBucket } from "../QuestionObjects/SalesBucket.js";
import { TimeBucket } from "../QuestionObjects/TimeBucket.js";

export function buildStaff(staffSettings, studioSettings) {
  let staffArray = [];

  Object.entries(staffSettings.staff).map(([staffId, staff]) => {
    let studios = staff.locations;
    if (studios.length === 0 || studios.includes("All Studios")) {
      studios = studioSettings.generalSettings.studios;
      //studios = ["ALL"];
    }

    let staffObj = null;
    let newStaff = false;
    //find if staff is already created
    for (let j = 0; j < staffArray.length; j++) {
      if (staffArray[j].primaryName === staff.name) {
        staffObj = staffArray[j];
      }
    }

    if (staffObj === null) {
      staffObj = new PayWellStaff(staff.name);
      newStaff = true;
    }

    let buckets = Object.keys(studioSettings.classSettings.classBuckets);

    //buckets[i].charAt(0).toLowerCase() + buckets[i].slice(1) + "Rates";

    //let names = getAllStaffNames(staff);
    for (let i = 0; i < studios.length; i++) {
      // if(studios[i] === "ALL"){
      //   staffObj.location = studioSettings.generalSettings.studios;
      //   staffObj.infoObject = {};
      // }
      staffObj.addInfo(studios[i], staff.type, staff.alternateNames, buckets);
      staffObj.addProperty(studios[i], "id", staff.idString);
      staffObj.addProperty(studios[i], "secondaryId", staff.secondIdString);
      staffObj.addProperty(studios[i], "titles", staff.titles);
      staffObj.addProperty(studios[i], "email", staff.email);
      staffObj.addProperty(studios[i], "homeLocation", staff.homeLocation);
      staffObj.addProperty(
        studios[i],
        "agreementCommissionPercentage",
        staff.commissionRate.agreement === ""
          ? null
          : parseFloat(staff.commissionRate.agreement)
      );
      staffObj.addProperty(
        studios[i],
        "retailCommissionPercentage",
        staff.commissionRate.retail === ""
          ? null
          : parseFloat(staff.commissionRate.retail)
      );

      if(studioSettings.salesSettings.on){
        let buckets = Object.entries(studioSettings.salesSettings.salesBuckets);
        for(let b = 0; b < buckets.length; b++){
          let name = buckets[b][0];
          let bucket = buckets[b][1];
          let rates = bucket.distinctRates;
          for(let r = 0; r < rates.length; r++){
            let rate = name + "-" + rates[r];
            staffObj.addProperty(studios[i], rate, staff.commissionRate[rate] ? staff.commissionRate[rate] : null);
          }
        }
      }

      let staffFields = studioSettings.generalSettings.staffFields;
      for(let f = 0; f < staffFields.length; f++){
        let alreadyAddedFields = ["name", "type", "alternateNames", "locations", "titles", "email", "homeLocation", "commissionRate", "hourly", "secondaryHourly", "agreementCommissionPercentage", "retailCommissionPercentage"];
        if(alreadyAddedFields.includes(staffFields[f].field)){
          continue;
        }
        staffObj.addProperty(studios[i], staffFields[f].field, staff[staffFields[f].field] ? staff[staffFields[f].field] : staffFields[f].default);
      }
      staffObj.addHourlyRate(studios[i], parseFloat(staff.hourly));
      staffObj.addSecondHourlyRate(
        studios[i],
        parseFloat(staff.secondaryHourly)
      );
      staffObj.addProperty(studios[i], 'overtime', parseFloat(staff.overtime || 0));
      staffObj.addProperty(studios[i], 'doubleOvertime', parseFloat(staff.doubleOvertime || 0));
      staffObj.addProperty(studios[i], 'holiday', parseFloat(staff.holiday || 0));
      Object.entries(studioSettings.timeSettings.timeBuckets).map(([key, value]) => {
        let id = key.charAt(0).toLowerCase() + key.slice(1);
        if(value.type === "Hourly"){
          if(id === 'regular'){
            staffObj.addProperty(studios[i], id + "Hourly", staff.hourly ? staff.hourly : 0);
          } else {
            staffObj.addProperty(studios[i], id + "Hourly", staff[id + "Hourly"] ? staff[id + "Hourly"] : 0);

          }
        }
      });

      for (let j = 0; j < buckets.length; j++) {
        let rateName =
          buckets[j].charAt(0).toLowerCase() + buckets[j].slice(1) + "Rates";
        let rateArray = convertRate(
          buckets[j],
          studioSettings.classSettings.classBuckets,
          staff
        );
        staffObj.addRate(studios[i], rateArray, rateName);
      }
    }

    Object.entries(studioSettings.timeSettings.timeBuckets).map(([key, value]) => {
      let id = key.charAt(0).toLowerCase() + key.slice(1);
      if(value.type === "Earning"){
        let rateKey = id + "Earning";
        if(staff[rateKey] && staff[rateKey].length > 0){
          if(staffObj[rateKey]){
            staffObj[rateKey] = staffObj[rateKey].concat(staff[rateKey]);
          }else{
            staffObj[rateKey] = staff[rateKey];
          }
        }
      }
    });

    if (newStaff) {
      staffArray.push(staffObj);
    }
  });

  return staffArray;
}

function getAllStaffNames(staff) {
  let names = staff.alternateNames;
  names.unshift(staff.name);
  return names;
}

function convertRate(type, buckets, staffObj) {
  let rateArray = [];
  let typeBucket = buckets[type];
  let rateType = typeBucket.rate.structure;

  let staffRateType = type.charAt(0).toLowerCase() + type.slice(1) + "Rate";
  let staffRate = staffObj[staffRateType];
  let capacity = parseInt(
    typeBucket.capacity.substring(typeBucket.capacity.indexOf("~") + 1)
  );

  // if (type === "Private") {
  //   checkPercent(parseFloat(staffRate.private));
  //   rateArray.push(staffRate.private);
  // } else {
  if (typeBucket.rate.includeZero === "Not Paid") {
    rateArray.push("0");
  } else if (typeBucket.rate.includeZero === "Paid The Base Rate" && rateType === "Base Rate + Per Head Bonus") {
    rateArray.push(staffRate.base);
  } else if (typeBucket.rate.includeZero === "Paid The Base Rate" && rateType === "Flat Rate") {
    rateArray.push(staffRate.flat);
  } else {
    rateArray.push(staffRate.zero);
  }
  // rateArray.push(staffRate.zero);
  // checkPercent(parseFloat(staffRate.zero));
  // }

  if (rateType === "Base Rate + Per Head Bonus") {
    let base = parseFloat(staffRate.base);
    let perHead = parseFloat(staffRate.perHead);
    let afterAttendee = parseInt(staffRate.afterAttendee);
    for (let i = 1; i <= capacity; i++) {
      if (i <= afterAttendee) {
        rateArray.push("" + base);
      } else {
        let extraAttendees = i - afterAttendee;
        rateArray.push("" + (base + extraAttendees * perHead));
      }
    }
  } else if (rateType === "Custom Amount") {
    for (let i = 0; i < staffRate.custom.length; i++) {
      if (i === 0 || (i === 1 && type === "Private")) {
        continue;
      }
      //checkPercent(parseFloat(staffRate.custom[i]));
      rateArray.push("" + staffRate.custom[i]);
    }
  } else if (rateType === "Flat Rate") {
    for (let i = 0; i < capacity; i++) {
      rateArray.push("" + staffRate.flat);
    }
  } else if (rateType === "None (Zero)") {
    for (let i = 0; i < capacity; i++) {
      rateArray.push(0);
    }
  }

  while (rateArray.length <= capacity) {
    rateArray.push(0);
  }

  return rateArray;
}

// function checkPercent(value) {
//   if (value < 1 && value > 0) {
//     useSessionFile = true;
//   }
// }

function removeInputFormatting(value) {
  let id = getIdentifier(value);
  let val = getValue(value);
  if (id === "text") {
    return val;
  } else if (id === "number") {
    if (val === "") {
      return -1;
    }
    return parseFloat(val);
  } else if (id === "dollar") {
    if (val === "") {
      return -1;
    }
    return parseFloat(val);
  } else if (id === "percent") {
    if (val === "") {
      return -1;
    }
    return parseFloat(val) / 100;
  } else if (id === "time") {
    return val;
  } else if(id === "hour"){
    return parseFloat(val);
  }
  return value;
}

function getIdentifier(value) {
  return value.substring(0, value.indexOf("~"));
}

function getValue(value) {
  return value.substring(value.indexOf("~") + 1, value.length);
}

function compareNumberIndex(a, b) {
  const [firstId, firstObj] = a;
  const [secondId, secondObj] = b;
  let firstIndex = firstObj.exceptionNumber;
  let secondIndex = secondObj.exceptionNumber;

  return firstIndex - secondIndex;
}

function compareColumnIndex(a, b) {
  const [firstId, firstObj] = a;
  const [secondId, secondObj] = b;
  let firstIndex = firstObj.columnNumber;
  let secondIndex = secondObj.columnNumber;

  return firstIndex - secondIndex;
}

export function buildSettings(studioSettings, staffSettings) {
  let useSessionFile = false;

  let includeTimeClock = new BooleanToggle(null, false);

  let payMaxOnFullClasses = new BooleanToggle(null, false);
  const classCatchAllObjects = [];
  let includeAgreements = new BooleanToggle(null, false);
  let sessionSignupBonus = new InputBox("Integer", null, 0);
  let includeSalesFile = new BooleanToggle(null, false);
  let organizeStaffLastName = new BooleanToggle(null, true);
  let includeCommissionTabs = new BooleanToggle(null, true);
  let noCommissionForStaffBoughtProducts = new BooleanToggle(null, false);
  let annualCommission = new InputBox("Integer", null, 1);
  let singleClassPrevNewAgreement = new BooleanToggle(null, false);
  let noProductSalesCommissionBelow = new InputBox("Integer", null, 0);
  let diffCommissionPayCol = new AgreementPriceSettings(-1, true);
  let timeSignUpPeriodPostSession = new InputBox("Integer", null, 24);
  let productSaleCommentForNoCommission = "Nothing Yet";
  let useGrossSalesDetail = new BooleanToggle(null, false);
  let includeCostPerClassMetric = new BooleanToggle(null, false);
  let rescheduledClassStatuses = new ClassAttributeList("Rescheduled Classes");
  let summaryTabCommissionForIndividualTabs = new BooleanToggle(null, false);
  let useWhenIWorkTime = new BooleanToggle(null, false);
  let useWhenIWorkTimeDetail = new BooleanToggle(null, false);
  let includePayTypeSubtotals = new BooleanToggle(null, false);
  let includeStudioSubtotals = new BooleanToggle(null, false);
  let includeOnlyCommissionableItems = new BooleanToggle(null, false);
  const catchAllCommissionLogic = [];
  let useSlingTime = new BooleanToggle(null, false);
  let useSlingTimeBasic = new Boolean(null, false);
  let useSlingTimeAdvanced = new Boolean(null, false);
  let useTSheetsTime = new BooleanToggle(null, false);
  let agreementDiscountPercentageNoCommission = new InputBox(
    "Integer",
    null,
    -1
  );
  let customEmailSubjectLine = new InputBox("String", null, "Pay Stub Summary");
  let processorConfig = null;
  let hoursVsClassesPercentFlag = new HoursVsClassFlag(false);
  let confirmCommissionFromGrossSales = new BooleanToggle(null, false);
  let useProsprTime = new BooleanToggle(null, false);
  let useClubReadyTimePunchDetail = new BooleanToggle(null, false);
  let useGustoTime = new BooleanToggle(null, false);
  let usePaycomTime = new BooleanToggle(null, false);
  let useDeputyTime = new BooleanToggle(null, false);
  let useDeputyDetailTime = new BooleanToggle(null, false);
  let punchDetailInStaffTabs = new BooleanToggle(null, false);
  let attendeeAlternates = [];
  let commentsCarryOverToStaffTabs = ["Class"];
  let displayCountsFormat = ["All", "Count"];
  let showRescheduledClassesInOutput = true;
  let showAttendanceStatus = [];
  let showAttendeeBonusComment = false;
  
  let mealBreakFlag = null;

  let upgradePaymentTypes = [];

  let commissionPayObjects = [];
  let salesBuckets = [];
  let timeCatchAllObject = [];

  let classBuckets = [];
  let timeBuckets = [];

  let singleAgreements = ["Single Class"];
  let retailColumn = new InputBox("Integer", null, -1);

  let systemClockOutTime = new InputBox("String", null, "11:00 PM");
  let displayScheduleTab = new BooleanToggle(null, false);
  let weeklyOvertime = null;
  let dailyOvertime = null;
  let dailyDoubleOvertime = null;
  let consecutiveOvertime = null;
  let overtimeRate = null;
  let doubleOvertimeRate = null;
  let holidayRate = null;
  let holidaysObserved = [];
  let addOvertimePremiumAmount = false;


  let confirmFromSessionPayroll = new BooleanToggle(null, false);

  //new tiny updates
  let ignorePreviousAgreements = new BooleanToggle(null, false);
  let onlyShowPaidHoursSchedule = new BooleanToggle(null, false);

  const classSettings = studioSettings.classSettings;
  const timeSettings = studioSettings.timeSettings;
  const commissionSettings = studioSettings.commissionSettings;
  const formatSettings = studioSettings.formatSettings;
  const generalSettings = studioSettings.generalSettings;
  const salesSettings = studioSettings?.salesSettings || null;

  includeTimeClock = new BooleanToggle(
    timeSettings.general.useTimeClock.length > 0 &&
      timeSettings.general.useTimeClock !== "None",
    false
  );

  useClubReadyTimePunchDetail = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("ClubReady Time Punch Detail"),
    false
  );

  let includeNoShows = false;
  let includeLateCancels = false;

  for (
    let i = 0;
    i < classSettings.general.fullClassSettings.statuses.length;
    i++
  ) {
    if (
      classSettings.general.fullClassSettings.statuses[i].includes("No Show")
    ) {
      includeNoShows = true;
    } else if (
      classSettings.general.fullClassSettings.statuses[i].includes("Cancelled")
    ) {
      includeLateCancels = true;
    }
  }

  payMaxOnFullClasses = {
    valid: classSettings.general.fullClassSettings.valid,
    noShows: includeNoShows,
    lateCancels: includeLateCancels,
    time: removeInputFormatting(
      classSettings.general.fullClassSettings.timeCancel
    ),
    statuses: classSettings.general.fullClassSettings.statuses,
  };

  function createRate(exception) {
    let type = exception.rate;
    let valueOne = exception.rateValueOne;
    let valueTwo = exception.rateValueTwo;
    let valueThree = exception.rateValueThree;

    let defaultValues = getDefaultValue(type);
    if (valueOne === null) {
      valueOne = defaultValues[0];
    } else if (valueTwo === null) {
      valueTwo = defaultValues[1];
    } else if (valueThree === null) {
      valueThree = defaultValues[2];
    }

    if (type === "Exactly") {
      return "*" + removeInputFormatting(valueOne);
    } else if (type === "a bonus of") {
      return "++" + removeInputFormatting(valueOne);
    } else if (type === "Percentage") {
      return removeInputFormatting(valueOne) + "G%";
    } else if (type === "Attendee Count") {
      return "@" + parseInt(removeInputFormatting(valueOne));
    } else if (type === "With No Shows and Late Cancels") {
      let str = "";
      if (valueOne.includes("No Shows")) {
        str = str + "NS";
      }
      if (valueOne.includes("Late Cancels")) {
        str = str + "LC";
      }
      if (valueOne.includes("Open Bookings")) {
        str = str + "OB";
      }
      return str;
    } else if (type === "Amount minus hourly rate") {
      let amount = removeInputFormatting(valueOne);
      let math = valueTwo;
      return "" + amount + math + "hourly";
    } else if (type === "Specific Class Rate") {
      return valueOne;
    } else if (type === "Throw Flag") {
      return "FLAG:" + removeInputFormatting(valueOne);
    } else if (type === "Base Plus Per Head") {
      return (
        removeInputFormatting(valueOne) +
        "+" +
        removeInputFormatting(valueTwo) +
        "@" +
        removeInputFormatting(valueThree)
      );
    } else if (type === "Percent of Pay") {
      return "%" + removeInputFormatting(valueOne);
    } else if (type === "Custom") {
      return "?" + removeInputFormatting(valueOne);
    }

    return null;
  }

  function getDefaultValue(value) {
    let valueArray = [null, null, null];
    if (value === "Exactly" || value === "a bonus of") {
      valueArray[0] = "dollar~0";
    } else if (value === "Amount minus hourly rate") {
      valueArray[0] = "dollar~0";
      valueArray[1] = "-";
    } else if (value === "Percentage" || value === "Percent of Pay") {
      valueArray[0] = "percent~0";
    } else if (
      value === "With No Shows and Late Cancels" ||
      value === "Specific Class Rate"
    ) {
      valueArray[0] = [];
    } else if (value === "Attendee Count") {
      valueArray[0] = "number~0";
    } else if (value === "Throw Flag") {
      valueArray[0] = "text~";
    } else if (value === "Base Plus Per Head") {
      valueArray[0] = "dollar~0";
      valueArray[1] = "dollar~0";
      valueArray[2] = "number~0";
    }

    return valueArray;
  }

  let operators = {
    "less than": "<",
    "more than": ">",
    exactly: "=",
    "not exactly": "!",
  };

  Object.entries(classSettings.exceptions)
    .sort(compareNumberIndex)
    .map(([key, value]) => {
      let rateIndicator = "" + createRate(value);
      if (rateIndicator !== null && rateIndicator.substring(0, 1) === "%") {
        useSessionFile = true;
      }
      //let rateOutput = encodeRate(value.rate, value.rateAmount[value.rate]);
      const catchAllQuestion = new CatchAllClassLogic(
        value.day.includes("ANY") ? "Any" : value.day,
        value.timeSequence.includes("ALL") ? "All" : value.timeSequence,
        removeInputFormatting(value.time),
        value.type.includes("ANY") ? "Any" : value.type,
        value.name,
        value.instructor,
        value.studio,
        value.paySequence.includes("ANY")
          ? "Any"
          : operators[value.paySequence] +
            removeInputFormatting(value.payAmount),
        value.attendeeSequence.includes("ANY")
          ? "Any"
          : operators[value.attendeeSequence] +
            removeInputFormatting(value.attendeeNumber),
        "" + rateIndicator
      );
      catchAllQuestion.overridePay = value.override;
      classCatchAllObjects.push(catchAllQuestion);
    });

  includeAgreements = new BooleanToggle(
    commissionSettings.general.useAgreementsLog,
    false
  );

  Object.entries(commissionSettings.commissionBuckets).map(([key, value]) => {
    let commissionLogic = new CommissionBucket(key);
    commissionLogic.setAmount(removeInputFormatting(value.rate));
    commissionLogic.setSplit(value.split === "Split between");
    for (let i = 0; i < value.salespeople.length; i++) {
      if (value.salespeople[i] === "Closing Salesperson") {
        commissionLogic.setSalesPersonPay("Close", true);
      } else if (value.salespeople[i] === "Opening Salesperson") {
        commissionLogic.setSalesPersonPay("Open", true);
      } else if (value.salespeople[i] === "Instructor") {
        commissionLogic.setSalesPersonPay("Instructor", true);
      } else if (value.salespeople[i] === "Secondary Salesperson") {
        commissionLogic.setSalesPersonPay("Second", true);
      }
    }
    commissionPayObjects.push(commissionLogic);
  });

  if(salesSettings && salesSettings?.on){
    Object.entries(salesSettings.salesBuckets).map(([key, value]) => {
      let salesLogic = new SalesBucket(key, value.type, value.implicitType, value.itemNames, value.trackPrevious);
      salesLogic.addStaffSpecifications(value.staffNamesPaidForThisBucket, value.staffTypesPaidForThisBucket);
      salesLogic.addHierarchy(value.hierarchy)

      salesLogic.addReactivationOptions(value.reactivationOptions, value.reactivationTimeFrame);
      salesLogic.addSessionConverion(value.sessionConversion, value.sessionConversionTime, value.sessionConversionClassBuckets, value.sessionConversionInstructorBonus);
      salesLogic.addMultiplePreviousAgreementsLogic(value.multiplePreviousAgreementsPayment);
      salesLogic.addDiscountPercentages(value.discountPercentages, value?.commissionStaffBought || false);
      salesLogic.addPostDatedSaleOptions(value.postDatedSaleOptions);
      salesLogic.addUnpaidItemOptions(value.unpaidItemOptions, value.unpaidItemDifferentRate);
      salesLogic.addRateLogic(value.distinctRates, value.rates, value.rateOverrides);

      salesBuckets.push(salesLogic);
    });
  }

  sessionSignupBonus = new InputBox(
    "Integer",
    removeInputFormatting(commissionSettings.general.introInstructorBonus),
    0
  );

  includeSalesFile = new BooleanToggle(
    commissionSettings.general.useProductGrossSales,
    false
  );

  organizeStaffLastName = new BooleanToggle(
    formatSettings.general.organizeStaffLastName,
    true
  );

  includeCommissionTabs = new BooleanToggle(
    formatSettings.general.staffCommissionFormat !== "DO NOT INCLUDE",
    true
  );

  noCommissionForStaffBoughtProducts = new BooleanToggle(
    !commissionSettings.general.commissionStaffBought,
    false
  );

  singleClassPrevNewAgreement = new BooleanToggle(
    commissionSettings.general.agreementSingleGC === "New Agreement",
    false
  );

  diffCommissionPayCol = new AgreementPriceSettings(
    removeInputFormatting(commissionSettings.general.agreementPayColumn),
    commissionSettings.general.subtractSetupFee
  );

  timeSignUpPeriodPostSession = new InputBox(
    "Integer",
    removeInputFormatting(commissionSettings.general.postIntroSignUpTime),
    24
  );

  Object.entries(timeSettings.exceptions)
    .sort(compareNumberIndex)
    .map(([key, value]) => {
      let timeCatchAll = new CatchAllTimeLogic(value.label);
      timeCatchAll.setType(value.label);
      timeCatchAll.setUtility(value.utility);
      if (value.studio.length === 0) {
        timeCatchAll.setLocation(generalSettings.studios);
      } else {
        timeCatchAll.setLocation(value.studio);
      }

      if (value.staff.length === 0) {
        timeCatchAll.setStaff(
          Object.values(staffSettings.staff).map((s) => s.name)
        );
      } else {
        timeCatchAll.setStaff(value.staff);
      }

      let hours = removeInputFormatting(value.hours);
      let rate = removeInputFormatting(value.rate);
      let total = removeInputFormatting(value.total);
      timeCatchAll.setHours(hours);
      timeCatchAll.setRate(rate);
      if (hours * rate === total || total === -1) {
        timeCatchAll.setTotal(removeInputFormatting(value.total), false);
      } else {
        timeCatchAll.setTotal(removeInputFormatting(value.total), true);
      }
      timeCatchAll.setPayLogic(value.daySequence, value.day);
      timeCatchAll.setComment(removeInputFormatting(value.comment));
      timeCatchAllObject.push(timeCatchAll);
    });

  includeCostPerClassMetric = new BooleanToggle(
    formatSettings.general.displayCostPerClass,
    false
  );

  Object.entries(classSettings.classBuckets).map(([key, value]) => {
    let bucket = new ClassBucket(key);
    bucket.addNames(value.names);
    bucket.addStatuses(value.statuses);
    bucket.setCapacity(removeInputFormatting(value.capacity));
    //bucket.setDefaultPay(removeInputFormatting(value.defaultPay));
    bucket.hasSignUpsPostSession = value.hasSignUpsPostSession
      ? value.hasSignUpsPostSession
      : key === "Intro";
    bucket.isAffectedByAlternateClientPayment =
      value.isAffectedByAlternateClientPayment
        ? value.isAffectedByAlternateClientPayment
        : key === "Group";
    bucket.isStandardSessionType = value.isStandardSessionType
      ? value.isStandardSessionType
      : key === "Group";
    bucket.includeSessionTimeInName = value.includeSessionTimeInName
      ? value.includeSessionTimeInName
      : key === "Group";
    bucket.wouldHaveBeenFullApplicable = value.wouldHaveBeenFullApplicable
      ? value.wouldHaveBeenFullApplicable
      : key === "Group";
    bucket.paidOnSignUps = value.paidOnSignUps
      ? value.paidOnSignUps
      : key === "Intro" && classSettings.general.introSignUps === "sign up";
    bucket.payment = value.rate.structure;
    bucket.hasSecondaryName = value?.includesSecondaryName ?? false;
    bucket.excludeOverlapInPayments = value?.excludeOverlapInPayments ?? false;
    classBuckets.push(bucket);
  });

  rescheduledClassStatuses.setAttributes(
    classSettings.general.rescheduledStatuses
  );

  summaryTabCommissionForIndividualTabs = new BooleanToggle(
    formatSettings.general.staffCommissionFormat === "Single Summary Line",
    false
  );

  useWhenIWorkTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("WhenIWork Timesheet"),
    false
  );

  useWhenIWorkTimeDetail = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("WhenIWork Detail Timesheet"),
    false
  );

  includePayTypeSubtotals = new BooleanToggle(
    formatSettings.general.staffPayTypeTotals,
    false
  );

  includeStudioSubtotals = new BooleanToggle(
    formatSettings.general.staffStudioTotals,
    false
  );

  includeOnlyCommissionableItems = new BooleanToggle(
    formatSettings.general.staffNonZeroCommission,
    false
  );

  Object.entries(commissionSettings.exceptions)
    .sort(compareNumberIndex)
    .map(([key, value]) => {
      const commissionException = new CatchAllCommissionLogic();
      commissionException.addTypes(value.type);
      commissionException.addItemName(removeInputFormatting(value.name));
      commissionException.addLocations(value.studio);
      commissionException.addPrice(
        value.priceSequence,
        removeInputFormatting(value.price)
      );
      commissionException.addNotes(removeInputFormatting(value.comment));

      commissionException.addRateType(value.rateFrequency, value.rateNormal);
      commissionException.addRate(
        removeInputFormatting(value.rate),
        value.rateSplit === "Split between",
        value.rateSalespeople
      );

      catchAllCommissionLogic.push(commissionException);
    });

  useSlingTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Sling Timesheet"),
    false
  );

  useSlingTimeBasic = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Sling Basic Timesheet"),
    false
  );

  useSlingTimeAdvanced = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Sling Advanced Timesheet"),
    false
  );

  useTSheetsTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("TSheets Timesheet"),
    false
  );

  useProsprTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Prospr Timesheet"),
    false
  );

  useGustoTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Gusto Timesheet"),
    false
  );

  usePaycomTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Paycom Timesheet"),
    false
  );

  useDeputyTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Deputy Timesheet"),
    false
  );

  useDeputyDetailTime = new BooleanToggle(
    timeSettings.general.useTimeClock.includes("Deputy Detail Timesheet"),
    false
  );

  agreementDiscountPercentageNoCommission = new InputBox(
    "Percent",
    removeInputFormatting(
      commissionSettings.general.discountPercentNoCommission
    ),
    -1
  );

  punchDetailInStaffTabs = new BooleanToggle(
    timeSettings.general.punchDetailInStaffTabs,
    false
  );

  useGrossSalesDetail = new BooleanToggle(
    commissionSettings.general.useGrossSalesDetail,
    false
  );

  customEmailSubjectLine = new InputBox(
    "String",
    removeInputFormatting(generalSettings.customEmailSubject),
    "Pay Stub Summary"
  );

  upgradePaymentTypes = commissionSettings.general.upgradePaymentTypes;

  if (commissionSettings?.general?.retailColumn) {
    retailColumn = new InputBox(
      "Integer",
      parseInt(removeInputFormatting(commissionSettings.general.retailColumn)),
      -1
    );
  }

  if (commissionSettings?.general?.singleAgreements) {
    singleAgreements = commissionSettings.general.singleAgreements;
  }

  //COME BACK TO THIS
  processorConfig = buildSettingsProcessorConfig(
    studioSettings,
    formatSettings.processorMapping
  );

  const classCountHour = formatSettings.general.classCountVsHourFlag;
  hoursVsClassesPercentFlag.valid = classCountHour.valid;
  hoursVsClassesPercentFlag.addAverageClassHours(
    removeInputFormatting(classCountHour.classLength)
  );
  hoursVsClassesPercentFlag.addPercentError(
    removeInputFormatting(classCountHour.percentDifference)
  );

  confirmCommissionFromGrossSales = new BooleanToggle(
    commissionSettings.general.confirmCommissionFromGrossSales,
    false
  );

  systemClockOutTime = new InputBox(
    "String",
    removeInputFormatting(timeSettings.general.systemClockOutTime),
    "11:00 PM"
  );

  Object.entries(timeSettings.timeBuckets).map(([key, value]) => {
    let bucket = new TimeBucket(key, value.type);
    bucket.isStandardTimeType = value.isStandardTimeType;
    bucket.rounded = value.rounded;
    bucket.names = value.names;
    bucket.overtimeApplicable = value.overtimeApplicable;
    bucket.frequency = value.frequency;
    timeBuckets.push(bucket);
  });

  weeklyOvertime = timeSettings?.general?.weeklyOvertime || null;
  if(weeklyOvertime !== null){
    weeklyOvertime = removeInputFormatting(timeSettings.general.weeklyOvertime);
  }

  dailyOvertime = timeSettings?.general?.dailyOvertime || null;
  if(dailyOvertime !== null){
    dailyOvertime = removeInputFormatting(timeSettings.general.dailyOvertime);
  }

  dailyDoubleOvertime = timeSettings?.general?.dailyDoubleOvertime || null;
  if(dailyDoubleOvertime !== null){
    dailyDoubleOvertime = removeInputFormatting(timeSettings.general.dailyDoubleOvertime);
  }

  consecutiveOvertime = timeSettings?.general?.consecutiveOvertime || null;
  if(consecutiveOvertime !== null){
    consecutiveOvertime = removeInputFormatting(timeSettings.general.consecutiveOvertime);
  }

  overtimeRate = timeSettings?.general?.overtimeRate || null;
  doubleOvertimeRate = timeSettings?.general?.doubleOvertimeRate || null;
  holidayRate = timeSettings?.general?.holidayRate || null;
  holidaysObserved = timeSettings?.general?.holidaysObserved || [];
  addOvertimePremiumAmount = timeSettings?.general?.addOvertimePremiumAmount || false;

  displayScheduleTab = new BooleanToggle(
    formatSettings.general.displayScheduleTab,
    false
  );

  confirmFromSessionPayroll = new BooleanToggle(
    classSettings.general.confirmFromSessionPayroll,
    false
  );

  ignorePreviousAgreements = new BooleanToggle(
    commissionSettings.general.ignorePreviousAgreements,
    false
  );

  onlyShowPaidHoursSchedule = new BooleanToggle(
    formatSettings.general.onlyShowPaidHoursSchedule,
    false
  );

  attendeeAlternates =
    classSettings.general.attendeePayment === undefined
      ? []
      : classSettings.general.attendeePayment;

  commentsCarryOverToStaffTabs = formatSettings?.general?.commentsCarryOverToStaffTabs ?? ["Class"];

  displayCountsFormat = formatSettings?.general?.displayCountsFormat ?? ["All", "Count"];
  if(displayCountsFormat === "All"){
    displayCountsFormat = ["All", "Count"];
  }

  showRescheduledClassesInOutput = formatSettings?.general?.showRescheduledClassesInOutput ?? true;

  showAttendanceStatus = formatSettings?.general?.showAttendanceStatus ?? [];

  showAttendeeBonusComment = formatSettings?.general?.showAttendeeBonusComment ?? false;

  let useComplexTimeSettings = timeSettings?.general?.useComplexTimeSettings ?? false;

  let mbf = timeSettings?.general?.mealBreakFlag ?? 'hour~-1';
  if(mbf === null){
    mbf = 'hour~-1';
  }
  mealBreakFlag = removeInputFormatting(mbf ?? 'hour~-1') ?? null;

  let namingConventions = {
    sessionName: "Session",
    sessionsName: "Sessions",
    timeName: "Time",
    commissionName: "Commission",
    specialistName: "Instructor",
    specialistsName: "Instructors",
    clientName: "Attendee", //Client..
    clientsName: "Attendees",
    memberName: "Member", //Customer...
    membersName: "Members",
    locationName: "Studio",
    locationsName: "Studios",
  };

  generalSettings.sessionName
    ? (namingConventions.sessionName = generalSettings.sessionName)
    : null;
  generalSettings.sessionsName
    ? (namingConventions.sessionsName = generalSettings.sessionsName)
    : null;
  generalSettings.timeName
    ? (namingConventions.timeName = generalSettings.timeName)
    : null;
  generalSettings.commissionName
    ? (namingConventions.commissionName = generalSettings.commissionName)
    : null;
  generalSettings.specialistName
    ? (namingConventions.specialistName = generalSettings.specialistName)
    : null;
  generalSettings.specialistsName
    ? (namingConventions.specialistsName = generalSettings.specialistsName)
    : null;
  generalSettings.clientName
    ? (namingConventions.clientName = generalSettings.clientName)
    : null;
  generalSettings.clientsName
    ? (namingConventions.clientsName = generalSettings.clientsName)
    : null;
  generalSettings.memberName
    ? (namingConventions.memberName = generalSettings.memberName)
    : null;
  generalSettings.membersName
    ? (namingConventions.membersName = generalSettings.membersName)
    : null;
  generalSettings.locationName
    ? (namingConventions.locationName = generalSettings.locationName)
    : null;
  generalSettings.locationsName
    ? (namingConventions.locationsName = generalSettings.locationsName)
    : null;

  let staffTypes = [
    {
      name: "Instructor",
      hourlyPay: true,
      commissionPay: true,
      sessionPay: true,
    },
    {
      name: "Non-Instructor",
      hourlyPay: true,
      commissionPay: true,
      sessionPay: false,
    },
    {
      name: "Manager",
      hourlyPay: true,
      commissionPay: true,
      sessionPay: false,
    },
  ];

  generalSettings.staffTypes ? (staffTypes = generalSettings.staffTypes) : null;

  let staffFields = generalSettings.staffFields ?? [];

  // payPeriodExamples: [],
  //   payFrequency: "Bi-Weekly",
  //   fiscalWorkweekStartDay: "Sunday",
  //   holidaysObserved: [],
  //   weeklyPayDay: "Friday",
  //   monthlyPayDays: ["15th", "Last Day of Month"],
  //   payPeriodDays1: ["1st", "15th"],
  //   payPeriodDays2: ["16th", "Last Day of Month"],

  //   agreementCommissionPaySequence: "Standard",
  //   retailCommissionPaySequenceType: "Standard",
  let payPeriodObject = {
    payPeriodExamples: generalSettings.payPeriodExamples,
    payFrequency: generalSettings.payFrequency,
    fiscalWorkweekStartDay: generalSettings.fiscalWorkweekStartDay,
    holidaysObserved: generalSettings.holidaysObserved,
    weeklyPayDay: generalSettings.weeklyPayDay,
    monthlyPayDays: generalSettings.monthlyPayDays,
    payPeriodDays1: generalSettings.payPeriodDays1,
    payPeriodDays2: generalSettings.payPeriodDays2,
    agreementCommissionPaySequence: generalSettings.agreementCommissionPaySequence,
    retailCommissionPaySequenceType: generalSettings.retailCommissionPaySequenceType,
    processor: generalSettings.processor
  }
  let attendeeStatuses = generalSettings.attendeeStatuses || [
    "Completed Booking",
    "Cancelled Outside Policy Rules - Session Lost",
    "No Show Booking",
    "Cancelled Within Policy Rules - No session credit lost",
    "Cancelled By Admin - Not Customer Fault - No Session Credit Lost",
    "Rescheduled By Admin",
    "Open Booking - not yet logged",
    "Past But Not Logged"
  ];
  let completedStatuses = generalSettings.completedStatuses || ['Completed Booking'];

  const questionArray = {
    includeTimeClock: includeTimeClock,
    payMaxOnFullClasses: payMaxOnFullClasses,
    classCatchAllObjects: classCatchAllObjects,
    includeAgreements: includeAgreements,
    sessionSignupBonus: sessionSignupBonus,
    includeSalesFile: includeSalesFile,
    organizeStaffLastName: organizeStaffLastName,
    includeCommissionTabs: includeCommissionTabs,
    noCommissionForStaffBoughtProducts: noCommissionForStaffBoughtProducts,
    annualCommission: annualCommission,
    singleClassPrevNewAgreement: singleClassPrevNewAgreement,
    noProductSalesCommissionBelow: noProductSalesCommissionBelow,
    diffCommissionPayCol: diffCommissionPayCol,
    timeSignUpPeriodPostSession: timeSignUpPeriodPostSession,
    productSaleCommentForNoCommission: productSaleCommentForNoCommission,
    useGrossSalesDetail: useGrossSalesDetail,
    includeCostPerClassMetric: includeCostPerClassMetric,
    rescheduledClassStatuses: rescheduledClassStatuses,
    summaryTabCommissionForIndividualTabs:
      summaryTabCommissionForIndividualTabs,
    classBuckets: classBuckets,
    useWhenIWorkTime: useWhenIWorkTime,
    useWhenIWorkTimeDetail: useWhenIWorkTimeDetail,
    includePayTypeSubtotals: includePayTypeSubtotals,
    includeStudioSubtotals: includeStudioSubtotals,
    includeOnlyCommissionableItems: includeOnlyCommissionableItems,
    catchAllCommissionLogic: catchAllCommissionLogic,
    useSlingTime: useSlingTime,
    useTSheetsTime: useTSheetsTime,
    useClubReadyTimePunchDetail: useClubReadyTimePunchDetail,
    agreementDiscountPercentageNoCommission:
      agreementDiscountPercentageNoCommission,
    customEmailSubjectLine: customEmailSubjectLine,
    processorConfig: processorConfig,
    hoursVsClassesPercentFlag: hoursVsClassesPercentFlag,
    commissionPayObjects: commissionPayObjects,
    timeCatchAllObject: timeCatchAllObject,
    upgradePaymentTypes: upgradePaymentTypes,
    confirmCommissionFromGrossSales: confirmCommissionFromGrossSales,
    punchDetailInStaffTabs: punchDetailInStaffTabs,
    useSlingTimeBasic: useSlingTimeBasic,
    useSlingTimeAdvanced: useSlingTimeAdvanced,
    useProsprTime: useProsprTime,
    useGustoTime: useGustoTime,
    usePaycomTime: usePaycomTime,
    useDeputyTime: useDeputyTime,
    useDeputyDetailTime: useDeputyDetailTime,
    attendeeAlternates: attendeeAlternates,
    namingConventions: namingConventions,
    retailColumn: retailColumn,
    singleAgreements: singleAgreements,
    staffTypes: staffTypes,
    systemClockOutTime: systemClockOutTime,
    displayScheduleTab: displayScheduleTab,
    confirmFromSessionPayroll: confirmFromSessionPayroll,
    ignorePreviousAgreements: ignorePreviousAgreements,
    onlyShowPaidHoursSchedule: onlyShowPaidHoursSchedule,
    commentsCarryOverToStaffTabs: commentsCarryOverToStaffTabs,
    displayCountsFormat: displayCountsFormat,
    showRescheduledClassesInOutput: showRescheduledClassesInOutput,
    showAttendanceStatus: showAttendanceStatus,
    showAttendeeBonusComment: showAttendeeBonusComment,
    mealBreakFlag: mealBreakFlag,
    salesBuckets: salesBuckets,
    staffFields: staffFields,
    weeklyOvertime: weeklyOvertime,
    dailyOvertime: dailyOvertime,
    dailyDoubleOvertime: dailyDoubleOvertime,
    consecutiveOvertime: consecutiveOvertime,
    overtimeRate: overtimeRate,
    doubleOvertimeRate: doubleOvertimeRate,
    holidayRate: holidayRate,
    holidaysObserved: holidaysObserved,
    addOvertimePremiumAmount: addOvertimePremiumAmount,
    timeBuckets: timeBuckets,
    useComplexTimeSettings: useComplexTimeSettings,
    payPeriodObject: payPeriodObject,
    attendeeStatuses: attendeeStatuses,
    completedStatuses: completedStatuses
  };

  return questionArray;
}

const columnConversion = { A: 0, B: 1, C: 2, D: 3, E: 4, F: 5, G: 6 };

function buildSettingsProcessorConfig(studioSettings) {
  let mapping = studioSettings.formatSettings.processorMapping;

  const config = new ProcessorConfig(mapping.name, mapping.paywellTab);
  config.replacements = mapping.replacements;
  config.deleteEmptyRows = mapping.deleteEmptyRows;
  config.payComponents = mapping.payComponents;
  config.locationSheetBreakout = mapping.locationSheetBreakout;
  config.columnBreakoutName = mapping.columnBreakoutName;
  config.header = mapping?.header ? mapping.header : "";

  Object.entries(mapping.columns)
    .sort(compareColumnIndex)
    .map(([key, entry]) => {
      const column = new Column(removeInputFormatting(entry.name));
      if (entry.columnType.name === "Constant Value") {
        column.addConstantValue(
          entry.columnType.constant.empty
            ? ""
            : removeInputFormatting(entry.columnType.constant.value)
        );
      } else if (entry.columnType.name === "Staff Attribute") {
        let attribute = findAttribute(
          entry.columnType.staff.attribute,
          studioSettings
        );
        let method = new Boolean(attribute.method);
        let duplicate = entry.columnType.staff.duplicate;
        const staffAttribute = new StaffAttribute(
          attribute.value,
          method.toString()
        );
        if (duplicate) {
          staffAttribute.isDuplicate(
            removeInputFormatting(entry.columnType.staff.primary)
          );
        }
        column.addStaffAttribute(staffAttribute);
      } else if (entry.columnType.name === "Excel Attribute") {
        Object.entries(entry.columnType.excel).map(([key, value]) => {
          let excelAttribute = null;
          let v = value.valueColumn;
          if (columnConversion[v] !== undefined) {
            v = columnConversion[v];
          }
          excelAttribute = new ExcelAttribute(v);
          let values = findValues(value.primaryValues, studioSettings);

          excelAttribute.addPrimaryParams(
            columnConversion[value.primaryLookupColumn],
            values
          );
          if (
            value.secondaryLookupColumn !== null &&
            value.secondaryLookupColumn !== "None" &&
            value.secondaryValues.length !== 0
          ) {
            excelAttribute.addSecondaryParams(
              columnConversion[value.secondaryLookupColumn],
              findValues(value.secondaryValues, studioSettings)
            );
          }

          if (value.rowAttribute) {
            const rowAttribute = new RowAttribute(
              value.rowColumnName,
              removeInputFormatting(value.rowColumnValue)
            );
            excelAttribute.addRowAttribute(rowAttribute);

            if (
              value.secondRowColumnName !== undefined &&
              value.secondRowColumnName !== "None" &&
              value.secondRowColumnName !== null
            ) {
              const secondRowAttribute = new RowAttribute(
                value.secondRowColumnName,
                removeInputFormatting(value.secondRowColumnValue)
              );
              excelAttribute.addSecondRowAttribute(secondRowAttribute);
            }
          }
          column.addExcelAttributes(excelAttribute);
        });
      }
      config.addColumn(column);
    });
  return config;
}

function findValues(primaryValue, studioSettings) {
  let newValues = [];
  let values = primaryValue;
  for (let i = 0; i < values.length; i++) {
    let exactInclude =
      values[i].charAt(0) === "<" &&
      values[i].charAt(values[i].length - 1) === ">";
    let foundValues = [];
    if (
      values[i].includes("*All Classes*") ||
      values[i].includes(
        "*All " + studioSettings.generalSettings.sessionsName + "*"
      )
    ) {
      foundValues = addClassNames(
        newValues,
        studioSettings.classSettings.classBuckets,
        Object.keys(studioSettings.classSettings.classBuckets)
      );
    }
    let bucketNames = Object.keys(studioSettings.classSettings.classBuckets);
    for (let j = 0; j < bucketNames.length; j++) {
      if (
        values[i].includes("*All " + bucketNames[j] + " Classes*") ||
        values[i].includes(
          "*All " +
            bucketNames[j] +
            " " +
            studioSettings.generalSettings.sessionsName +
            "*"
        )
      ) {
        foundValues = addClassNames(
          newValues,
          studioSettings.classSettings.classBuckets,
          [bucketNames[j]]
        );
      }
    }

    if (
      values[i].includes("*All Private Classes*") ||
      values[i].includes(
        "*All Private " + studioSettings.generalSettings.sessionsName + "*"
      )
    ) {
      foundValues = addClassNames(
        newValues,
        studioSettings.classSettings.classBuckets,
        ["Private", "SemiPrivate", "ThirtyMinPrivate"]
      );
    }

    let bucketName = bucketNameInString(bucketNames, values[i]);

    if (values[i].includes("*All ") && bucketName !== null) {
      foundValues = addClassNames(
        newValues,
        studioSettings.classSettings.classBuckets,
        [bucketName]
      );
    }

    if (values[i].includes("*All Sales*")) {
      foundValues = [": IA", ": NA", ": UA", ": NR", "{Agreements}", "{Retail}"];
      //newValues = newValues.concat([": IA", ": NA", ": UA", ": NR"]);
    } else if (values[i].includes("*All Agreements*")) {
      foundValues = [": IA", ": NA", ": UA", "{Agreements}"];
      //newValues = newValues.concat([": IA", ": NA", ": UA"]);
    } else if (values[i].includes("*All Retail*")) {
      foundValues = [": NR", "{Retail}"];
      //newValues = newValues.concat([": NR"]);
    } else if (values[i].includes("*All Post-Intro Agreements*")) {
      foundValues = [": IA"];
      //newValues = newValues.concat([": IA"]);
    } else if (values[i].includes("*All Non-Intro Agreements*")) {
      foundValues = [": NA"];
      //newValues = newValues.concat([": NA"]);
    } else if (values[i].includes("*All Upgrade/Downgrade Agreements*")) {
      foundValues = [": UA"];
      //newValues = newValues.concat([": UA"]);
    } else if (values[i].includes("*All Studios*")) {
      foundValues = addAllStudios(
        newValues,
        studioSettings.generalSettings.studios
      );
      //newValues = newValues.concat([": UA"]);
    } else if (values[i].includes("*All Time*")) {
      foundValues = ["Mon,", "Tue,", "Wed,", "Thu,", "Fri,", "Sat,", "Sun,","Regular Totals"];
    }else {
      foundValues.push(values[i]);
      //newValues.push(values[i]);
    }

    if (exactInclude) {
      for (let j = 0; j < foundValues.length; j++) {
        if (
          foundValues[j].charAt(0) === "<" &&
          foundValues[j].charAt(foundValues[j].length - 1) === ">"
        ) {
          continue;
        }
        foundValues[j] = "<" + foundValues[j] + ">";
      }
    }

    newValues = newValues.concat(foundValues);
  }
  return newValues;
}

function bucketNameInString(bucketNames, string) {
  for (let i = 0; i < bucketNames.length; i++) {
    if (string.includes(bucketNames[i])) {
      return bucketNames[i];
    }
  }
  return null;
}

function addClassNames(values, classBuckets, types) {
  let newValues = values;
  Object.entries(classBuckets).map(([name, bucket]) => {
    let addType = false;
    for (let i = 0; i < types.length; i++) {
      if (types[i] === name) {
        addType = true;
      }
    }
    if (addType) {
      newValues = newValues.concat(bucket.names);
    }
  });

  //ALL CLASSES
  if (types.length === Object.keys(classBuckets).length) {
    let allClasses = [
      "Class Credit",
      "Intro",
      "Private Internal Intro Class",
      "CP Reformer Flow",
      "CP Cardio Sculpt",
      "CP Control",
      "CP F.I.T.",
      "CP Teen",
      "CP Suspend",
      "CP Center+Balance",
      "CP Restore",
      "Semi-Private Session",
      "Apprentice Semi-Private",
      "Internal Special Event",
      "Member Workshop",
      "FREE Workshop",
      "CP Teacher Training Informational Session",
      "Private Training ASMT",
      "Private Event - Workshop ",
      "Limited Capacity Class",
      "Virtual Class!",
      "Virtual Class",
      "Outdoor Class",
      "Pilates Reformer Session",
    ];

    for (let i = 0; i < allClasses.length; i++) {
      if (!newValues.includes(allClasses[i])) {
        newValues.push(allClasses[i]);
      }
    }
  }

  return newValues;
}

function addAllStudios(values, studios) {
  return values.concat(studios);
}

//["Last Name", "First Name", "Full Name", "ID", "Email", "Type", "Titles", "Locations", "Alternate Names"]
function findAttribute(attribute, studioSettings) {
  if (attribute === "Last Name") {
    return { value: "getPrimaryLastName", method: true };
  } else if (attribute === "First Name") {
    return { value: "getPrimaryFirstName", method: true };
  } else if (attribute === "Full Name") {
    return { value: "getPrimaryName", method: true };
  } else if (attribute === "ID") {
    return { value: "id", method: false };
  } else if (attribute === "Secondary ID") {
    return { value: "secondaryId", method: false };
  } else if (attribute === "Email") {
    return { value: "email", method: false };
  } else if (attribute === "Type") {
    return { value: "type", method: false };
  } else if (attribute === "Titles") {
    return { value: "titles", method: false };
  } else if (attribute === "Locations") {
    return { value: "location", method: false };
  } else if (attribute === "Home Location") {
    return { value: "homeLocation", method: false };
  } else if (attribute === "Alternate Names") {
    return { value: "name", method: false };
  } else if (attribute === "*Pay Component*") {
    return { value: "*Pay Component*", method: false };
  } else if (attribute === "Pay Period Start Date") {
    return { value: "Pay Period Start Date", method: false };
  } else if (attribute === "Pay Period End Date") {
    return { value: "Pay Period End Date", method: false };
  }
  let staffFields = studioSettings.staffSettings.staffFields;
  for (let i = 0; i < staffFields.length; i++) {
    if (attribute === staffFields[i].name) {
      return { value: staffFields[i].field, method: false };
    }
  }
  return null;
}
