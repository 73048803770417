import React, { useState, useRef, useEffect } from "react";


export function EarningsCell({ field, value, openEarningsDialog, frequency, rowId }) {
    const earningsArray = value || [];
    let totalEarnings = earningsArray.reduce((acc, curr) => {
      // If amount exists, use it
      if (curr.amount) {
        return acc + curr.amount;
      }
      // If both rate and hours exist, multiply them
      if (curr.rate && curr.hours) {
        return acc + (curr.hours * curr.rate);
      }
      // If neither condition is met, add 0
      return acc;
    }, 0);

    if (earningsArray.length === 0) {
      totalEarnings = null;
    }
    let freqDisplay = "Check";

    if (frequency === "Yearly") {
      freqDisplay = "YR";
    } else if (frequency === "Monthly") {
      freqDisplay = "MO";
    }
    const totalString = totalEarnings === null ? "-" : `$${totalEarnings} / ${freqDisplay}`;

    //openEarningsDialog(field, rowId, value)
    useEffect(() => openEarningsDialog(field, rowId, value), [])
    return <>{totalString}</>;
  }
