import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

/**
 * TabPanel component for conditional rendering of tab content.
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.children - The content to render inside the panel.
 * @param {number} props.value - The currently selected tab index (from the parent Tabs).
 * @param {number} props.index - The index that this panel corresponds to.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="settings-page__tab-panel-inner">
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/**
 * Helper function to build props for each tab.
 * @param {number} index 
 * @returns {Object} props
 */
export function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default TabPanel;



// tab for selecting which columns to view
// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <Box
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box className="settings-page__tab-panel-inner">
//           {children}
//         </Box>
//       )}
//     </Box>
//   );
// }

// TabPanel.propTypes = {import TabPanel, { tabProps } from "./TabPanel";

//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };
// function tabProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

