import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography, Divider } from "@mui/material";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions";
import { Skeleton } from "@mui/material";
import AttributeListBox from "../../Components/AttributeListBox";
import SettingsFormContainer from "../../../components/SettingsForm/SettingsFormContainer.jsx";
import SettingsFormInput from "../../../components/SettingsForm/SettingsFormInput.jsx";
import { settingIds, settingTooltips } from "../CommonVariables";
import useAutoScrollToHash from "../useAutoScrollToHash";
import { getTimeBucketKeys } from "../../../utility-functions/parseSettingsObject";
import NegativeOneToggleInput from "../../Components/NegativeOneToggleInput.jsx";
import { formatHeaderName } from "../../StaffSettings/utils/staffHelpers";

// Tab properties helper function
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// General settings form component
function GeneralTimeSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].timeSettings.general[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_GENERAL_TIME_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      key: id,
      value: value,
    });
  };

  return (
    <SettingsFormContainer>

      <SettingsFormInput 
        label={"Which " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .timeName +
            " clock file(s) do you use:"}
        inputElement={
          <SelectorBox
            className="selector-box__select"
            id="useTimeClock"
            onChange={handleUpdate}
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general
                .useTimeClock
            }
            size={350}
            options={[
              "ClubReady Time Clock Summary",
              "ClubReady Time Punch Detail",
              "WhenIWork Timesheet",
              "WhenIWork Detail Timesheet",
              "Sling Timesheet",
              "Sling Basic Timesheet",
              "Sling Advanced Timesheet",
              "Prospr Timesheet",
              "Gusto Timesheet",
              "Paycom Timesheet",
              "Deputy Timesheet",
              "Deputy Detail Timesheet",
              "TSheets Timesheet"
            ]}
            placeholder="None"
            label="Time File"
            name="Time File"
          />
        }
        tooltip={settingTooltips?.timeSettings?.general?.useTimeClock}
        id={settingIds?.timeSettings?.general?.useTimeClock}
        />

        <SettingsFormInput 
          label="Display Punch Detail in Staff Tabs (if applicable)"
          inputElement={
            <BooleanToggleElement
              onChange={handleUpdate}
              default={
                settings[getUrlVariableValue("settingsId")].timeSettings.general
                  .punchDetailInStaffTabs
              }
              id="punchDetailInStaffTabs"
            />
          }
          tooltip={settingTooltips?.timeSettings?.general?.punchDetailInStaffTabs}
          id={settingIds?.timeSettings?.general?.punchDetailInStaffTabs}
          />

        <SettingsFormInput 
          label="Use Complex Time Clock Settings:"
          inputElement={
            <BooleanToggleElement
              onChange={handleUpdate}
              default={
                settings[getUrlVariableValue("settingsId")].timeSettings.general
                  .useComplexTimeSettings || false
              }
              id="useComplexTimeSettings"
            ></BooleanToggleElement>
          }
          tooltip={settingTooltips?.timeSettings?.general?.useComplexTimeSettings}
          id={settingIds?.timeSettings?.general?.useComplexTimeSettings}
          />

        {settings[getUrlVariableValue("settingsId")].timeSettings.general.useComplexTimeSettings && (
          [

            <SettingsFormInput 
              label="Does the system automatically clock out staff at a certain time?"
              inputElement={
                <InputBoxElement
                    id="systemClockOutTime"
                    default={
                      settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime 
                      ? settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime.includes("text~") ? settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime : "text~" + settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime 
                      : "text~"
                    }
                    onChange={handleUpdate}
                    label="System Clock Out Time"
                    types={["text"]}
                  />
              }
                tooltip={settingTooltips?.timeSettings?.general?.systemClockOutTime}
                id={settingIds?.timeSettings?.general?.systemClockOutTime}
                />,

            <SettingsFormInput 
              label="Do you require meal breaks? If so, what is the minimum time required (minutes):"
              inputElement={
                <NegativeOneToggleInput
                  value={Number(settings[getUrlVariableValue("settingsId")].timeSettings.general?.mealBreakFlag?.split("~")[1]) || -1}
                  onChange={(value) => handleUpdate("hour~" + value, "mealBreakFlag")}
                  inputLabel="Minutes Required"
                  id="mealBreakFlag"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.mealBreakFlag}
              id={settingIds?.timeSettings?.general?.mealBreakFlag}
            />,

            <SettingsFormInput 
              label="Do you want to calcuate WEEKLY overtime hours? If so, how many hours do they need to exceed:"
              inputElement={
                <NegativeOneToggleInput
                  value={Number(settings[getUrlVariableValue("settingsId")].timeSettings.general?.weeklyOvertime?.split("~")[1]) || -1}
                  onChange={(value) => handleUpdate("hour~" + value, "weeklyOvertime")}
                  inputLabel="Hours Required"
                  id="weeklyOvertime"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.weeklyOvertime}
              id={settingIds?.timeSettings?.general?.weeklyOvertime}
            />,

            <SettingsFormInput 
              label="Do you want to calcuate DAILY overtime hours? If so, how many hours do they need to exceed:"
              inputElement={
                <NegativeOneToggleInput
                  value={Number(settings[getUrlVariableValue("settingsId")].timeSettings.general?.dailyOvertime?.split("~")[1]) || -1}
                  onChange={(value) => handleUpdate("hour~" + value, "dailyOvertime")}
                  inputLabel="Hours Required"
                  id="dailyOvertime"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.dailyOvertime}
              id={settingIds?.timeSettings?.general?.dailyOvertime}
            />,

            <SettingsFormInput 
              label="Do you want to calcuate DAILY double overtime hours? If so, how many hours do they need to exceed:"
              inputElement={
                <NegativeOneToggleInput
                  value={Number(settings[getUrlVariableValue("settingsId")].timeSettings.general?.dailyDoubleOvertime?.split("~")[1]) || -1}
                  onChange={(value) => handleUpdate("hour~" + value, "dailyDoubleOvertime")}
                  inputLabel="Hours Required"
                  id="dailyDoubleOvertime"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.dailyDoubleOvertime}
              id={settingIds?.timeSettings?.general?.dailyDoubleOvertime}
            />,

            <SettingsFormInput 
              label="Do you want to calcuate CONSECUTIVE overtime hours? If so, how many hours do they need to work up to on the 7th day:"
              inputElement={
                <NegativeOneToggleInput
                  value={Number(settings[getUrlVariableValue("settingsId")].timeSettings.general?.consecutiveOvertime?.split("~")[1]) || -1}
                  onChange={(value) => handleUpdate("hour~" + value, "consecutiveOvertime")}
                  inputLabel="Hours Required"
                  id="consecutiveOvertime"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.consecutiveOvertime}
              id={settingIds?.timeSettings?.general?.consecutiveOvertime}
            />,

            <SettingsFormInput 
              label="What is your overtime rate:"
              inputElement={
                <SelectorBox
                  className="selector-box__select"
                  id="overtimeRate"
                  onChange={handleUpdate}
                  default={
                    settings[getUrlVariableValue("settingsId")].timeSettings.general.overtimeRate
                  }
                  size={350}
                  options={[
                    '1.5x Hourly Rate',
                    '1.5x Regular Rate',
                    'Overtime Rate',
                  ]}
                  placeholder="None"
                  label="Overtime Rate"
                  name="Overtime Rate"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.overtimeRate}
              id={settingIds?.timeSettings?.general?.overtimeRate}
            />,

            <SettingsFormInput 
              label="What is your double overtime rate:"
              inputElement={
                <SelectorBox
                  className="selector-box__select"
                  id="doubleOvertimeRate"
                  onChange={handleUpdate}
                  default={
                    settings[getUrlVariableValue("settingsId")].timeSettings.general.doubleOvertimeRate
                  }
                  size={350}
                  options={[
                    'None',
                    '2x Hourly Rate',
                    '2x Regular Rate',
                    'Double Overtime Rate',
                  ]}
                  placeholder="None"
                  label="Double Overtime Rate"
                  name="Double Overtime Rate"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.doubleOvertimeRate}
              id={settingIds?.timeSettings?.general?.doubleOvertimeRate}
            />,

            <SettingsFormInput 
              label="What is your holiday rate:"
              inputElement={
                <SelectorBox
                  className="selector-box__select"
                  id="holidayRate"
                  onChange={handleUpdate}
                  default={
                    settings[getUrlVariableValue("settingsId")].timeSettings.general.holidayRate
                  }
                  size={350}
                  options={[
                    'None',
                    '1.5x Hourly Rate',
                    '1.5x Regular Rate',
                    'Overtime Rate',
                    '2x Hourly Rate',
                    '2x Regular Rate',
                    'Holiday Rate',
                  ]}
                  placeholder="None"
                  label="Holiday Rate"
                  name="Holiday Rate"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.holidayRate}
              id={settingIds?.timeSettings?.general?.holidayRate}
            />,

            <SettingsFormInput 
              label="Add Overtime Premium Adjustment"
              inputElement={
                <BooleanToggleElement
                  onChange={handleUpdate}
                  default={
                    settings[getUrlVariableValue("settingsId")].timeSettings.general
                      .addOvertimePremiumAmount
                  }
                  id="addOvertimePremiumAmount"
                />
              }
              tooltip={settingTooltips?.timeSettings?.general?.addOvertimePremiumAmount}
              id={settingIds?.timeSettings?.general?.addOvertimePremiumAmount}
            />,

            <SettingsFormInput 
              label="What days are considered holidays:"
              inputElement={
              <AttributeListBox
                  freeSolo
                  multiple={true}
                  limitTags={20}
                  onChange={handleUpdate}
                  id="holidaysObserved"
                  default={
                    settings[getUrlVariableValue("settingsId")].timeSettings.general
                      .holidaysObserved || []
                  }
                  size={400}
                  label="Holidays Observed"
                  options={[
                    "New Year's Day",
                    "Martin Luther King Jr. Day",
                    "Presidents' Day",
                    "Memorial Day",
                    "Independence Day",
                    "Labor Day",
                    "Columbus Day",
                    "Veterans Day",
                    "Thanksgiving Day",
                    "Christmas Day",
                    "Family Day",
                    "Good Friday",
                    "Victoria Day",
                    "Canada Day",
                    "Civic Holiday",
                    "Labour Day",
                    "Thanksgiving (Canada)",
                    "Boxing Day",
                    "Constitution Day (Mexico)",
                    "Benito Juárez's Birthday",
                    "Revolution Day",
                    "Easter Monday",
                    "May Day / Labor Day",
                    "Ascension Day",
                    "Whit Monday",
                    "National Day (varies by country)",
                    "Assumption Day",
                    "All Saints' Day",
                    "Boxing Day / St. Stephen's Day"
                  ]}
                ></AttributeListBox>
              }

              tooltip={settingTooltips?.timeSettings?.general?.holidaysObserved}
              id={settingIds?.timeSettings?.general?.holidaysObserved}
            />
          ])}
    </SettingsFormContainer>
  );
}

function TimeBucketBox({ bucketName }) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      bucketName + "-" + id,
      settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName][id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_BUCKET_TIME_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      timeBucket: bucketName,
      key: id,
      value: value,
    });
  };

  // Get the current bucket's type
  const bucketType = settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].type;

  return (
    <SettingsFormContainer>

      {/* Always display the Type selector */}
      <SettingsFormInput 
        label="Type:"
        inputElement={
          <SelectorBox
            key={bucketName + "-type"}
            className="selector-box__select"
            id="type"
            onChange={handleBucketUpdate}
            default={bucketType}
            size={350}
            options={['Hourly', 'Earning']}
            placeholder="None"
            label="Type"
            name="Type"
          />
        }
        tooltip={settingTooltips?.timeSettings?.timeBuckets?.sharedBucketProperties?.type}
        id={settingIds?.timeSettings?.timeBuckets?.sharedBucketProperties?.type}
      />

      {/* Only show other fields if type is not 'Earning' */}
      {bucketType !== 'Earning' && (
        [
          <SettingsFormInput 
            label="Time Names:"
            inputElement={
              <AttributeListBox
                key={bucketName + "-names"}
                multiple={true}
                freeSolo
                limitTags={20}
                onChange={handleBucketUpdate}
                id="names"
                default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].names}
                size={400}
                label="Time Names"
                options={[]}
              />
            }
        tooltip={settingTooltips?.timeSettings?.timeBuckets?.sharedBucketProperties?.names}
        id={settingIds?.timeSettings?.timeBuckets?.sharedBucketProperties?.names}
          />,

          <SettingsFormInput 
            label="Standard Type:"
            inputElement={
              <BooleanToggleElement
                key={bucketName + "-isStandardTimeType"}
                id="isStandardTimeType"
                default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].isStandardTimeType}
                onChange={handleBucketUpdate}
                label="Is Standard Time Type"
              />
            }
          tooltip={settingTooltips?.timeSettings?.timeBuckets?.sharedBucketProperties?.isStandardTimeType}
          id={settingIds?.timeSettings?.timeBuckets?.sharedBucketProperties?.isStandardTimeType}
          />,
          /* <Typography>Rounded:</Typography>
          <SelectorBox
            key={bucketName + "-rounded"}
            className="selector-box__select"
            id="rounded"
            onChange={handleBucketUpdate}
            default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].rounded}
            size={350}
            options={['None', 'Up', 'Down', 'Nearest 10th', 'Nearest 100th']}
            placeholder="None"
            label="Rounded"
            name="Rounded"
          /> */
        ]
      )}

{/* <> */}

{/* <Typography>Frequency:</Typography>
<SelectorBox
  key={bucketName + "-earningFrequency"}
  className="selector-box__select"
  id="frequency"
  onChange={handleBucketUpdate}
  default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].frequency}
  size={350}
  // options={['Yearly', 'Monthly', "Pay Period"]}
  options={["Pay Period"]}
  placeholder="None"
  label="Earning Frequency"
  name="Earning Frequency"
/>
<Divider className="settings-page__divider settings-page__divider--light" />
<Typography>Format:</Typography>
<SelectorBox
  key={bucketName + "-format"}
  className="selector-box__select"
  id="format"
  onChange={handleBucketUpdate}
  default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].format || 'Dollar Amount'}
  size={350}
  options={['Hours * Rate', 'Dollar Amount']}
  placeholder="None"
  label="Format"
  name="Format"
/>
</> */}

      {/* Only show other fields if type is 'Earning' */}
      {bucketType === 'Earning' && (
        [
          <SettingsFormInput 
            label="Frequency:"
            inputElement={
              <SelectorBox
                key={bucketName + "-earningFrequency"}
                className="selector-box__select"
                id="frequency"
                onChange={handleBucketUpdate}
                default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].frequency}
                size={350}
                // options={['Yearly', 'Monthly', "Pay Period"]}
                options={["Pay Period"]}
                placeholder="None"
                label="Earning Frequency"
                name="Earning Frequency"
              />
            }
            tooltip={settingTooltips?.timeSettings?.timeBuckets?.sharedBucketProperties?.frequency}
            id={settingIds?.timeSettings?.timeBuckets?.sharedBucketProperties?.frequency}
          />,
          <SettingsFormInput 
            label="Format:"
            inputElement={
              <SelectorBox
                key={bucketName + "-format"}
                className="selector-box__select"
                id="format"
                onChange={handleBucketUpdate}
                default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].format || 'Dollar Amount'}
                size={350}
                options={['Hours * Rate', 'Dollar Amount']}
                placeholder="None"
                label="Format"
                name="Format"
              />
            }
            tooltip={settingTooltips?.timeSettings?.timeBuckets?.sharedBucketProperties?.format}
            id={settingIds?.timeSettings?.timeBuckets?.sharedBucketProperties?.format}
          />
        ]
      )}

      {/* <Divider className="settings-page__divider settings-page__divider--light" /> */}

      {/* <Typography>Overtime Applicable:</Typography>
      <BooleanToggleElement
        key={bucketName + "-overtimeApplicable"}
        id="overtimeApplicable"
        default={settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets[bucketName].overtimeApplicable}
        onChange={handleBucketUpdate}
        label="Overtime Applicable"
      /> */}
    </SettingsFormContainer>
  );
}

// Main time settings component with tabs
export default function TimeSettings() {
  const { settings } = useSettingsContext();
  const [tab, setTab] = useState("General");
  const [tabIndex, setTabIndex] = useState(0);
  const [bucketKeys, setBucketKeys] = useState([]);
  const [orderedBuckets, setOrderedBuckets] = useState([]);

  useEffect(() => {
    const bucketsObject = settings[getUrlVariableValue("settingsId")]?.timeSettings?.timeBuckets;
    const unsortedKeys = bucketsObject ? Object.keys(bucketsObject) : undefined;
    setBucketKeys(unsortedKeys ? unsortedKeys.sort() : []);
    setOrderedBuckets(getOrderedBuckets());
  }, [settings]);

  const setTabAndIndex = (tabId) => {
    const bucketKeys = getTimeBucketKeys(settings);
    const tabIndex = bucketKeys.indexOf(tabId);
    if (tabIndex === -1 && tabId !== "General") {
      console.warn(`No tab exists with id "${tabId}"`)
      return;
    }
    setTab(tabId);
    if (tabId === "General") {
      setTabIndex(0);
    } else {
      setTabIndex(tabIndex + 1);
    }
  }

  useAutoScrollToHash(setTabAndIndex);

  const handleTabChange = (event, newIndex) => {
    const orderedBuckets = getOrderedBuckets();
    setTab(newIndex === 0 ? "General" : orderedBuckets[newIndex - 1]);
    setTabIndex(newIndex);
  };

  // Helper function to sort buckets in the desired order
  const getOrderedBuckets = () => {
    const timeBuckets = settings[getUrlVariableValue("settingsId")].timeSettings.timeBuckets;
    const bucketNames = Object.keys(timeBuckets);

    // First, get standard buckets
    const standardBuckets = bucketNames.filter(name => 
      timeBuckets[name].isStandardTimeType
    );

    // Then, get non-standard hourly buckets
    const hourlyBuckets = bucketNames.filter(name => 
      !timeBuckets[name].isStandardTimeType && 
      timeBuckets[name].type === 'Hourly'
    ).sort();

    // Finally, get earning buckets
    const earningBuckets = bucketNames.filter(name => 
      timeBuckets[name].type === 'Earning'
    ).sort();

    return [...standardBuckets, ...hourlyBuckets, ...earningBuckets];
  };

  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box>
      <Typography variant="h2">Time Settings</Typography>
      <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`Set up rules for your ${settings[getUrlVariableValue("settingsId")].generalSettings.timeName.toLowerCase() || "time"} buckets 
        and configure general settings such as alternate timekeeping reports, overtime, double overtime and holiday pay, and more.`}
      </Typography>
      <Tabs className="settings-page__tabs" value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="General" {...tabProps(0)} className="settings-page__tab"/>
        {orderedBuckets.map((bucketName, index) => (
          <Tab 
            label={formatHeaderName(bucketName)} 
            key={bucketName} 
            {...tabProps(index + 1)} 
            className="settings-page__tab"
          />
        ))}
      </Tabs>

      {tab === "General" ? <GeneralTimeSettings /> : <TimeBucketBox bucketName={tab} />}
    </Box>
  ) : (
    <Box>
      <Skeleton>
        <Typography variant="h2">Loading Time Settings...</Typography>
      </Skeleton>
    </Box>
  );
}
