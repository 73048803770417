// // ComparisonView.js
// import React, { useState, useMemo } from 'react';
// import {
//   Box,
//   Chip,
//   Typography,
//   Paper,
//   Button,
//   Tooltip,
//   Tab,
//   Tabs,
//   TextField,
// } from '@mui/material';
// import { DownloadRounded } from '@mui/icons-material';
// import Papa from 'papaparse';
// import './_outputTest.scss';
// // MUI Data Grid Pro (v5.12.0)
// import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

// // --- 1) Import the diff library ---
// import { diffChars } from 'diff';

// // Define color mapping for different statuses
// const statusColorMap = {
//   ADDED_ROW: 'success',
//   REMOVED_ROW: 'error',
//   REORDER: 'primary',
//   MODIFIED: 'warning',
//   DEFAULT: 'default',
// };

// export const ComparisonView = ({ data }) => {
//   // 1) Unconditional Hooks
//   const [activeTab, setActiveTab] = useState(0);
//   const [filterStatus, setFilterStatus] = useState([]);

//   // Use provided data or fallback to an empty object.
//   const safeData = data || {};

//   /**
//    * 2) Prepare sections data and global stats.
//    * Here we count only rows with differences (i.e. statuses other than UNCHANGED).
//    */
//   const computedData = useMemo(() => {
//     let totalDifferences = 0;
//     const globalStatusCount = {};
//     const sectionsData = [];
//     const statusSet = new Set();

//     Object.entries(safeData).forEach(([sectionName, diffObj]) => {
//       // If diffObj is not an object (e.g. "EXACT MATCH"), then treat differences as zero.
//       if (typeof diffObj !== 'object' || !Array.isArray(diffObj.diffRows)) {
//         sectionsData.push({
//           sectionName,
//           differences: [],
//           total: 0,
//           statusCount: {},
//         });
//         return;
//       }
//       // Filter out unchanged rows for the summary and status counts.
//       const differences = diffObj.diffRows.filter(
//         (diff) => diff.status !== 'UNCHANGED'
//       );
//       totalDifferences += differences.length;
//       const sectionStatusCount = {};
//       differences.forEach((diff) => {
//         globalStatusCount[diff.status] =
//           (globalStatusCount[diff.status] || 0) + 1;
//         sectionStatusCount[diff.status] =
//           (sectionStatusCount[diff.status] || 0) + 1;
//         statusSet.add(diff.status);
//       });
//       sectionsData.push({
//         sectionName,
//         differences,
//         total: differences.length,
//         statusCount: sectionStatusCount,
//       });
//     });

//     return {
//       totalDifferences,
//       differencesPerStatus: globalStatusCount,
//       sectionsData,
//       allStatuses: Array.from(statusSet),
//     };
//   }, [safeData]);


//   // Summary stats (only differences; unchanged rows are excluded)
//   const { totalDifferences, differencesPerStatus, allStatuses } = computedData;
//   const hasActualData = Object.keys(safeData).length > 0;

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   // ===== NEW: Build the full sheet diff data using the new generator =====
//   const diffData = useMemo(() => {
//     // Assume safeData is keyed by sheet name.
//     const sheetName = Object.keys(safeData)[activeTab] || '';
//     if (typeof safeData[sheetName] === 'string') {
//       return { header: [], diffRows: [] };
//     }
//     return safeData[sheetName] || { header: [], diffRows: [] };
//   }, [safeData, activeTab]);

//   // ===== NEW: New columns definition for full sheet view =====
//   const fullSheetColumns = useMemo(() => {
//     // Two fixed columns for row numbers.
//     const baseCols = [
//       {
//         field: 'originalRow',
//         headerName: 'Orig Row',
//         width: 100,
//         renderCell: (params) => params.value ?? '-',
//       },
//       {
//         field: 'updatedRow',
//         headerName: 'Upd Row',
//         width: 100,
//         renderCell: (params) => params.value ?? '-',
//       },
//     ];
//     // Then one column per Excel column (A, B, C, …) determined by the header.
//     const maxCols = diffData.header.length;
//     const excelCols = [];
//     for (let i = 0; i < maxCols; i++) {
//       excelCols.push({
//         field: String.fromCharCode(65 + i),
//         headerName: String.fromCharCode(65 + i),
//         flex: 1,
//         renderCell: (params) => {
//           // For MODIFIED rows, use cellDiffs if available.
//           if (params.row.status === 'MODIFIED' && params.row.cellDiffs) {
//             return <span>{params.row.cellDiffs[i] || (params.row.rowData[i] ?? '')}</span>;
//           }
//           // Otherwise, show the cell value.
//           return <span>{params.row.rowData[i] ?? ''}</span>;
//         },
//       });
//     }
//     return [...baseCols, ...excelCols];
//   }, [diffData.header]);

//   // 9) Render the full sheet view.
//   return (
//     <Box p={4}>
//       {/* Top Summary Section */}
//       <Paper
//         elevation={3}
//         sx={{
//           padding: 3,
//           marginBottom: 4,
//         }}
//       >
//         {/* Header + Export */}
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           flexWrap="wrap"
//         >
//           <Typography variant="h5" gutterBottom>
//             Comparison Summary
//           </Typography>
//         </Box>

//         {/* Overall Summary */}
//         <Box mt={2}>
//           <Typography variant="body1" gutterBottom>
//             <strong>Total Differences:</strong>{' '}
//             <span style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#d32f2f' }}>
//               {totalDifferences}
//             </span>
//           </Typography>
//           {/* Status Summaries (only for differences; unchanged is excluded) */}
//           <Box display="flex" flexWrap="wrap" gap={1}>
//             {allStatuses.map((status) => (
//               <Chip
//                 key={status}
//                 label={`${status} (${differencesPerStatus[status] || 0})`}
//                 color={statusColorMap[status] || statusColorMap.DEFAULT}
//                 variant={filterStatus.includes(status) ? 'filled' : 'outlined'}
//                 clickable
//               />
//             ))}
//           </Box>
//         </Box>
//       </Paper>

   

//       {!hasActualData && (
//         <Typography variant="body2" color="textSecondary">
//           No data available.
//         </Typography>
//       )}

//       {hasActualData && (
//         <Box sx={{ mt: 4 }}>
//           {/* Tabs for each sheet */}
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//             sx={{ marginBottom: 2 }}
//           >
//             {Object.keys(safeData).map((sheetName, index) => (
//               <Tab key={sheetName} label={`${sheetName}`} value={index} />
//             ))}
//           </Tabs>

//           {/* Full Sheet Diff Table */}
//           <Box sx={{ height: 600, width: '100%' }}>
//             <DataGridPro
//               rows={diffData.diffRows.map((row, idx) => ({ id: idx, ...row }))}
//               columns={fullSheetColumns}
//               disableSelectionOnClick
//               pagination={false}
//               rowHeight={50}
//               headerHeight={50}
//               getRowClassName={(params) => {
//                 if (params.row.status === 'ADDED_ROW') return 'added-row';
//                 if (params.row.status === 'REMOVED_ROW') return 'removed-row';
//                 if (params.row.status === 'REORDER') return 'reorder-row';
//                 if (params.row.status === 'MODIFIED') return 'modified-row';
//                 return '';
//               }}
//             />
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };


// ComparisonView.js
import React, { useState, useMemo } from 'react';
import {
  Box,
  Chip,
  Typography,
  Paper,
  Button,
  Tooltip,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { DownloadRounded } from '@mui/icons-material';
import Papa from 'papaparse';
import './_outputTest.scss';
// MUI Data Grid Pro (v5.12.0)
import { DataGridPro } from '@mui/x-data-grid-pro';

// --- 1) Import the diff library ---
import { diffChars } from 'diff';

// Define color mapping for different statuses
const statusColorMap = {
  ADDED_ROW: 'success',
  REMOVED_ROW: 'error',
  REORDER: 'primary',
  MODIFIED: 'warning',
  DEFAULT: 'default',
};




export const ComparisonView = ({ data }) => {
  // 1) Unconditional Hooks
  const [activeTab, setActiveTab] = useState(0);
  const [filterStatus, setFilterStatus] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');

  // Use provided data or fallback to an empty object.
  const safeData = data || {};

  /**
   * 2) Prepare sections data and global stats.
   * Here we count only rows with differences (i.e. statuses other than UNCHANGED).
   */
  const computedData = useMemo(() => {
    let totalDifferences = 0;
    const globalStatusCount = {};
    const sectionsData = [];
    const statusSet = new Set();

    Object.entries(safeData).forEach(([sectionName, diffObj]) => {
      // If diffObj is not an object (e.g. "EXACT MATCH"), then treat differences as zero.
      if (typeof diffObj !== 'object' || !Array.isArray(diffObj.diffRows)) {
        sectionsData.push({
          sectionName,
          differences: [],
          total: 0,
          statusCount: {},
        });
        return;
      }
      // Filter out unchanged rows for the summary and status counts.
      const differences = diffObj.diffRows.filter(
        (diff) => diff.status !== 'UNCHANGED'
      );
      totalDifferences += differences.length;
      const sectionStatusCount = {};
      differences.forEach((diff) => {
        globalStatusCount[diff.status] =
          (globalStatusCount[diff.status] || 0) + 1;
        sectionStatusCount[diff.status] =
          (sectionStatusCount[diff.status] || 0) + 1;
        statusSet.add(diff.status);
      });
      sectionsData.push({
        sectionName,
        differences,
        total: differences.length,
        statusCount: sectionStatusCount,
      });
    });

    return {
      totalDifferences,
      differencesPerStatus: globalStatusCount,
      sectionsData,
      allStatuses: Array.from(statusSet),
    };
  }, [safeData]);

  // Summary stats (only differences; unchanged rows are excluded)
  const { totalDifferences, differencesPerStatus, allStatuses } = computedData;
  const hasActualData = Object.keys(safeData).length > 0;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // ===== NEW: Build the full sheet diff data using the new generator =====
  const diffData = useMemo(() => {
    // Assume safeData is keyed by sheet name.
    const sheetName = Object.keys(safeData)[activeTab] || '';
    if (typeof safeData[sheetName] === 'string') {
      return { header: [], diffRows: [] };
    }
    return safeData[sheetName] || { header: [], diffRows: [] };
  }, [safeData, activeTab]);

  // ===== NEW: Apply filtering (by status and global search) for the table view =====
  const filteredDiffRows = useMemo(() => {
    const isFilteringActive = filterStatus.length > 0 || globalSearch.trim() !== '';
    // If no filters, show all rows; otherwise, exclude unchanged rows.
    let rows = isFilteringActive 
      ? diffData.diffRows.filter((row) => row.status !== 'UNCHANGED')
      : diffData.diffRows;
  
    if (filterStatus.length) {
      rows = rows.filter((row) => filterStatus.includes(row.status));
    }
    if (globalSearch.trim() !== '') {
      const searchLower = globalSearch.trim().toLowerCase();
      rows = rows.filter((row) =>
        row.rowData.some((cell) =>
          String(cell).toLowerCase().includes(searchLower)
        )
      );
    }
    return rows;
  }, [diffData.diffRows, filterStatus, globalSearch]);
  
  
  // ===== NEW: Compute the filtered count for each tab =====
const getFilteredCount = (sheetName) => {
  const sheetData = safeData[sheetName];
  if (typeof sheetData !== 'object') return 0;
  const isFilteringActive = filterStatus.length > 0 || globalSearch.trim() !== '';
  let rows =  sheetData.diffRows.filter((row) => row.status !== 'UNCHANGED');
    
  if (filterStatus.length) {
    rows = rows.filter((row) => filterStatus.includes(row.status));
  }
  if (globalSearch.trim() !== '') {
    const searchLower = globalSearch.trim().toLowerCase();
    rows = rows.filter((row) =>
      row.rowData.some((cell) =>
        String(cell).toLowerCase().includes(searchLower)
      )
    );
  }
  return rows.length;
};

  // ===== NEW: New columns definition for full sheet view =====
  const fullSheetColumns = useMemo(() => {
    // Add a new status column as the first column.
    const baseCols = [
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params) => (
          <Chip
            label={params.value}
            color={statusColorMap[params.value] || statusColorMap.DEFAULT}
          />
        ),
      },
      {
        field: 'originalRow',
        headerName: 'Orig Row',
        width: 100,
        renderCell: (params) => params.value ?? '-',
      },
      {
        field: 'updatedRow',
        headerName: 'Upd Row',
        width: 100,
        renderCell: (params) => params.value ?? '-',
      },
    ];
    // Then one column per Excel column (A, B, C, …) determined by the header.
    const maxCols = diffData.header.length;


  const excelCols = [];
  for (let i = 0; i < maxCols; i++) {
    excelCols.push({
      field: String.fromCharCode(65 + i),
      headerName: String.fromCharCode(65 + i),
      flex: 1,
      renderCell: (params) => {
        // Handle MODIFIED rows with cellDiffs
        if (params.row.status === 'MODIFIED' && params.row.cellDiffs) {
          const cellContent = params.row.cellDiffs[i] || params.row.rowData[i] || '';
          
          // Check if this is a structured diff object
          if (cellContent?.type === "CELL_DIFF") {
            return (
              <span>
                {cellContent.elements.map((element, elIndex) => (
                  <span key={elIndex} style={element.style}>
                    {element.value}
                  </span>
                ))}
              </span>
            );
          }
          
          // Fallback for plain values
          return <span>{cellContent}</span>;
        }
        
        // Handle other row types
        return <span>{params.row.rowData[i] ?? ''}</span>;
      },
    });
  }
  
  return [...baseCols, ...excelCols];
}, [diffData.header]);


  //   const excelCols = [];
  //   for (let i = 0; i < maxCols; i++) {
  //     excelCols.push({
  //       field: String.fromCharCode(65 + i),
  //       headerName: String.fromCharCode(65 + i),
  //       flex: 1,
  //       renderCell: (params) => {
  //         // For MODIFIED rows, use cellDiffs if available.
  //         if (params.row.status === 'MODIFIED' && params.row.cellDiffs) {
  //           return (
  //             <span>
  //               {params.row.cellDiffs[i] ||
  //                 (params.row.rowData[i] ?? '')}
  //             </span>
  //           );
  //         }
  //         // Otherwise, show the cell value.
  //         return <span>{params.row.rowData[i] ?? ''}</span>;
  //       },
  //     });
  //   }
  //   return [...baseCols, ...excelCols];
  // }, [diffData.header]);



  // In ComparisonView.js

// ===== NEW: Apply filtering (by status and global search) for the table view =====


// ===== NEW: Export CSV functionality (if needed) =====
// const handleExportCSV = () => {
//   const exportRows = [];
//   Object.entries(safeData).forEach(([sheetName, sheetData]) => {
//     if (typeof sheetData !== 'object') return;
//     const isFilteringActive = filterStatus.length > 0 || globalSearch.trim() !== '';
//     let rows = sheetData.diffRows.filter((row) => row.status !== 'UNCHANGED')
      
//     if (filterStatus.length) {
//       rows = rows.filter((row) => filterStatus.includes(row.status));
//     }
//     if (globalSearch.trim() !== '') {
//       const searchLower = globalSearch.trim().toLowerCase();
//       rows = rows.filter((row) =>
//         row.rowData.some((cell) =>
//           String(cell).toLowerCase().includes(searchLower)
//         )
//       );
//     }
//     rows.forEach((row) => {
//       exportRows.push({
//         Sheet: sheetName,
//         Status: row.status,
//         OriginalRow: row.originalRow,
//         UpdatedRow: row.updatedRow,
//         Data: row.rowData.join(' | '),
//       });
//     });
//   });
//   if (exportRows.length === 0) {
//     alert('No data to export based on current filters.');
//     return;
//   }
//   const csv = Papa.unparse(exportRows);
//   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//   const url = URL.createObjectURL(blob);
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', 'comparison_data.csv');
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };
const handleExportCSV = () => {
  const exportRows = [];
  Object.entries(safeData).forEach(([sheetName, sheetData]) => {
    if (typeof sheetData !== 'object') return;
    const isFilteringActive = filterStatus.length > 0 || globalSearch.trim() !== '';
    let rows = sheetData.diffRows.filter((row) => row.status !== 'UNCHANGED')
     
    // let rows = isFilteringActive 
    //   ? sheetData.diffRows.filter((row) => row.status !== 'UNCHANGED')
    //   : sheetData.diffRows;
    if (filterStatus.length) {
      rows = rows.filter((row) => filterStatus.includes(row.status));
    }
    if (globalSearch.trim() !== '') {
      const searchLower = globalSearch.trim().toLowerCase();
      rows = rows.filter((row) =>
        row.rowData.some((cell) =>
          String(cell).toLowerCase().includes(searchLower)
        )
      );
    }
    rows.forEach((row) => {
      let oldValue = '';
      let newValue = '';
      if (row.status === 'ADDED_ROW') {
        // No old value for added rows
        newValue = row.rowData.join(' | ');
      } else if (row.status === 'REMOVED_ROW') {
        // No new value for removed rows
        oldValue = row.rowData.join(' | ');
      } else if (row.status === 'MODIFIED') {
        // Use the stored oldData and newData if available
        oldValue = row.oldData ? row.oldData.join(' | ') : '';
        newValue = row.newData ? row.newData.join(' | ') : row.rowData.join(' | ');
      } else if (row.status === 'REORDER') {
        // For reorder rows, both values are the same
        oldValue = row.rowData.join(' | ');
        newValue = row.rowData.join(' | ');
      } else {
        // Default case (e.g. unchanged, if you include them)
        oldValue = row.rowData.join(' | ');
        newValue = row.rowData.join(' | ');
      }
      exportRows.push({
        Sheet: sheetName,
        Status: row.status,
        OriginalRow: row.originalRow,
        UpdatedRow: row.updatedRow,
        "Old Value": oldValue,
        "New Value": newValue,
      });
    });
  });
  if (exportRows.length === 0) {
    alert('No data to export based on current filters.');
    return;
  }
  const csv = Papa.unparse(exportRows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'comparison_data.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


  return (
    <Box p={4}>
      {/* Top Summary Section */}
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          marginBottom: 4,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={2}
        >
          <Typography variant="h5" gutterBottom>
            Comparison Summary
          </Typography>
          <Button
            variant="outlined"
            startIcon={<DownloadRounded />}
            onClick={handleExportCSV}
          >
            Export CSV
          </Button>
        </Box>

        {/* Global Search */}
        <Box mb={2}>
          <TextField
            label="Global Search"
            variant="outlined"
            value={globalSearch}
            onChange={(e) => setGlobalSearch(e.target.value)}
            fullWidth
          />
        </Box>

        {/* Overall Summary */}
        <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            <strong>Total Differences:</strong>{' '}
            <span
              style={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: '#d32f2f',
              }}
            >
              {totalDifferences}
            </span>
          </Typography>
          {/* Status Summaries (only for differences; unchanged is excluded) */}
          <Box display="flex" flexWrap="wrap" gap={1}>
            {allStatuses.map((status) => (
              <Chip
                key={status}
                label={`${status} (${differencesPerStatus[status] || 0})`}
                color={statusColorMap[status] || statusColorMap.DEFAULT}
                variant={
                  filterStatus.includes(status) ? 'filled' : 'outlined'
                }
                onClick={() => {
                  if (filterStatus.includes(status)) {
                    setFilterStatus(
                      filterStatus.filter((s) => s !== status)
                    );
                  } else {
                    setFilterStatus([...filterStatus, status]);
                  }
                }}
                clickable
              />
            ))}
          </Box>
        </Box>
      </Paper>

      {!hasActualData && (
        <Typography variant="body2" color="textSecondary">
          No data available.
        </Typography>
      )}

      {hasActualData && (
        <Box sx={{ mt: 4 }}>
          {/* Tabs for each sheet with dynamic counts */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ marginBottom: 2 }}
          >
            {Object.keys(safeData).map((sheetName, index) => (
              <Tab
                key={sheetName}
                label={`${sheetName} (${getFilteredCount(sheetName)} Diffs)`}
                value={index}
              />
            ))}
          </Tabs>

          {/* Full Sheet Diff Table */}
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGridPro
              rows={filteredDiffRows.map((row, idx) => ({
                id: idx,
                ...row,
              }))}
              columns={fullSheetColumns}
              disableSelectionOnClick
              pagination={false}
              rowHeight={50}
              headerHeight={50}
              getRowClassName={(params) => {
                if (params.row.status === 'ADDED_ROW') return 'added-row';
                if (params.row.status === 'REMOVED_ROW')
                  return 'removed-row';
                if (params.row.status === 'REORDER') return 'reorder-row';
                if (params.row.status === 'MODIFIED')
                  return 'modified-row';
                return '';
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
