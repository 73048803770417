import React, { useState } from 'react';
import { Box, Switch, Typography, Tooltip, Tab, Tabs } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RateObjectComponent from './RateItem';

import useAutoScrollToHash from "../useAutoScrollToHash";

import { useSettingsContext } from "../../../contexts/SettingsContext";

import AttributeListBox from "../../Components/AttributeListBox";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import { Divider } from "@mui/material";
import { classesExamples, settingIds, settingTooltips } from "../CommonVariables";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import "../studioSettings.scss";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import { Skeleton } from "@mui/material";
import TieredItemManager from '../../Components/TieredItemManager.js';
import RateManager from './RateForm.js';
import DiscountRateManager from './DiscountRateForm.js';
import { db } from '../../../firebase.js';
import { doc, setDoc, deleteDoc, getDoc } from "firebase/firestore";
import { API } from 'aws-amplify';
import { getFirebaseJwtToken } from '../../../utility-functions/tokenUtility.js';
import { getAllStudioFromAllPresets } from '../../../services/studio.services.js';
import SettingsFormContainer from '../../../components/SettingsForm/SettingsFormContainer.jsx';
import SettingsFormInput from '../../../components/SettingsForm/SettingsFormInput.jsx';
import NegativeOneToggleInput from '../../Components/NegativeOneToggleInput.jsx';

export default function SalesSettings() {

  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const [salesSettings, setSalesSettings] = useState(settings[getUrlVariableValue("settingsId")]?.salesSettings || false);
  const [activeTab, setActiveTab] = useState(0);

  const handleToggle = async (event) => {
    const userUID = getUID();
    setSalesSettings({
      ...salesSettings,
      on: event.target.checked,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "salesSettings-on",
      settings[getUrlVariableValue("settingsId")].salesSettings["on"],
      event.target.checked
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_SALES_SETTINGS_ON",
      settingsId: getUrlVariableValue("settingsId"),
      uid: userUID,
      key: "on",
      value: event.target.checked,
    });

  };

  const handleOnHistoricalDataPullChange = async (event) => {
    const userUID = getUID();
    const salesDataDocRef = doc(db, "5-year-sales-data", userUID);

    dispatch({
      type: "UPDATE_SALES_SETTINGS_HISTORICAL_DATA_PULL",
      settingsId: getUrlVariableValue("settingsId"),
      uid: userUID,
      key: "historicalDataPull",
      value: event.target.checked,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      "salesSettings-historicalDataPull",
      settings[getUrlVariableValue("settingsId")].salesSettings["historicalDataPull"],
      event.target.checked
    );
    setChanges(newRev);


    try {
      if (event.target.checked) {
        // Check if the UID is already present in the collection
        const docSnap = await getDoc(salesDataDocRef);
        if (!docSnap.exists()) {
          // Add the UID to the collection if not present
          await setDoc(salesDataDocRef, { uid: userUID });

          // Retrieve Firebase JWT Token
          const token = await getFirebaseJwtToken();


          let allStudios = await getAllStudioFromAllPresets(userUID);


          allStudios.forEach(async (studio) => {
            console.log('Step Function started for: ', studio);

            // Set up the API call with JWT token in the headers
            const apiName = 'paywellAPIResource';
            const path = '/startStepFunctionExecution'; // Adjusted API path for Lambda invocation
            const myInit = {
              headers: {
                'Authorization': `Bearer ${token}`
              },
              body: {
                uid: userUID,
                studio
              },
            };

            // Call the API to trigger the Step Function with necessary parameters
            const response = await API.post(apiName, path, myInit);

            console.log('Step Function triggered:', response);


          })


        }
      } else {
        // Remove the UID from the collection if the toggle is turned off
        await deleteDoc(salesDataDocRef);
      }
    } catch (error) {
      console.error("Error updating sales data collection or triggering step function:", error);
    }
  };



  const handleChangeBucket = (event, newValue) => {
    setActiveTab(newValue);
  };

  useAutoScrollToHash((tabId) => {
    const bucketsObject = settings[getUrlVariableValue("settingsId")]?.salesSettings?.salesBuckets;
    if (!bucketsObject) return; // bucketsObject will be undefined until settings loads

    const bucketKeys = sortBucketKeys(bucketsObject).map(key => key.toLowerCase());
    const tabIndex = bucketKeys.indexOf(tabId.toLowerCase());
    if (tabIndex === -1) {
      console.warn(`No tab with id "${tabId}"`)
      return;
    }
    setActiveTab(tabIndex);
  });

  const sortBucketKeys = (buckets) => {
    const agreementTrue = [];
    const retailTrue = [];
    const others = [];

    Object.keys(buckets).forEach(key => {
      const bucket = buckets[key];
      if (bucket.type === "Agreement" && bucket.implicitType) {
        agreementTrue.push(key);
      } else if (bucket.type === "Retail" && bucket.implicitType) {
        retailTrue.push(key);
      } else {
        others.push(key);
      }
    });

    // Sorting non-priority buckets alphabetically
    others.sort();
    // Concatenating arrays to ensure the right order
    return [...agreementTrue, ...retailTrue, ...others];
  }


  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {/* {
        "Sales " + settings[getUrlVariableValue("settingsId")].generalSettings
          .commissionName
      }
       */}
        Sales Settings
      </Typography>
      <Typography variant="p" className="settings-page__title-description">
        {/* <Link href=""><VideocamIcon/>Tutorial</Link> */}
        {`Set up rules for your commission buckets, such as rates, classifications, hierarchies, and more.`}
      </Typography>
      <Divider className="settings-page__divider" />

      <SettingsFormContainer>

        <SettingsFormInput 
          label="Enable Sales Settings"
          inputElement={
            <Switch
              id={"on"}
              checked={settings[getUrlVariableValue("settingsId")].salesSettings.on}
              onChange={handleToggle}
              name="enableSales"
              inputProps={{ 'aria-label': 'Enable sales settings' }}
            />
          }
          tooltip={settingTooltips?.salesSettings?.on}
          id={settingIds?.salesSettings?.on}
        />

        <SettingsFormInput 
          label="Enable Sales Settings Historical Data Pull"
          inputElement={
            <Switch
              id={"historicalDataPull"}
              checked={settings[getUrlVariableValue("settingsId")].salesSettings.historicalDataPull}
              onChange={handleOnHistoricalDataPullChange}
              name="enableSales"
              inputProps={{ 'aria-label': 'Enable historical data pull' }}
            />
          }
          tooltip={settingTooltips?.salesSettings?.enableHistoricalSalesLookup}
          id={settingIds?.salesSettings?.enableHistoricalSalesLookup}
        />

        <>
          <Tabs value={activeTab} onChange={handleChangeBucket} aria-label="Sales bucket tabs">
            {sortBucketKeys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets).map((key, index) => (
              <Tab label={key} key={index} />
            ))}
          </Tabs>
          <SalesBucketSettings
            key={"tab-" + activeTab}
            bucketName={sortBucketKeys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets)[activeTab]}
            track={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[sortBucketKeys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets)[activeTab]].trackPrevious}
            salesSettings={settings[getUrlVariableValue("settingsId")].salesSettings}
            setSalesSettings={setSalesSettings}
          />
        </>
      </SettingsFormContainer>

    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}


function SalesBucketSettings({ bucketName, track, salesSettings, setSalesSettings }) {

  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const staffOptions = [
    ...Object.entries(settings.staff.staff).map((s) => s[1].name),
  ];

  const [trackPrevious, setTrackPrevious] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].trackPrevious
  );

  const [sessionConversion, setSessionConversion] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversion
  );

  const [unpaid, setUnpaid] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].unpaidItemOptions
  );

  const [reactivation, setReactivation] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].reactivationOptions
  );

  const [distinctRates, setDistinctRates] = useState(
    settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].distinctRates
  );

  // const handleImplicitTypeChange = (bucketName, value, salesSettings, setSalesSettings) => {
  //   const bucketType = salesSettings.salesBuckets[bucketName].type;
  //   const newSalesSettings = { ...salesSettings };

  //   // Set implicitType for the current bucket
  //   newSalesSettings.salesBuckets[bucketName].implicitType = value;

  //   // Unset implicitType for all other buckets of the same type
  //   Object.keys(newSalesSettings.salesBuckets).forEach(key => {
  //     if (newSalesSettings.salesBuckets[key].type === bucketType && key !== bucketName) {
  //       newSalesSettings.salesBuckets[key].implicitType = false;
  //     }
  //   });

  //   // Update state and context
  //   setSalesSettings(newSalesSettings);
  //   let newRev = new RevertList(changes.getValues());
  //   newRev.addElement(
  //     bucketName + "-implicitType",
  //     settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].implicitType,
  //     value
  //   );
  //   setChanges(newRev);
  //   dispatch({
  //     type: "UPDATE_SALES_SETTINGS_WITH_KEY",
  //     settingsId: getUrlVariableValue("settingsId"),
  //     uid: getUID(),
  //     salesType: bucketName,
  //     key: 'implicitType',
  //     value: value,
  //   });
  // };

  const handleBucketUpdate = (value, id) => {
    if (id === 'implicitType') {
      //handleImplicitTypeChange(bucketName, value, settings[getUrlVariableValue("settingsId")].salesSettings, setSalesSettings);
      //return;
    } else if (id === 'trackPrevious') {
      setTrackPrevious(value);
    } else if (id === 'sessionConversion') {
      setSessionConversion(value);
    } else if (id === 'unpaidItemOptions') {
      setUnpaid(value);
    } else if (id === 'reactivationOptions') {
      setReactivation(value);
    } else if (id === "rates-single") {
      id = "rates";
      value = { "New": value };
    } else if (id === "distinctRates") {
      let rates = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rates;
      const rateValues = value.reduce((acc, key) => {
        acc[key] = rates[key] || {
          rateType: "Fixed",
          rate: 0,
          setupFeeIncluded: false,
          split: true,
          salespeople: ["Close"]
        };
        return acc;
      }, {});

      setDistinctRates(value);
      dispatch({
        type: "UPDATE_SALES_SETTINGS_WITH_KEY",
        settingsId: getUrlVariableValue("settingsId"),
        uid: getUID(),
        salesType: bucketName,
        key: 'rates',
        value: rateValues,
      });
    } else if (id === "discountPercentages-thresholds") {
      id = "discountPercentages";

      let discounts = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName][id];

      // Create a new discounts object based on the incoming value array
      const newDiscounts = value.reduce((acc, key) => {
        //const decimalKey = (parseFloat(key) / 100).toFixed(2);
        acc[key] = discounts[key] || {
          rateType: "Fixed",
          rate: 0,
          setupFeeIncluded: false,
          split: true,
          salespeople: ["Close"]
        };
        return acc;
      }, {});

      value = newDiscounts;
    } else if (id === "unpaidItemsHierarchy") {
      id = "hierarchy";
      let hierarchy = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].hierarchy;
      hierarchy["0"] = value;
      value = hierarchy;
    }
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      bucketName + "-" + id,
      settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[
      bucketName
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_SALES_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      salesType: bucketName,
      key: id,
      value: value,
    });
  };

  const getAllItemOptions = (bucketName) => {
    let buckets = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets;
    let bucket = buckets[bucketName];
    let items = bucket.itemNames;
    let allItems = [...items];

    let bucketNames = Object.keys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets);
    for (let i = 0; i < bucketNames.length; i++) {
      if (bucketNames[i] !== bucketName) {
        allItems.push("*" + bucketNames[i] + "*");
      }
    }
    return allItems;
  }

  return (
    <SettingsFormContainer style={{marginTop: '18px'}}>

      <SettingsFormInput 
        label="Type:"
        inputElement={
          <SelectorBox
            label="Type"
            className="mw-200"
            options={["Agreement", "Retail"]}
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].type}
            onChange={handleBucketUpdate}
            id="type"
            key={bucketName + "-type"}
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.type}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.type}
      />

      <SettingsFormInput 
        label="Implicit Type: "
        inputElement={
          <BooleanToggleElement
            id="implicitType"
            key={bucketName + "-implicit"}
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].implicitType}
            onChange={handleBucketUpdate}
            label="Implicit Type"
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.implicitType}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.implicitType}
      />

      <SettingsFormInput 
        label="Sold Items: "
        inputElement={
          <AttributeListBox
            className="w-max-400"
            key={bucketName + "-names"}
            multiple={true}
            freeSolo
            onChange={handleBucketUpdate}
            id="itemNames"
            default={
              settings[getUrlVariableValue("settingsId")].salesSettings
                .salesBuckets[bucketName].itemNames
            }
            limitTags={8}
            label=""
            options={classesExamples}
          ></AttributeListBox>
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.itemNames}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.itemNames}
      />

      <SettingsFormInput 
        label="Staff Names Paid For This Bucket: "
        inputElement={
          <AttributeListBox
            key={bucketName + "-staffNamesPaidForThisBucket"}
            className="mw-200"
            multiple={true}
            freeSolo
            onChange={handleBucketUpdate}
            id="staffNamesPaidForThisBucket"
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].staffNamesPaidForThisBucket}
            label="Staff Names"
            limitTags={8}
            options={staffOptions} // Predefined staff names
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.staffNamesPaidForThisBucket}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.staffNamesPaidForThisBucket}
      />

      <SettingsFormInput 
        label="Staff Types Paid For This Bucket: "
        inputElement={
          <AttributeListBox
            key={bucketName + "-staffTypesPaidForThisBucket"}
            className="mw-200"
            multiple={true}
            freeSolo
            onChange={handleBucketUpdate}
            id="staffTypesPaidForThisBucket"
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].staffTypesPaidForThisBucket}
            label="Staff Types"
            limitTags={8}
            options={Object.values(settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes).map((type) => type.name)} // Predefined staff types
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.staffTypesPaidForThisBucket}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.staffTypesPaidForThisBucket}
      />

      {trackPrevious ? (
        <SettingsFormInput 
          label="Rates: "
          inputElement={
            <RateManager
              key={bucketName + "-rates-multi"}
              id="rates"
              bucketName={bucketName}
              rates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rates}
              setRates={handleBucketUpdate}
              distinctRates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].distinctRates}
              rateCustomInput={false}
              rateOverrides={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rateOverrides || {}}
              tiers={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].hierarchy}
            />
          }

          tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.rates}
          id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.rates}
        />
      ) : (
        <Box className={"settings-page__box settings-page__box--inline"}> {/* This won't be converted to a SettingsFormInput because it renders fine as is, and is more complicated than most other settings form inputs */}
          <Typography id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.rates} className="settings-page__text__full-width">
            <Tooltip arrow title={<span style={{ fontSize: '.8em', padding: '0', margin: '0' }}>{settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.rates}</span>}>
              <InfoOutlinedIcon sx={{opacity: .5, fontSize: '1.3em', marginRight: '5px', marginBottom: '1px'}}/>{'  '}{"Rate: "}
            </Tooltip>
          </Typography>

          <AttributeListBox
            key={bucketName + "-unpaidItemsHierarchy"}
            multiple={true}
            freeSolo
            onChange={handleBucketUpdate}
            id="unpaidItemsHierarchy"
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].hierarchy[0]}
            label="Unpaid Items"
            limitTags={8}
            options={getAllItemOptions(bucketName)} // Predefined staff types
          />
          <RateObjectComponent
            on={true}
            key={bucketName + "-rates-single"}
            id="rates-single"
            bucketName={bucketName}
            rateName="New"
            rate={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rates["New"]}
            onRateChange={handleBucketUpdate}
          />
        </Box>
      )}

      {trackPrevious && (
        <SettingsFormInput 
          label="Manage Item Tiers"
          inputElement={
            <TieredItemManager
              key={bucketName + "-hierarchy"}
              className="w-max-400"
              id="hierarchy"
              noLabel
              buckets={Object.keys(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets)}
              initialTiers={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].hierarchy}
              options={getAllItemOptions(bucketName)}
              onChange={handleBucketUpdate}
            />
          }
          tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.hierarchy}
          id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.hierarchy}
        />)}


      <SettingsFormInput 
        label="Discounted Items: "
        inputElement={
          <DiscountRateManager
            key={bucketName + "-discountPercentages"}
            id="discountPercentages"
            rateName="Discounted"
            bucketName={"Discounted " + bucketName}
            rates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].discountPercentages}
            setRates={handleBucketUpdate}
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.discountPercentages}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.discountPercentages}
      />

      <SettingsFormInput 
        label="Pay Commission on Staff Bought Items: "
        inputElement={
          <BooleanToggleElement
            id="commissionStaffBought"
            key={bucketName + "-commissionStaffBought"}
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].commissionStaffBought || false}
            onChange={handleBucketUpdate}
            label="Staff Purchases"
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.commissionStaffBought}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.commissionStaffBought}
      />

      {/* <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">{"Rate Name Overrides: "}</Typography>
        <RateOverrideManager 
          key={bucketName + "-rateOverrides"}
          id="rateOverrides"
          rateOverrides={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].rateOverrides || {}} 
          setRateOverrides={handleBucketUpdate}
          distinctRates={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].distinctRates}
        />
      </Box> */}

      <SettingsFormInput 
        label="Unpaid Items: "
        inputElement={
          <SelectorBox
            key={bucketName + "-unpaidItemOptions"}
            className="mw-200"
            id="unpaidItemOptions"
            label="Unpaid Items"
            default={unpaid}
            onChange={handleBucketUpdate}
            options={["Pay as Normal", "Do Not Pay", "Pay a Different Rate"]}
            fullWidth
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.unpaidItemOptions}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.unpaidItemOptions}
      />

      {unpaid === "Pay a Different Rate" && (
      <SettingsFormInput 
        label="Unpaid Item Rate: "
        inputElement={
          <RateObjectComponent
            on={unpaid === "Pay a Different Rate"}
            key={bucketName + "-unpaidItemRate"}
            id="unpaidItemRate"
            bucketName={bucketName}
            rateName="Unpaid"
            rate={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].unpaidItemDifferentRate}
            onRateChange={(rate) => handleBucketUpdate(rate, 'unpaidItemDifferentRate')}
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.unpaidItemDifferentRate}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.unpaidItemDifferentRate}
      />)}

      {trackPrevious && (
        <SettingsFormInput 
          label="Multiple Previous Sales: "
          inputElement={
            <SelectorBox
              className="mw-200"
              key={bucketName + "-multiplePreviousAgreementsPayment"}
              options={["Do Not Pay", "Disregard all previous agreements", "Disregard all but last previous agreement", "Disregard cross-bucket sales", "Take into account all previous agreements & use highest tier agreement for base", "Take into account all previous agreements & let PayWell AI decided if its commissionable"]}
              default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].multiplePreviousAgreementsPayment}
              onChange={handleBucketUpdate}
              label="Multiple Previous Sales"
              id="multiplePreviousAgreementsPayment"
            />
          }
          tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.multiplePreviousAgreementsPayment}
          id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.multiplePreviousAgreementsPayment}
        />
      )}

      {trackPrevious && (
        <SettingsFormInput 
          label="Reactivation Options: "
          inputElement={
            <SelectorBox
              className="w-max-400"
              key={bucketName + "-reactivationOptions"}
              options={["Disregard length between purchases", "Regard as new if length is less than reactivationTimeFrame", "Regard as unique 'Returning' type if length is less than reactivationTimeFrame"]}
              default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].reactivationOptions}
              onChange={handleBucketUpdate}
              label="Reactivation Options"
              id="reactivationOptions"
            />
          }
          tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.reactivationOptions}
          id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.reactivationOptions}
        />
      )}

      {reactivation !== "Disregard length between purchases" && (
        <SettingsFormInput 
          label="Do you want to set a reactivation time frame? If so, how many days:"
          inputElement={
            <NegativeOneToggleInput
              value={Number(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].reactivationTimeFrame) || -1}
              onChange={(value) => handleBucketUpdate(value, "reactivationTimeFrame")}
              inputLabel="Days"
              id="reactivationTimeFrame"
            />
          }
          tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.reactivationTimeFrame}
          id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.reactivationTimeFrame}
        />
      )}

      {/* <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
      <Typography className="settings-page__text">{"Post Dated Sale Options: "}</Typography>
      <SelectorBox
        key={bucketName + "-postDatedSaleOptions"}
        id="postDatedSaleOptions"
        label="Post Dated Sale Options"
        value={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].postDatedSaleOptions}
        onChange={(e) => handleBucketUpdate(e.target.value, 'postDatedSaleOptions')}
        options={["Pay as Normal", "Do Not Pay", "Pay on Post Dated Sale Day"]}
        fullWidth
      />
      </Box> */}

      <SettingsFormInput 
        label="Session Conversion: "
        inputElement={
          <BooleanToggleElement
            id="sessionConversion"
            key={bucketName + "-sessionConversion"}
            default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversion}
            onChange={handleBucketUpdate}
            label="Session Conversion"
          />
        }
        tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversion}
        id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversion}
      />

      {sessionConversion && (
        [

          <SettingsFormInput 
            label="Do you want to set a session conversion time frame? If so, how many hours:"
            inputElement={
              <NegativeOneToggleInput
                value={Number(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversionTime) || -1}
                onChange={(value) => handleBucketUpdate(value, "sessionConversionTime")}
                inputLabel="Hours"
                id="sessionConversionTime"
              />
            }
            tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversionTime}
            id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversionTime}
          />,

          <SettingsFormInput 
            label="Session Conversion Buckets: "
            inputElement={
              <AttributeListBox
                className="mw-200"
                key={bucketName + "-sessionConversionClassBuckets"}
                multiple={true}
                freeSolo
                onChange={handleBucketUpdate}
                id="sessionConversionClassBuckets"
                default={settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversionClassBuckets}
                label="Class Buckets for Conversion"
                options={Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets)}  // Make sure to provide the correct options
              />
            }
            tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversionClassBuckets}
            id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversionClassBuckets}
          />,

          <SettingsFormInput 
            label="Do you want to give instructors a bonus for session conversions? If so, how much:"
            inputElement={
              <NegativeOneToggleInput
                value={Number(settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets[bucketName].sessionConversionInstructorBonus) || -1}
                onChange={(value) => handleBucketUpdate(value, "sessionConversionInstructorBonus")}
                inputLabel="Bonus Amount ($USD)"
                id="sessionConversionInstructorBonus"
              />
            }
            tooltip={settingTooltips?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversionInstructorBonus}
            id={settingIds?.salesSettings?.salesBuckets?.sharedBucketProperties?.sessionConversionInstructorBonus}
          />
      ])}


    </SettingsFormContainer>
  );
}
