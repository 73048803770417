// excel-comparison-utils.js
import React, {  } from 'react';
import XLSX from '@sheet/core'; // same as used in your OutputTest.js

// ===== UPDATED: LCS algorithm for row alignment =====
function computeLCS(orig, upd) {
  const m = orig.length, n = upd.length;
  // Initialize dp table.
  const dp = Array(m + 1)
    .fill(null)
    .map(() => Array(n + 1).fill(0));
  // Build dp table: if a row pair differs in fewer than 3 cells, consider them matching.
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (countCellDifferences(orig[i - 1], upd[j - 1]) < 3) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }
  // Backtracking to extract aligned indices.
  let i = m, j = n;
  const common = [];
  while (i > 0 && j > 0) {
    if (countCellDifferences(orig[i - 1], upd[j - 1]) < 3) {
      common.unshift({ origIndex: i - 1, updIndex: j - 1 });
      i--;
      j--;
    } else if (dp[i - 1][j] >= dp[i][j - 1]) {
      i--;
    } else {
      j--;
    }
  }
  return common;
}

// ===== NEW: Helper to compare two rows cell‐by‐cell and count differences =====
function countCellDifferences(rowOrig, rowUpd) {
  const len = Math.max(rowOrig.length, rowUpd.length);
  let diffCount = 0;
  for (let i = 0; i < len; i++) {
    const a = rowOrig[i] ?? '';
    const b = rowUpd[i] ?? '';
    if (a !== b) diffCount++;
  }
  return diffCount;
}

// ===== UPDATED: Render a cell’s diff (for modified rows with < 3 changes) =====
function renderCellDiff(a, b) {
  return {
    type: "CELL_DIFF",
    oldValue: a ?? '',
    newValue: b ?? '',
    elements: [
      { 
        type: "old", 
        value: a ?? '', 
        style: { backgroundColor: 'rgba(244, 67, 54, 0.3)' } 
      },
      { 
        type: "separator", 
        value: "→", 
        style: { margin: '0 4px' } 
      },
      { 
        type: "new", 
        value: b ?? '', 
        style: { backgroundColor: 'rgba(76, 175, 80, 0.3)' } 
      }
    ]
  };
}



// ===== MAIN: Full-sheet diff generator =====
export function generateFullSheetDiff(sheetOrig, sheetUpd) {
  // Convert sheets to array-of-arrays (including header)
  const origData = sheetToArrayOfArrays(sheetOrig);
  const updData = sheetToArrayOfArrays(sheetUpd);

  // Assume first row is header – store it separately.
  const header = updData[0] || [];
  const origRows = origData.slice(1);
  const updRows = updData.slice(1);

  // Compute LCS alignment on rows using our updated equality criterion.
  const common = computeLCS(origRows, updRows);
  const diffRows = [];

  let o = 0, u = 0;
  // Walk through the aligned sequences.
  common.forEach(pair => {
    // Process any rows in original that are missing in updated (removals)
    while (o < pair.origIndex) {
      diffRows.push({
        status: 'REMOVED_ROW',
        originalRow: o + 2, // account for header and 1-indexing
        updatedRow: null,
        rowData: origRows[o],
      });
      o++;
    }
    // Process any rows in updated that are missing in original (additions)
    while (u < pair.updIndex) {
      diffRows.push({
        status: 'ADDED_ROW',
        originalRow: null,
        updatedRow: u + 2,
        rowData: updRows[u],
      });
      u++;
    }
    // Now process the common (aligned) row.
    const rowOrig = origRows[pair.origIndex];
    const rowUpd = updRows[pair.updIndex];
    const diffCount = countCellDifferences(rowOrig, rowUpd);
    const isReorder = (pair.origIndex !== pair.updIndex);

    if (diffCount === 0 && isReorder) {
      // Pure reorder (no cell-level differences).
      diffRows.push({
        status: 'REORDER',
        originalRow: pair.origIndex + 2,
        updatedRow: pair.updIndex + 2,
        rowData: rowUpd,
      });
    } else if (diffCount > 0 && diffCount < 3) {
      // Single row modification (combined view).
      diffRows.push({
        status: 'MODIFIED',
        originalRow: pair.origIndex + 2,
        updatedRow: pair.updIndex + 2,
        rowData: rowUpd,
        oldData: rowOrig, // store the original row
        newData: rowUpd,  // store the updated row
        // For each cell, if unchanged, show plain value; if changed, show diff.
        cellDiffs: rowOrig.map((cell, i) => {
          const updCell = rowUpd[i] ?? '';
          return (cell === updCell) ? cell : renderCellDiff(cell, updCell);
        }),
      });
    } else if (diffCount >= 3) {
      // Split into two rows: one removal and one addition.
      diffRows.push({
        status: 'REMOVED_ROW',
        originalRow: pair.origIndex + 2,
        updatedRow: null,
        rowData: rowOrig,
      });
      diffRows.push({
        status: 'ADDED_ROW',
        originalRow: null,
        updatedRow: pair.updIndex + 2,
        rowData: rowUpd,
      });
    } else {
      // Unchanged row (or nearly identical) – show as is.
      diffRows.push({
        status: 'UNCHANGED',
        originalRow: pair.origIndex + 2,
        updatedRow: pair.updIndex + 2,
        rowData: rowUpd,
      });
    }
    o = pair.origIndex + 1;
    u = pair.updIndex + 1;
  });

  // Process any remaining rows.
  while (o < origRows.length) {
    diffRows.push({
      status: 'REMOVED_ROW',
      originalRow: o + 2,
      updatedRow: null,
      rowData: origRows[o],
    });
    o++;
  }
  while (u < updRows.length) {
    diffRows.push({
      status: 'ADDED_ROW',
      originalRow: null,
      updatedRow: u + 2,
      rowData: updRows[u],
    });
    u++;
  }

  // Post-process: merge adjacent REMOVED_ROW and ADDED_ROW diffs into a single REORDER diff.
  const enhancedDifferences = [];
  for (let idx = 0; idx < diffRows.length; idx++) {
    const current = diffRows[idx];
    if (
      current.status === "REMOVED_ROW" &&
      idx < diffRows.length - 1 &&
      diffRows[idx + 1].status === "ADDED_ROW" &&
      String(current.rowData.join(',')).trim() === String(diffRows[idx + 1].rowData.join(',')).trim()
    ) {
      const next = diffRows[idx + 1];
      enhancedDifferences.push({
        status: "REORDER",
        originalRow: current.originalRow,
        updatedRow: next.updatedRow,
        rowData: current.rowData, // They are assumed to be identical.
      });
      idx++; // Skip the next row as it is merged.
    } else {
      enhancedDifferences.push(current);
    }
  }
  return { header, diffRows: enhancedDifferences };
}


// -----------------------
// Sheet & Diff Helpers
// -----------------------

// Convert a sheet into an array of arrays.
function sheetToArrayOfArrays(sheet) {
  const result = [];
  const range = XLSX.utils.decode_range(sheet['!ref']);
  for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
    const row = [];
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
      row.push(cell ? cell.v : '');
    }
    result.push(row);
  }
  return result;
}


