// AddStaffComponent.js
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  DialogActions,
  Stack,
} from "@mui/material";

import { useStaffContext } from "../../../contexts/StaffContext/StaffContext";
import { LoadingButton } from "@mui/lab";

const AddPayTierComponent = ({ onSave, onClose, tiers }) => {
  /*** Staff Context ***/
  const {
    loading,
  } = useStaffContext();

  const [name, setName] = useState("");

  const handleSubmit = async (e) => {
    onSave(name); // Assuming onSave is a callback to handle post-save actions
  };

  return (
    <Stack spacing={3} sx={{ margin: "16px  0" }}>
      <TextField
        required
        key={'Name'}
        label={'Name'}
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        margin="dense"
        />

      <DialogActions className="add-staff-component__actions">
        <Button onClick={() => onClose()}>Cancel</Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={name.length === 0 || tiers.includes(name) || name.length > 31}
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
};

export default AddPayTierComponent;
