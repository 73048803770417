import React, { } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Stack,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import NumericTextField from "../../../components/CustomTextFields/NumericTextField";
import { formatHeaderName } from "../utils/staffHelpers";

export default function EarningsDialog({
  isOpen,
  onClose,
  earnings,
  setEarnings,
  locations,
  earningBucketName,
  earningsBucket
}) {
  if (!earningsBucket) {
    return null;
  }

  const handleAddEarning = () => {
    const { format } = earningsBucket;
    if (format === "Dollar Amount") {
      const newEarning = {
        hours: null,
        rate: null,
        amount: 0,
        location: locations[0]
      };
      setEarnings((prev) => [...prev, newEarning]);
    } else {
      const newEarning = {
        hours: 0,
        rate: 0,
        amount: null,
        location: locations[0]
      };
      setEarnings((prev) => [...prev, newEarning]);
    }
  };

  const handleRemoveEarning = (index) => {
    setEarnings((prev) => prev.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    setEarnings((prev) =>
      prev.map((item, i) => {
        if (i !== index) return item;
        // If editing 'amount', nullify hours/rate; vice versa if editing hours/rate
        if (field === "amount") {
          return { ...item, amount: value, hours: null, rate: null };
        } else if (field === "hours" || field === "rate") {
          return { ...item, [field]: value, amount: null };
        }
        return { ...item, [field]: value };
      })
    );
  };

  return (
    <Dialog
      open={isOpen}
      // Treat outside clicks as "Cancel"
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          onClose(false);
        } else {
          onClose(false);
        }
      }}
      // Make the Dialog take up full width on smaller screens, but cap at 'md' size
      fullWidth
      maxWidth="md"
      /*
        Apply responsive styling to the Paper:
          - xs: '100%'  (phones)
          - sm: '90%'
          - md: '80%'
        Provide a fallback minWidth on medium screens to avoid shrinking too small.
      */
      sx={{
        "& .MuiDialog-paper": {
          width: { xs: "100%", sm: "90%", md: "80%" },
          minWidth: { md: 600 }, // 600px minimum on medium screens and above
        },
      }}
    >
      <DialogTitle component={"h3"}>{formatHeaderName(earningBucketName)} Earnings</DialogTitle>
      <DialogContent
        /*
          Allow horizontal scrolling if table is wider than the current viewport
          so it's still usable on small screens (mobile devices).
        */
        sx={{ overflowX: "auto" }}
      >
        <DialogContentText>
          Please add {formatHeaderName(earningBucketName)}
        </DialogContentText>

        {earningsBucket.format === "Dollar Amount" ? (
          <Stack spacing={3} sx={{ margin: "16px 0" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {earnings.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <NumericTextField
                        type="number"
                        value={item.amount ?? ""}
                        onChange={(e) =>
                          handleChange(idx, "amount", parseFloat(e.target.value))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputMode: "decimal",
                          pattern: "[0-9]*",
                        }}
                        sx={{ width: 110 }}
                      />
                    </TableCell>
                    <TableCell>
                      <SelectorBox
                        onChange={(e, newValue) =>
                          handleChange(idx, "location", e)
                        }
                        id="location"
                        default={item.location || locations[0]}
                        size={300} // reduce if needed for smaller screens
                        options={locations.concat(["*Studios Worked At*"])}
                        label="Location"
                        name="Location"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRemoveEarning(idx)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={3}>
                    <Button startIcon={<AddIcon />} onClick={handleAddEarning}>
                      Add {formatHeaderName(earningBucketName)}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        ) : (
          <Stack spacing={3} sx={{ margin: "16px 0" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Hours</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {earnings.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <NumericTextField
                        type="number"
                        value={item.hours ?? ""}
                        onChange={(e) =>
                          handleChange(
                            idx,
                            "hours",
                            parseFloat(e.target.value)
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">#</InputAdornment>
                          ),
                          inputMode: "decimal",
                          pattern: "[0-9]*",
                        }}
                        sx={{ width: 110 }}
                      />
                    </TableCell>
                    <TableCell>
                      <NumericTextField
                        type="number"
                        value={item.rate ?? ""}
                        onChange={(e) =>
                          handleChange(
                            idx,
                            "rate",
                            parseFloat(e.target.value)
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputMode: "decimal",
                          pattern: "[0-9]*",
                        }}
                        sx={{ width: 110 }}
                      />
                    </TableCell>
                    <TableCell>
                      <SelectorBox
                        onChange={(e, newValue) =>
                          handleChange(idx, "location", e)
                        }
                        id="location"
                        default={item.location || locations[0]}
                        size={300}
                        options={locations.concat(["*Studios Worked At*"])}
                        label="Location"
                        name="Location"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRemoveEarning(idx)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button startIcon={<AddIcon />} onClick={handleAddEarning}>
                      Add {formatHeaderName(earningBucketName)}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button
          onClick={() => onClose(true)}
          color="primary"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// export default function EarningsDialog({ isOpen, onClose, earnings, setEarnings, locations, earningBucketName, earningsBucket }) {
//   console.log({ isOpen, onClose, earnings, setEarnings, locations, earningBucketName, earningsBucket })
//   // If for some reason earningsBucket is null or undefined, just return null or an empty fallback.
//   if (!earningsBucket) {
//     return null;
//     // or <Dialog open={isOpen} onClose={() => onClose(false)}> <DialogContent> No bucket found </DialogContent> </Dialog>
//   }


//   const handleAddEarning = () => {
//     let earningsFormat = earningsBucket.format;
//     if (earningsFormat === 'Dollar Amount') {
//       const newEarning = { hours: null, rate: null, amount: 0, location: locations[0] };
//       setEarnings([...earnings, newEarning]);
//     } else {
//       const newEarning = { hours: 0, rate: 0, amount: null, location: locations[0] };
//       setEarnings([...earnings, newEarning]);
//     }
//   };

//   const handleRemoveEarning = (index) => {
//     const updatedEarnings = earnings.filter((_, i) => i !== index);
//     setEarnings(updatedEarnings);
//   };

//   const handleChange = (index, field, value) => {
//     const updatedEarnings = earnings.map((item, i) => {
//       if (i === index) {
//         if (field === 'amount') {
//           // If amount is being edited, nullify hours and rate
//           return { ...item, amount: value, hours: null, rate: null };
//         } else if (field === 'hours' || field === 'rate') {
//           // If hours or rate is being edited, nullify amount
//           return { ...item, [field]: value, amount: null };
//         }
//         // For other fields (like location), just update normally
//         return { ...item, [field]: value };
//       }
//       return item;
//     });

//     setEarnings(updatedEarnings);
//   };

//   return (

  

// <Dialog
// open={isOpen}
// onClose={(event, reason) => {
//   if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
//     // The user clicked outside or pressed ESC => treat as "Cancel"
//     onClose(false);
//   } else {
//     // If we want to allow the top-right "X" to also be "Cancel"...
//     onClose(false);
//   }
// }}
// // 1) Disable the default fixed maxWidth
// maxWidth={false}
// // 2) Override default Paper styling to auto-size up to 90% viewport width
// sx={{
//   "& .MuiDialog-paper": {
//     width: "auto",
//     maxWidth: "90%", // or 80% — pick what works best for your layout
//     minWidth: "60%",   // <-- add a minimum width

//   },
// }}
// >
// <DialogTitle component={"h3"}>
// {earningBucketName} Earnings
// </DialogTitle>

// {/* 3) Make sure we remove or override any horizontal scroll if unneeded */}
// <DialogContent sx={{ overflowX: "visible" }}>
//   <DialogContentText>
//     Please add {earningBucketName}
//   </DialogContentText>

//   {earningsBucket.format === "Dollar Amount" ? (
//     <Stack spacing={3} sx={{ margin: "16px  0" }}>
//       <Table >
//         <TableHead>
//           <TableRow>
//             <TableCell>Amount</TableCell>
//             <TableCell>Location</TableCell>
//             <TableCell>Actions</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {earnings.map((item, index) => (
//             <TableRow key={index} className="earnings-dialog__table-row">
//               <TableCell>
//                 <TextField
//                   type="number"
//                   value={item.amount}
//                   onChange={(e) =>
//                     handleChange(index, "amount", parseFloat(e.target.value))
//                   }
//                   InputProps={{
//                     startAdornment: (
//                       <InputAdornment position="start">$</InputAdornment>
//                     ),
//                     inputMode: "decimal",
//                     pattern: "[0-9]*",
//                   }}
//                 />
//               </TableCell>
//               <TableCell>
//                 <SelectorBox
//                   onChange={(e, newValue) => handleChange(index, "location", e)}
//                   id="location"
//                   default={item.location || (locations[0] ? locations[0] : "")}
//                   size={400}
//                   options={locations.concat(["*Studios Worked At*"])}
//                   label="Location"
//                   name="Location"
//                 />
//               </TableCell>
//               <TableCell>
//                 <IconButton onClick={() => handleRemoveEarning(index)}>
//                   <DeleteIcon />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//           <TableRow>
//             <TableCell colSpan={3}>
//               <Button startIcon={<AddIcon />} onClick={handleAddEarning}>
//                 Add {earningBucketName}
//               </Button>
//             </TableCell>
//           </TableRow>
//         </TableBody>
//       </Table>
//     </Stack>
//   ) : (
//     <Stack spacing={3} sx={{ margin: "16px  0" }}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Hours</TableCell>
//             <TableCell>Rate</TableCell>
//             <TableCell>Location</TableCell>
//             <TableCell>Actions</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {earnings.map((item, index) => (
//             <TableRow key={index} className="earnings-dialog__table-row">
//               <TableCell>
//                 <TextField
//                   type="number"
//                   value={item.hours}
//                   onChange={(e) =>
//                     handleChange(index, "hours", parseFloat(e.target.value))
//                   }
//                   InputProps={{
//                     startAdornment: (
//                       <InputAdornment position="start">#</InputAdornment>
//                     ),
//                     inputMode: "decimal",
//                     pattern: "[0-9]*",
//                   }}
//                   sx={{ width: "100px" }}
//                 />
//               </TableCell>
//               <TableCell>
//                 <TextField
//                   type="number"
//                   value={item.rate}
//                   onChange={(e) =>
//                     handleChange(index, "rate", parseFloat(e.target.value))
//                   }
//                   InputProps={{
//                     startAdornment: (
//                       <InputAdornment position="start">$</InputAdornment>
//                     ),
//                     inputMode: "decimal",
//                     pattern: "[0-9]*",
//                   }}
//                   sx={{ width: "100px" }}
//                 />
//               </TableCell>
//               <TableCell>
//                 <SelectorBox
//                   onChange={(e, newValue) =>
//                     handleChange(index, "location", e)
//                   }
//                   id="location"
//                   default={
//                     item.location || (locations[0] ? locations[0] : "")
//                   }
//                   size={400}
//                   options={locations.concat(["*Studios Worked At*"])}
//                   label="Location"
//                   name="Location"
//                 />
//               </TableCell>
//               <TableCell>
//                 <IconButton onClick={() => handleRemoveEarning(index)}>
//                   <DeleteIcon />
//                 </IconButton>
//               </TableCell>
//             </TableRow>
//           ))}
//           <TableRow>
//             <TableCell colSpan={4}>
//               <Button startIcon={<AddIcon />} onClick={handleAddEarning}>
//                 Add {earningBucketName}
//               </Button>
//             </TableCell>
//           </TableRow>
//         </TableBody>
//       </Table>
//     </Stack>
//   )}
// </DialogContent>

// <DialogActions style={{ padding: "24px" }}>
//   <Button onClick={() => onClose(false)}>Cancel</Button>
//   <Button
//     onClick={() => onClose(true)}
//     color="primary"
//     variant="contained"
//   >
//     Confirm
//   </Button>
// </DialogActions>
// </Dialog>

//   )
// }

