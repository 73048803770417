// src/utility-functions/tokenManager.js
import { getAuth } from "firebase/auth";

class TokenManager {
  constructor() {
    this.cachedToken = null;
    this.tokenExpiry = null;
    this.fetchingToken = null;
  }

  async getToken() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User not authenticated");
    }

    const now = new Date();

    // If a valid token is cached, return it
    if (this.cachedToken && this.tokenExpiry && now < this.tokenExpiry) {
      return this.cachedToken;
    }

    // If a token fetch is already in progress, return the existing promise
    if (this.fetchingToken) {
      return this.fetchingToken;
    }

    // Fetch a new token and cache it
    this.fetchingToken = user.getIdTokenResult()
      .then((idTokenResult) => {
        this.cachedToken = idTokenResult.token;
        // Set expiry 5 minutes before actual expiry to account for clock skew
        this.tokenExpiry = new Date(idTokenResult.expirationTime);
        this.fetchingToken = null;
        return this.cachedToken;
      })
      .catch((error) => {
        console.error('Error fetching JWT token:', error);
        this.fetchingToken = null;
        throw error;
      });

    return this.fetchingToken;
  }
}

const instance = new TokenManager();
export default instance;