import { brands } from "../../StudioSettings/CommonVariables";
export function getDistinctSalesRates(salesBuckets)  {
  let allDistinctRates = [];
  // let salesBuckets = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets;
  for (let bucketName in salesBuckets) {
    let bucketRates = salesBuckets[bucketName].distinctRates;
    for (let j = 0; j < bucketRates.length; j++) {
      allDistinctRates.push(bucketName + "-" + bucketRates[j]);
    }
  }

  return allDistinctRates;
}

/**
 * Converts an array of strings (e.g. alternateNames) to a display string.
 * @param {string[]} valueArray
 * @returns {string} e.g. "Name1, Name2"
 */
export function attributeArrayRead(valueArray) {
    if (!valueArray || valueArray.length === 0) {
      return "-";
    }
    return valueArray.join(", ");
  }
  
  /**
   * Nicely formats an array of locations for display in the grid.
   * @param {string[]} valueArray
   * @returns {string}
   */
  export function locationsArrayRead(valueArray) {
    if (!valueArray || valueArray.length === 0) {
      return "0 Locations Selected";
    } else if (valueArray.indexOf("All Studios") > -1) {
      return "All Studios";
    } else if (valueArray.length > 3) {
      return valueArray.slice(0, 2).join(", ") + ", +" + (valueArray.length - 3);
    }
    return valueArray.join(", ");
  }

  export const formatHeaderName = (str) => {
    // Add space between lowercase followed by uppercase
    console.log(str);
    return str.replace(/([a-z])([A-Z])/g, '$1 $2')
      // Add space between uppercase followed by uppercase+lowercase
      // .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  };
  


  export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  export const attendeeFormat = (value, label) => {
    if (parseInt(value) === 1) {
      return value + " " + label + " attendee";
    } else {
      return value + " " + label + " attendees";
    }
  };

  export const percentOrValueFormat = (value) => {
    if (value === "") {
      return "-";
    } else if (value >= 1 || value <= 0) {
      return currencyFormatter.format(value);
    } else {
      return (parseFloat(value) * 100).toFixed(2) + "%";
    }
  };

  export const findBrand = (brand, studios) => {

    // If brand is not defined, find it
    if (!brand) {
      const brandList = brands;

      for (let element1 of brandList) {
        for (let element2 of studios) {
          if (element2.includes(element1)) {
            brand = element1;
            break;
          }
        }

        if (brand) {
          break;
        }
      }
    }

    return brand;
  };


  // REVIEW WITH SCOT
  export const checkBrandAndLocation = (staffBrand, staffLocation, settingsBrand, settingsStudios) => {
    if (staffBrand === "") {
          // Access the brand from the settings
      staffBrand = findBrand(settingsBrand, settingsStudios);
    }
 

    // If brand is not defined, find it THIS WILL NEVER RUN
    if (!settingsBrand) {
      const brandList = brands; // where the hell is this?

      for (let element1 of brandList) {
        for (let element2 of settingsStudios) {
          if (element2.includes(element1)) {
            settingsBrand = element1;
            break;
          }
        }

        if (settingsBrand) {
          break;
        }
      }
    }

    let brandMatch = settingsBrand === staffBrand || !settingsBrand;

    let locationMatch = staffLocation.length === 0;
    for (let i = 0; i < staffLocation.length; i++) {
      for (let j = 0; j < settingsStudios.length; j++) {
        if (
          staffLocation[i] === settingsStudios[j] ||
          staffLocation[i] === "All Studios"
        ) {
          locationMatch = true;
          break;
        }
      }
    }

    return brandMatch && locationMatch;
  };

  /**
   const attributeArrayRead = (valueArray) => {
  if (valueArray.length === 0) {
    return "-";
  }
  return valueArray.join(", ");
};

const locationsArrayRead = (valueArray) => {
  if (valueArray.length === 0) {
    return "0 Locations Selected";
  } else if (valueArray.indexOf("All Studios") > -1) {
    return "All Studios";
  } else if (valueArray.length > 3) {
    return valueArray.slice(0, 2).join(", ") + ", +" + (valueArray.length - 3);
  }
  return valueArray.join(", ");
};
   */
