import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Link, Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "../../components/AppBar/ResponsiveAppBar";
import "./staffEmailer.scss";
import StaffEmailerDropzone from "./staffEmailerDropzone";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { useSnackBar } from "../../contexts/SnackBarContext/SnackBarContext";
import { useAuth } from "../../contexts/AuthContext";
import EmailPreviewTable from "../../components/EmailPreviewTable/EmailPreviewTable";
import NoDataPlaceholder from "./NoDataPlaceholder";
import NoAccountsRoundedIcon from '@mui/icons-material/NoAccountsRounded';
import { VideoCameraBackRounded } from "@mui/icons-material";
export default function StaffEmailer(props) {
  const { getFirebaseJwtToken } = useAuth();
  const showSnackBar = useSnackBar();
  const history = useHistory();
  const { settings } = useSettingsContext();

  const [tables, setTables] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [emailCards, setEmailCards] = useState([]);
  const [emails, setEmails] = useState([]);
  const [sendAllEmailButtonDisabled, setSendAllEmailButtonDisabled] =
    useState(false);
  const [isEmailsLoaded, setIsEmailsLoaded] = useState(false);

  // Zero earnings alert
  const [showZeroEarningsWarning, setShowZeroEarningsWarning] = useState(false);

  useEffect(() => {
    if (
      isEmailsLoaded &&
      emailCards.length > 0 &&
      emailCards.every((card) => card.zeroEarnings)
    ) {
      setShowZeroEarningsWarning(true);
    } else {
      setShowZeroEarningsWarning(false);
    }
  }, [isEmailsLoaded, emailCards]);

  // Functions remain the same
  const addEmailCard = (newEmail) => {

    setEmailCards((prev) => prev.concat(newEmail));
  };

  // const addEmail = (newEmail, to, cc, subject, email) => {

  //   setEmails((prev) => prev.concat({ email: newEmail, to, cc, subject, ...email }));
  // };

  const addEmail = (payload, to, cc, subject, emailMeta) => {
    // emailMeta is your local object for preview
    setEmails((prev) => prev.concat({
      ...payload,
      // Just for convenience, store these extra fields if needed:
      staffEmail: emailMeta.staffEmail,
      zeroEarnings: emailMeta.zeroEarnings,
    }));
  };


  const sendEmail = async (email, showSnackBarMessage = true) => {
    if (email?.zeroEarnings) {
      console.warn(
        "The following email was not sent because the recipient had zero earnings during the pay period.",
        email
      );
      return;
    }
    const token = await getFirebaseJwtToken();

    const myAPI = "paywellAPIResource";
    const path = "/email";

    const event = {
      headers: { Authorization: `Bearer ${token}` },
      body: {
        to: email.to,
        cc: email.cc,
        subject: email.subject,
        employeeName: email.employeeName,
        location: email.location,
        payPeriod: email.payPeriod,
        customEmailTextContent: email.customEmailTextContent,
        payCheckAmount: email.payCheckAmount,
        // typedRows: email.typedRows,
        sections: email.sections,
      },
    };

    let res;
    await API.post(myAPI, path, event)
      .then((response) => {
        if (response.success === true) {
          if (showSnackBarMessage)
            showSnackBar(`Email sent to ${email.to}`, "success");
        } else {
          // if (showSnackBarMessage)
          //   showSnackBar(`Failed sending email to ${email.to}`, "error");
        }
        res = response;
      })
      .catch((error) => {
        console.error(error);
        res = error;
        showSnackBar(`Failed sending email to ${email.to}`, "error");
      });
    return res;
  };


  const sendAllEmails = async (allEmails) => {
    let allSuccessful = true;

    for (const email of allEmails) {
      // Log the email to verify zeroEarnings is false
      if (!email.zeroEarnings) {
        console.log(`Sending email to: ${email.to}, zeroEarnings: ${email.zeroEarnings}`);
        try {
          await sendEmail(email, false); // Assuming sendEmail is a promise-based function
        } catch (error) {
          console.error(`Failed to send email to: ${email.to}`, error);
          allSuccessful = false;
        }
      } else {
        console.log(`Skipping email to: ${email.to}, zeroEarnings: ${email.zeroEarnings}`);
      }
    }

    if (allSuccessful) {
      showSnackBar(`All emails sent successfully`, "success");
    } else {
      showSnackBar(`Failed to send some emails`, "error");
    }

    setSendAllEmailButtonDisabled(true);
    setTimeout(() => {
      setSendAllEmailButtonDisabled(false);
    }, 30000);
  };






  return (
    <>
      <ResponsiveAppBar hideMenu={true} showUser={true} />
      <Box className="page">
        <Box className="staff-emailer">
          <Typography variant="h2" className="settings-page__title">
            Email Staff Paystubs
          </Typography>
          <Typography variant="p" className="settings-page__title-description">
            <Link href="/resources/Emailing%20Pay%20Stubs"><VideoCameraBackRounded />Tutorial</Link>
            {` -  Upload your PayWell output file, preview staff paystubs, and send!`}
          </Typography>

          {settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
            <Box className="staff-emailer__content">
              {/* LEFT COLUMN: Dropzone */}
              <Box className="staff-emailer__left">
                <StaffEmailerDropzone
                  setIsEmailsLoaded={setIsEmailsLoaded}
                  setEmailCards={setEmailCards}
                  setEmails={setEmails}
                  tables={tables}
                  setTables={setTables}
                  tableHeaders={tableHeaders}
                  setTableHeaders={setTableHeaders}
                  handleAddEmailCard={addEmailCard}
                  emails={emails}
                  addEmail={addEmail}
                  type="export"
                />
              </Box>

              {/* RIGHT COLUMN: Alert + Table or Placeholder */}
              <Box className="staff-emailer__right">
                {isEmailsLoaded ? (
                  emailCards.length < 1 ? (
                    // If loaded but no staff emails found:
                    // <>
                    //   <div style={{ marginTop: "50px" }}>
                    //     No staff emails found. Please add emails for your staff
                    //     in staff settings.
                    //   </div>
                    //   <Button
                    //     className="staff-emailer__staff-settings-link"
                    //     onClick={() =>
                    //       history.push({
                    //         pathname: "studio-setting/staff",
                    //         search: location.search,
                    //       })
                    //     }
                    //   >
                    //     Staff Settings
                    //   </Button>
                    // </>
                    <Box
                      sx={{
                        textAlign: "center",
                        border: "1px dashed #ddd",
                        borderRadius: 2,
                        p: 3,
                        color: "#666",
                        mt: 2,
                        margin: "0 auto",
                      }}
                    >
                      <NoAccountsRoundedIcon sx={{ fontSize: 48, color: "#ccc", mb: 1 }} />
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        No Staff Emails Found
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        Please add emails for your staff in staff settings.
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          history.push({
                            pathname: "studio-setting/staff",
                            search: location.search,
                          })
                        }
                      >
                        Staff Settings
                      </Button>
                    </Box>

                  ) : (
                    // We have data, show alerts + table
                    <>
                      {showZeroEarningsWarning && (
                        <Alert
                          severity="warning"
                          onClose={() => setShowZeroEarningsWarning(false)}
                          className="staff-emailer__zero-earnings-alert"
                        >
                          <AlertTitle>Zero Earnings Detected</AlertTitle>
                          All employees have zero earnings. If this is incorrect,
                          please save and re-upload the Excel file.
                        </Alert>
                      )}
                      <Box className="staff-emailer__table-section">
                        <EmailPreviewTable
                          emailCards={emailCards}
                          emails={emails}
                          handleSendEmail={sendEmail}
                          handleSendAllEmails={sendAllEmails}
                          sendAllEmailButtonDisabled={sendAllEmailButtonDisabled}
                          setSendAllEmailButtonDisabled={setSendAllEmailButtonDisabled}
                        />
                      </Box>
                    </>
                  )
                ) : (
                  // Not loaded yet, so show an empty state
                  <NoDataPlaceholder />
                )}
              </Box>
            </Box>
          ) : (
            <Skeleton height={"138px"} width={"100%"} className="staff-emailer__skeleton" />
          )}
        </Box>
      </Box>
    </>
  );
}